export const normalize = (
	attribute: string,
	toLowerCase = false,
	toUpperCase = false
): string => {
	const normalizationForm = 'NFD';
	const nfdRegex = /[\u0300-\u036f]/g;

	if (!attribute) {
		return '';
	}

	let normalizedAttribute = attribute
		.normalize(normalizationForm)
		.replace(nfdRegex, ''); // Remove accents

	normalizedAttribute = normalizedAttribute.replace(/ /g, '_'); // replace whitespaces by underscore

	normalizedAttribute = normalizedAttribute.replace(
		/[`~!@#$%^&*()°|+\-=?;:'",.<>{}[\]\\/]/gi,
		''
	); // Remove special characters

	normalizedAttribute = normalizedAttribute.replace(/_+/g, '_'); // replace multiple underscores with one

	if (toLowerCase) {
		return normalizedAttribute.replace(nfdRegex, '').toLowerCase();
	} else if (toUpperCase) {
		return normalizedAttribute.replace(nfdRegex, '').toUpperCase();
	} else {
		return normalizedAttribute.replace(nfdRegex, '');
	}
};
