import { immerable, produce } from 'immer';
import { Map } from 'immutable';

import { SimulationColumnsItemEntity } from './simulation-columns-item.entity';
import { SimulationColumnsListEntity } from './simulation-columns-list.entity';

type ResultType = 'REST' | 'ODATA_XML' | 'ODATA_JSON' | 'CHANGE_ME';

type RawDataResult =
	| {
			'@odata.context': string;
			'@odata.count': string;
			value: Array<Record<string, any>>;
	  }
	| string
	| Array<Record<string, any>>
	| null;

export type SimResultErr =
	| {
			code: number;
			msg: string;
	  }
	| Array<string>;

interface ISimulationResultsEntity {
	type: ResultType;
	data: Array<Record<string, any>>;
	count: number;
	rawData: RawDataResult;
	gridData: {
		columnsToRender: Array<string>;
		columnsDataTypes: Map<string, SimulationColumnsItemEntity>;
	};
	error: SimResultErr | null;
}

const entityDefaults: ISimulationResultsEntity = {
	type: 'REST',
	data: [],
	count: 0,
	rawData: null,
	gridData: {
		columnsToRender: [],
		columnsDataTypes: Map(),
	},
	error: null,
};

export class SimulationResultsEntity implements ISimulationResultsEntity {
	private [immerable] = true;

	public type = entityDefaults.type;
	public data = entityDefaults.data;
	public count = entityDefaults.count;
	public rawData = entityDefaults.rawData;
	public gridData = entityDefaults.gridData;
	public error = entityDefaults.error;

	public static empty(): SimulationResultsEntity {
		return new SimulationResultsEntity();
	}

	public static buildWithErrors(
		code: number,
		msg: string
	): SimulationResultsEntity {
		return SimulationResultsEntity.empty().setError({
			code,
			msg,
		});
	}

	public static build(
		type: ResultType,
		params: {
			data: Array<Record<string, any>>;
			count: number;
			aliasToCol: SimulationColumnsListEntity;
		}
	): SimulationResultsEntity {
		const instance = new SimulationResultsEntity();
		instance.type = type;
		instance.data = [...params.data];
		instance.count = params.count;

		if (params.data.length !== 0) {
			const displayedColsCaptions = Object.keys(params.data[0]);
			instance.gridData = {
				columnsToRender: displayedColsCaptions,
				columnsDataTypes: params.aliasToCol.extractColAliasAndType(
					displayedColsCaptions
				),
			};
		}
		return instance;
	}

	private constructor() {}

	public setRawData(data: RawDataResult | null): SimulationResultsEntity {
		return produce(this, (draft: SimulationResultsEntity) => {
			draft.rawData = data;
		});
	}

	public setError(data: SimResultErr): SimulationResultsEntity {
		return produce(this, (draft: SimulationResultsEntity) => {
			draft.error = data;
		});
	}
}
