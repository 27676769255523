import { Component, EventEmitter, Output } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DcBaseComponent } from '@datachain/ui-sdk/common';
import { debounceTime, map, takeUntil, tap, withLatestFrom } from 'rxjs';

import { ValidationErrorKeys } from '../../form/validation-erros-keys';
import { AppMetadataEntity } from './app-metadata.entity';
import { AppMetadataForm, MetadataFormControls } from './app-metadata.form';

@Component({
	selector: 'app-metadata',
	templateUrl: './app-metadata.component.html',
	styleUrls: ['./app-metadata.component.scss'],
	providers: [AppMetadataForm],
	inputs: ['metadata'],
})
export class AppMetadataComponent extends DcBaseComponent {
	public titlePlaceHolder = $localize`:i18n=@@metadata.title.placeholder:`;
	public descriptionPlaceHolder = $localize`:i18n=@@metadata.description.placeholder:`;
	public Appearance: MatFormFieldAppearance = 'outline';
	public MetadataFormControls = MetadataFormControls;

	@Output()
	public notifyOnError = new EventEmitter<boolean>();

	@Output()
	public hasChangedValue = new EventEmitter<boolean>();

	protected readonly ValidationErrorKeys = ValidationErrorKeys;
	public constructor(public readonly metadataForm: AppMetadataForm) {
		super();
		this.cmpId = 'metadata';

		this.toObservable<AppMetadataEntity>('metadata')
			.pipe(
				takeUntil(this.onDestroy$),
				tap((metadata) => this.metadataForm.populate(metadata))
			)
			.subscribe();

		this.metadataForm.form.statusChanges
			.pipe(
				takeUntil(this.onDestroy$),
				tap((status) => {
					this.notifyOnError.emit(status === 'INVALID');
				})
			)
			.subscribe();

		this.metadataForm.form.valueChanges
			.pipe(
				debounceTime(500), // prevents multiple executions on page startup due to form controls firing sequentially
				withLatestFrom(this.toObservable<AppMetadataEntity>('metadata')),
				map(([formValues, knownInputEntity]) => {
					if (knownInputEntity.id === -1) {
						// TODO replace with explicit instance saying it is in creation mode; i.e. all modifications is to be saved
						return true;
					}
					return !knownInputEntity.isEqualTo(this.extractFormData());
				}),
				takeUntil(this.onDestroy$),
				tap((res) => this.hasChangedValue.next(res))
			)
			.subscribe();
	}

	public extractFormData(): AppMetadataEntity {
		return this.metadataForm.extract();
	}
}
