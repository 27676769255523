import { AppTagItemEntity } from '@datachain/ui-sdk/components';
import { createAction, props } from '@ngrx/store';
import { Map, OrderedSet } from 'immutable';

import { ExpositionEndpointMetadataEntity } from '../components/exposition-endpoint-metadata-config/exposition-endpoint-metadata.entity';
import { ExpositionHistoryListEntity } from '../components/exposition-history-list/exposition-history-list.entity';
import { ExpositionMetadataEntity } from '../components/exposition-internal-metadata-config/exposition-metadata.entity';
import { ExpositionListEntity } from '../components/exposition-list/exposition-list.entity';
import { MigrationReadyExpositionList } from '../components/exposition-migrations-list/exposition-migration-stepper/migration-ready-exposition-list.entity';
import { MigratedExpositionListEntity } from '../components/exposition-migrations-list/migrated-exposition-list.entity';
import { SimulationAccessEntity } from '../components/exposition-simulator/simulation-access.entity';
import { SimulationColumnsListEntity } from '../components/exposition-simulator/simulation-columns-list.entity';
import { SimulationExpositionMetadataEntity } from '../components/exposition-simulator/simulation-exposition-metadata.entity';
import { SimulationGroupEntity } from '../components/exposition-simulator/simulation-group.entity';
import { SimulationResultsEntity } from '../components/exposition-simulator/simulation-results.entity';
import { SimulationUrisEntity } from '../components/exposition-simulator/simulation-uris.entity';
import { SimulationUserEntity } from '../components/exposition-simulator/simulation-user.entity';
import { ExpositionDatablockColumnEntity } from '../components/exposition-stepper/exposition-datablock-list/exposition-datablock-column.entity';
import { ExpositionDatablockListEntity } from '../components/exposition-stepper/exposition-datablock-list/exposition-datablock-list.entity';
import { ExpositionEntity } from '../components/exposition-view/exposition.entity';
import { AccessConfigEntity } from '../domain/exposition-access-config.entity';
import { ExpositionColumnConfigListEntity } from '../domain/exposition-column-config-list.entity';
import { ExpositionColumnConfigEntity } from '../domain/exposition-column-config.entity';
import { ExpositionConsumerMappingEntity } from '../domain/exposition-consumer-mapping.entity';
import { ExpositionConsumerEntity } from '../domain/exposition-consumer.entity';
import { PublicationStatus } from '../domain/publication-status';
import {
	SimulationExecutionPayload,
	SimulationType,
	SimulationUrisConfigPayload,
} from '../domain/simulation.types';
import { PublicationAction } from './expositions.state';

export type ExpositionJobPair = [number, string];

export enum ExpositionsActions {
	InitExpositionsList = '[Expositions] init list',
	OpenInitNewExpositionMigrationModal = '[Expositions][Migration] configure new migration',
	InitMigratedExpositionsList = '[Expositions][Migration] init migrated expositions list',
	RefreshMigratedExpositionsList = '[Expositions][Migration] refresh migrated expositions list',
	FetchMigratedExpositionsListSuccess = '[Expositions][Migration]  successfully retrieved migrated expositions list',
	FetchMigratedExpositionsListFail = '[Expositions][Migration]  error while retrieving migrated expositions list',
	FetchAvailableExpositionsForMigration = '[Expositions][Migration]  retrieve eligible for migration expositions',
	FetchAvailableExpositionsForMigrationSuccess = '[Expositions][Migration]  successfully retrieved eligible for migration expositions',
	FetchAvailableExpositionsForMigrationFail = '[Expositions][Migration] Error while retrieving eligible for migration expositions',
	CloseEditExpositionColumnsModal = '[Expositions][Migration] Close edit exposition columns modal',
	RefreshReadyToMigrateExpositionsList = '[Expositions][Migration] refresh list of eligible expositions',
	RetrievesConsumersToMap = '[Expositions][Migration] get list of v1 expositions consumers',
	RetrievesConsumersToMapSuccess = '[Expositions][Migration] successfully retrieved list of v1 expositions consumers',
	RetrievesConsumersToMapFail = '[Expositions][Migration] error while retrieving v1 expositions consumers',
	RefreshExpositionsList = '[Expositions] refresh list',
	FetchAvailableExpositionsListSuccess = '[Expositions] get expositions list success',
	FetchAvailableExpositionsListFailure = '[Expositions] get expositions list failure',
	OpenCreateNewExpositionModal = '[Expositions] configure new exposition',
	OpenCreateNewExpositionModalFromDatablock = '[Expositions][Datablock List] configure new exposition from datablock',
	GoToExpositionDatablockFromListState = '[Expositions][Datablock List] go to state',
	GoToExpositionDatablockFromViewState = '[Expositions][Datablock Edit] go to state',
	OpenEditExpositionColumnsModal = '[Expositions] edit exposition columns',
	OpenEditExpositionMetadataPopup = '[Expositions] edit exposition metadata',
	OpenEditExpositionEndpointMetadataPopup = '[Expositions] edit exposition endpoint metadata',
	OpenEditExpositionApiModal = '[Expositions] edit exposition api',
	CloseCreateExpositionModal = '[Expositions] Close create exposition modal',
	CloseMigrateExpositionModal = '[Expositions] Close migration exposition modal',
	CloseEditExpositionApiModal = '[Expositions] Close edit exposition API modal',
	GoToCreateNewExpositionRoute = '[Expositions] navigate to create new expositions route',
	GoToExpositionEditColumnsRoute = '[Expositions] navigate to exposition edit columns route',
	GoToExpositionEditAccessRoute = '[Expositions] navigate to exposition edit access route',
	GoToExpositionCreateAccessRoute = '[Expositions] navigate to exposition create access route',
	RedirectToExpositionView = '[Expositions] navigate to exposition view page',
	InitExpositionConfig = '[Expositions] init exposition config',
	InitExpositionView = '[Expositions] Get exposition',
	FetchExpositionView = '[Expositions] Fetch exposition',
	FetchExpositionViewOnEndpointDetailsTrigger = '[Expositions] Get exposition view if not present on endpoint details view',
	// eslint-disable-next-line max-len
	FetchExpositionViewOnEndpointDetailsTriggerSuccess = '[Expositions] Successfully retrieved get exposition view if not present on endpoint details view',
	RefreshExpositionView = '[BE][Expositions] refresh exposition',
	FetchExpositionSuccess = '[BE][Expositions] fetch exposition success',
	CreateExposition = '[BE][Expositions] create exposition',
	MigrateSelectedExpositions = '[BE][Expositions Migration] migrate selected expositions',
	MigrateSelectedExpositionsSuccess = '[BE][Expositions Migration] Successfully migrate selected expositions',
	MigrateSelectedExpositionsFailed = '[BE][Expositions Migration] Failure during expositions v1 migrations',
	CreateExpositionSuccess = '[BE][Expositions] create exposition success',
	CreateExpositionFail = '[BE][Expositions] create exposition fail',
	GetDataBlockColumns = '[BE][Expositions] get datablock columns list',
	FetchDataBlockColumnsSuccess = '[Expositions] fetch datablock columns list success',
	FetchExpositionDataBlocksSuccess = '[Expositions] fetch datablock list success',
	GetExpositionColumnConfigList = '[BE][Expositions] get column config list',
	RefreshDatablockColumns = '[BE][Expositions] refresh datablock columns',
	FetchExpositionColumnConfigListSuccess = '[Expositions] fetch column config list successfully',
	FetchAvailableTagsSuccess = '[Expositions] Successfully retrieved available tags',
	GetAvailableConsumers = '[BE][Expositions] get available consumers',
	FetchAvailableConsumersSuccess = '[Expositions] fetch available consumers successfully',
	GetExpositionColumns = '[BE][Expositions] get exposition columns',
	RefreshExpositionColumns = '[BE][Expositions] refresh exposition columns',
	SaveExpositionColumns = '[BE][Expositions] save exposition columns',
	FetchExpositionColumnsSuccess = '[Expositions] fetch exposition columns successfully',
	InitExpositionMetadataConfig = '[Expositions] init exposition metadata config',
	SaveExpositionMetadata = '[BE][Expositions] save exposition metadata',
	FetchExpositionMetadataSuccess = '[Expositions] fetch exposition metadata successfully',
	SaveExpositionEndpointMetadata = '[BE][Expositions] save exposition endpoint metadata',
	FetchExpositionEndpointMetadataSuccess = '[Expositions] fetch exposition endpoint metadata successfully',
	InitExpositionAccessCreation = '[Expositions] init exposition access creation',
	InitExpositionAccessModification = '[BE][Expositions] init exposition access modification',
	CreateExpositionAccess = '[BE][Expositions] create new exposition access',
	SaveExpositionAccess = '[BE][Expositions] save exposition access',
	FetchExpositionAccessSuccess = '[Expositions] fetch exposition access successfully',
	ToggleAccessStatus = '[BE][Expositions] update exposition access status',
	OpenDeleteAccessPopup = '[Expositions] open delete access popup',
	DeleteExpositionAccessSuccess = '[Expositions] delete exposition access success',
	InitPublishExposition = '[BE][Expositions] publish exposition',
	PublicationActionSuccess = '[Expositions] publication action success',
	UpdateExpositionAndTrackJob = '[BE][Expositions] update exposition',
	UpdateExpositionWithoutJobTracking = '[BE][Expositions] update exposition without tracking jpb',
	UpdateExpositionData = '[BE][Expositions] update exposition Data',
	ClearExpositionData = '[BE][Expositions] clear exposition data',
	ActivateExposition = '[BE][Expositions] activate exposition',
	DeactivateExposition = '[BE][Expositions] deactivate exposition',
	ConfirmBeforePublishOnList = '[Expositions][List] open publish confirm popup',
	ConfirmBeforeDeleteExpoOnList = '[Expositions][List] open delete exposition confirm popup',
	ConfirmationBeforeClearData = '[Expositions][List] open clear data confirm popup',
	ConfirmationBeforeDeactivate = '[Expositions][List] open deactivate confirm popup',
	ConfirmationBeforeActivate = '[Expositions][List] open activate confirm popup',
	ConfirmUpdateExpositionDataOnList = '[Expositions][List] open update data confirm popup',
	BulkUpdateExpositionData = '[BE][Expositions][List] bulk update exposition Data',
	BulkPublishExposition = '[BE][Expositions][List] bulk publish exposition',
	OpenPublishConfirmationPopup = '[Expositions][View] open publish confirm popup',
	OpenUpdateDataConfirmationPopup = '[Expositions][View] open update data confirm popup',
	OpenUpdateConfirmationPopup = '[Expositions][View] open update confirm popup',
	OpenClearDataConfirmationPopup = '[Expositions][View] open clear data confirm popup',
	OpenActivateConfirmationPopup = '[Expositions][View] open activate confirm popup',
	OpenDeactivateConfirmationPopup = '[Expositions][View] open deactivate confirm popup',
	OpenDeleteConfirmationPopup = '[Expositions][View] open delete confirm popup',
	NavigateToExposition = '[Expositions] navigate to exposition',
	TrackExpositionAction = '[Expositions] listen to websocket after action launch',
	RefreshAfterError = '[Expositions] [View] Refresh after error',
	RefreshAfterErrorSuccess = '[Expositions] [View] Successfully Refresh after error',
	GoToExpositionsList = '[Expositions] go to list',
	GoToMigrationsModalRoute = '[Expositions] go to exposition migration modal route',
	RefreshDatablocks = '[BE][Expositions] refresh datablock list',
	RefreshDatablocksSuccess = '[BE][Expositions] refresh datablock list Success',
	GoToExpositionHistoryRoute = '[Expositions] navigate to exposition history route',
	GoToExpositionSimulationRoute = '[Expositions] navigate to exposition simulation route',
	GetExpositionHistory = '[BE][Expositions] get exposition history',
	RefreshExpositionHistory = '[BE][Expositions] refresh exposition history',
	FetchExpositionHistorySuccess = '[BE][Expositions] fetch exposition history Success',
	FetchExpositionOnHistoryNavigationSuccess = '[Expositions] fetch exposition on history navigation Success',
	GetJobErrorMessage = '[BE][Expositions] get job error message',
	FetchJobErrorMessageSuccess = '[BE][Expositions] fetch error message success',
	FetchJobErrorMessageFail = '[BE][Expositions] fetch error message fail',
	InitProjectImportConsumersMapping = '[Expositions][Project Import] get target consumers',
	RefreshProjectImportConsumersMapping = '[Expositions][Project Import] refresh target consumers',
	ProjectImportConsumersToMapSuccess = '[Expositions][Project Import] successfully retrieved list of available consumers',
	ProjectImportConsumersToMapFail = '[Expositions][Project Import] error while retrieving available consumers',
	GetAvailableUsersForSimulation = '[Expositions][Simulation] available users',
	GetAvailableUsersForSimulationSuccess = '[BE][Expositions][Simulation] available users success',
	GetAvailableUsersForSimulationFail = '[BE][Expositions][Simulation] available users errored',
	GetAvailableGroupsForSimulation = '[Expositions][Simulation] available groups',
	GetAvailableGroupsForSimulationSuccess = '[BE][Expositions][Simulation] available groups success',
	GetAvailableGroupsForSimulationFail = '[BE][Expositions][Simulation] available groups errored',
	GetAvailableAccessForSimulation = '[Expositions][Simulation] available access',
	GetAvailableAccessForSimulationSuccess = '[BE][Expositions][Simulation] available access success',
	GetAvailableAccessForSimulationFail = '[BE][Expositions][Simulation] available access errored',
	GetCurrentExpositionSimulationMetadata = '[Expositions][Simulation] Get metadata for current exposition',
	GetCurrentExpositionMetadataSuccess = '[BE][Expositions][Simulation] Get metadata for current exposition success',
	GetCurrentExpositionMetadataFail = '[BE][Expositions][Simulation] Failure retrieving exposition metadata',
	ComputeAvailableColumns = '[Expositions][Simulation] compute allowed columns',
	FetchAvailableColumnsForSimSuccess = '[BE][Expositions][Simulation] get available columns for simulation success',
	FetchAvailableColumnsForSimFail = '[BE][Expositions][Simulation] get available columns for simulation errored',
	FetchExpositionGridData = '[Expositions][Simulation] Simulate exposition data',
	FetchRawJsonData = '[Expositions][Simulation] Fetch Raw JSON exposition data',
	FetchRawXmlData = '[Expositions][Simulation] Fetch Raw XML exposition data',
	FetchRawJsonDataSuccess = '[BE][Expositions][Simulation] Fetch Raw JSON exposition data success',
	FetchRawJsonDataWarning = '[BE][Expositions][Simulation] Fetch Raw JSON exposition data failed with a warning',
	FetchRawJsonDataErrored = '[BE][Expositions][Simulation] Fetch Raw JSON exposition data errored',
	FetchRawXmlDataSuccess = '[BE][Expositions][Simulation] Fetch Raw XML exposition data success',
	FetchRawXmlDataWarning = '[BE][Expositions][Simulation] Fetch Raw XML exposition data failed with a warning',
	FetchRawXmlDataErrored = '[BE][Expositions][Simulation] Fetch Raw XML exposition data errored',
	FetchExpositionGridDataSuccess = '[BE][Expositions][Simulation] Simulate exposition data success',
	FetchExpositionGridDataFailedWithWarning = '[BE][Expositions][Simulation] Simulate exposition data failed with a warning',
	FetchExpositionGridDataErrored = '[BE][Expositions][Simulation] Simulate exposition data errored',
	ComputeUrisForConfig = '[Expositions][Simulation] compute available consumption uris',
	ComputeUrisForConfigSuccess = '[BE][Expositions][Simulation] fetch consumption uris success',
	ComputeUrisForConfigFail = '[BE][Expositions][Simulation] fetch consumption uris errored',
	ResetAvailableData = 'Reset Available Data',
}

export const navigateToExposition = createAction(
	ExpositionsActions.NavigateToExposition,
	props<{ expositionId: number }>()
);

export const initExpositionsList = createAction(
	ExpositionsActions.InitExpositionsList,
	props<{ datablockId: number | null }>()
);

export const refreshExpositionsList = createAction(
	ExpositionsActions.RefreshExpositionsList,
	props<{ expositionId: number | undefined; datablockId?: number | null }>()
);

export const refreshReadyToMigrateExpositionsList = createAction(
	ExpositionsActions.RefreshReadyToMigrateExpositionsList
);

export const fetchAvailableExpositionsListSuccess = createAction(
	ExpositionsActions.FetchAvailableExpositionsListSuccess,
	props<{
		list: ExpositionListEntity;
		canCreate: boolean;
	}>()
);

export const fetchAvailableExpositionsListFail = createAction(
	ExpositionsActions.FetchAvailableExpositionsListFailure,
	props<{ error: unknown }>()
);

export const openCreateNewExpositionModal = createAction(
	ExpositionsActions.OpenCreateNewExpositionModal
);

export const openCreateNewExpositionModalFromDatablock = createAction(
	ExpositionsActions.OpenCreateNewExpositionModalFromDatablock,
	props<{ id: number; label: string }>()
);

export const goToExpositionFromDatablockListState = createAction(
	ExpositionsActions.GoToExpositionDatablockFromListState,
	props<{
		id: number;
		label: string;
	}>()
);

export const goToExpositionFromDatablockViewState = createAction(
	ExpositionsActions.GoToExpositionDatablockFromViewState,
	props<{
		id: number;
		label: string;
	}>()
);

export const openInitNewExpositionMigrationModal = createAction(
	ExpositionsActions.OpenInitNewExpositionMigrationModal
);

export const openEditExpositionColumnsModal = createAction(
	ExpositionsActions.OpenEditExpositionColumnsModal,
	props<{
		expositionId: number;
	}>()
);

export const openEditExpositionMetadataPopup = createAction(
	ExpositionsActions.OpenEditExpositionMetadataPopup,
	props<{
		expositionId: number;
	}>()
);

export const openEditExpositionEndpointMetadataPopup = createAction(
	ExpositionsActions.OpenEditExpositionEndpointMetadataPopup,
	props<{
		expositionId: number;
	}>()
);

export const openEditExpositionApiModal = createAction(
	ExpositionsActions.OpenEditExpositionApiModal,
	props<{
		expositionId: number;
		accessId: string;
	}>()
);

export const goToExpositionsListRoute = createAction(
	ExpositionsActions.GoToExpositionsList
);

export const goToCreateNewExpositionRoute = createAction(
	ExpositionsActions.GoToCreateNewExpositionRoute
);

export const goToExpositionEditColumnsRoute = createAction(
	ExpositionsActions.GoToExpositionEditColumnsRoute,
	props<{
		expositionId: number;
	}>()
);

export const goToExpositionEditAccessRoute = createAction(
	ExpositionsActions.GoToExpositionEditAccessRoute,
	props<{
		expositionId: number;
		accessId: string;
	}>()
);

export const goToExpositionCreateAccessRoute = createAction(
	ExpositionsActions.GoToExpositionCreateAccessRoute,
	props<{
		expositionId: number;
	}>()
);

export const redirectToExpositionView = createAction(
	ExpositionsActions.RedirectToExpositionView,
	props<{
		expositionId: number;
	}>()
);

export const goToMigrationsModalRoute = createAction(
	ExpositionsActions.GoToMigrationsModalRoute
);

export const closeCreateExpositionModal = createAction(
	ExpositionsActions.CloseCreateExpositionModal,
	props<{ datablockId: number }>()
);

export const closeMigrateExpositionModal = createAction(
	ExpositionsActions.CloseMigrateExpositionModal
);

export const closeEditExpositionColumnsModal = createAction(
	ExpositionsActions.CloseEditExpositionColumnsModal,
	props<{
		expositionId: number;
	}>()
);

export const closeEditExpositionApiModal = createAction(
	ExpositionsActions.CloseEditExpositionApiModal,
	props<{
		expositionId: number;
	}>()
);

export const getDataBlocksColumns = createAction(
	ExpositionsActions.GetDataBlockColumns,
	props<{
		datablockId: number;
	}>()
);

export const initExpositionStepper = createAction(
	ExpositionsActions.InitExpositionConfig,
	props<{ datablockId: number | null }>()
);

export const fetchExpositionDataBlockListSuccess = createAction(
	ExpositionsActions.FetchExpositionDataBlocksSuccess,
	props<{
		datablocks: ExpositionDatablockListEntity;
		availableExpositions: ExpositionListEntity;
	}>()
);

export const fetchDataBlockColumnsSuccess = createAction(
	ExpositionsActions.FetchDataBlockColumnsSuccess,
	props<{
		datablockId: number;
		columns: Array<ExpositionDatablockColumnEntity>;
	}>()
);

export const getColumnConfigList = createAction(
	ExpositionsActions.GetExpositionColumnConfigList,
	props<{
		datablockId: number;
	}>()
);

export const refreshDatablockColumns = createAction(
	ExpositionsActions.RefreshDatablockColumns,
	props<{
		datablockId: number;
	}>()
);

export const fetchColumnConfigListSuccess = createAction(
	ExpositionsActions.FetchExpositionColumnConfigListSuccess,
	props<{
		list: ExpositionColumnConfigListEntity;
	}>()
);

export const fetchAvailableTagsSuccess = createAction(
	ExpositionsActions.FetchAvailableTagsSuccess,
	props<{ tags: OrderedSet<AppTagItemEntity> }>()
);

export const getAvailableConsumers = createAction(
	ExpositionsActions.GetAvailableConsumers
);

export const fetchAvailableConsumersSuccess = createAction(
	ExpositionsActions.FetchAvailableConsumersSuccess,
	props<{ consumers: Array<ExpositionConsumerEntity> }>()
);

export const createExposition = createAction(
	ExpositionsActions.CreateExposition,
	props<{
		payload: {
			datablockId: number;
			metadata: ExpositionMetadataEntity;
			endpointMetadata: ExpositionEndpointMetadataEntity;
			columnsConfig: Array<ExpositionColumnConfigEntity>;
			accessConfig: AccessConfigEntity;
		};
	}>()
);

export const migrateSelectedExpositions = createAction(
	ExpositionsActions.MigrateSelectedExpositions,
	props<{ mapping: Map<string, ExpositionConsumerMappingEntity> }>()
);

export const migrateSelectedExpositionsSuccess = createAction(
	ExpositionsActions.MigrateSelectedExpositionsSuccess
);

export const migrateSelectedExpositionsFailed = createAction(
	ExpositionsActions.MigrateSelectedExpositionsFailed,
	props<{ error: unknown }>()
);

export const createExpositionSuccess = createAction(
	ExpositionsActions.CreateExpositionSuccess,
	props<{ expositionId: number }>()
);

export const createExpositionFail = createAction(
	ExpositionsActions.CreateExpositionFail,
	props<{ error: unknown }>()
);

export const initExpositionView = createAction(
	ExpositionsActions.InitExpositionView,
	props<{ expositionId: number }>()
);

export const fetchExpositionViewOnEndpointDetailsTrigger = createAction(
	ExpositionsActions.FetchExpositionViewOnEndpointDetailsTrigger,
	props<{ expositionId: number }>()
);

export const fetchExpositionViewOnEndpointDetailsTriggerSuccess = createAction(
	ExpositionsActions.FetchExpositionView,
	props<{ exposition: ExpositionEntity }>()
);

export const fetchExpositionSuccess = createAction(
	ExpositionsActions.FetchExpositionSuccess,
	props<{
		exposition: ExpositionEntity;
		availableExpositions: ExpositionListEntity;
		permissions: { delete: boolean; view: boolean; edit: boolean };
	}>()
);

export const fetchExpositionOnHistoryNavigationSuccess = createAction(
	ExpositionsActions.FetchExpositionOnHistoryNavigationSuccess,
	props<{ exposition: ExpositionEntity }>()
);

export const getExpositionColumns = createAction(
	ExpositionsActions.GetExpositionColumns,
	props<{ expositionId: number }>()
);

export const refreshExpositionColumns = createAction(
	ExpositionsActions.RefreshExpositionColumns,
	props<{ expositionId: number }>()
);

export const saveExpositionColumns = createAction(
	ExpositionsActions.SaveExpositionColumns,
	props<{ expositionId: number; columns: ExpositionColumnConfigListEntity }>()
);

export const fetchExpositionColumnsSuccess = createAction(
	ExpositionsActions.FetchExpositionColumnsSuccess,
	props<{
		columns: ExpositionColumnConfigListEntity;
		permissions: { delete: boolean; view: boolean; edit: boolean };
	}>()
);

export const initExpositionMetadataConfig = createAction(
	ExpositionsActions.InitExpositionMetadataConfig,
	props<{ expositionId: number }>()
);

export const saveExpositionMetadata = createAction(
	ExpositionsActions.SaveExpositionMetadata,
	props<{ expositionId: number; metadata: ExpositionMetadataEntity }>()
);

export const fetchExpositionMetadataSuccess = createAction(
	ExpositionsActions.FetchExpositionMetadataSuccess,
	props<{ metadata: ExpositionMetadataEntity }>()
);

export const saveExpositionEndpointMetadata = createAction(
	ExpositionsActions.SaveExpositionEndpointMetadata,
	props<{ expositionId: number; metadata: ExpositionEndpointMetadataEntity }>()
);

export const fetchExpositionEndpointMetadataSuccess = createAction(
	ExpositionsActions.FetchExpositionEndpointMetadataSuccess,
	props<{ metadata: ExpositionEndpointMetadataEntity }>()
);

export const initExpositionAccessCreation = createAction(
	ExpositionsActions.InitExpositionAccessCreation,
	props<{ expositionId: number }>()
);

export const initExpositionAccessModification = createAction(
	ExpositionsActions.InitExpositionAccessModification,
	props<{ expositionId: number; accessId: string }>()
);

export const createExpositionAccess = createAction(
	ExpositionsActions.CreateExpositionAccess,
	props<{ expositionId: number; access: AccessConfigEntity }>()
);

export const saveExpositionAccess = createAction(
	ExpositionsActions.SaveExpositionAccess,
	props<{ expositionId: number; access: AccessConfigEntity }>()
);

export const fetchExpositionAccessSuccess = createAction(
	ExpositionsActions.FetchExpositionAccessSuccess,
	props<{
		access: AccessConfigEntity;
		permissions: { delete: boolean; view: boolean; edit: boolean };
		exposition: ExpositionEntity;
	}>()
);

export const openDeleteAccessPopup = createAction(
	ExpositionsActions.OpenDeleteAccessPopup,
	props<{ expositionId: number; accessId: string; accessLabel: string }>()
);

export const deleteExpositionAccessSuccess = createAction(
	ExpositionsActions.DeleteExpositionAccessSuccess,
	props<{ expositionId: number; accessId: string }>()
);

export const initPublishExposition = createAction(
	ExpositionsActions.InitPublishExposition,
	props<{ expositionId: number }>()
);

export const bulkPublishExposition = createAction(
	ExpositionsActions.BulkPublishExposition,
	props<{ expositionId: Array<number> }>()
);

export const publicationActionSuccess = createAction(
	ExpositionsActions.PublicationActionSuccess,
	props<{ status: PublicationStatus }>()
);

export const updateExpositionData = createAction(
	ExpositionsActions.UpdateExpositionData,
	props<{ expositionId: number }>()
);

export const bulkUpdateExpositionData = createAction(
	ExpositionsActions.BulkUpdateExpositionData,
	props<{ expositionId: Array<number> }>()
);

export const updateExpositionParams = createAction(
	ExpositionsActions.UpdateExpositionAndTrackJob,
	props<{
		expositionId: number;
		updateMetadata: boolean;
		updateColumns: boolean;
		updateAccess: boolean;
	}>()
);

export const updateExpositionWithoutJobTracking = createAction(
	ExpositionsActions.UpdateExpositionWithoutJobTracking,
	props<{
		expositionId: number;
		updateMetadata: boolean;
		updateAccess: boolean;
	}>()
);

// export const clearExpositionData = createAction(
// 	ExpositionsActions.ClearExpositionData,
// 	props<{ expositionId: number }>()
// );
// export const activateExposition = createAction(
// 	ExpositionsActions.ActivateExposition,
// 	props<{ expositionId: number }>()
// );
// export const deactivateExposition = createAction(
// 	ExpositionsActions.DeactivateExposition,
// 	props<{ expositionId: number }>()
// );

export const openPublishConfirmationPopup = createAction(
	ExpositionsActions.OpenPublishConfirmationPopup,
	props<{
		expositionId: number;
		expositionLabel: string;
	}>()
);

export const confirmBeforePublishOnList = createAction(
	ExpositionsActions.ConfirmBeforePublishOnList,
	props<{
		expositionId: number | Array<number>;
		expositionLabel: string;
	}>()
);

export const confirmBeforeDeleteExpoOnList = createAction(
	ExpositionsActions.ConfirmBeforeDeleteExpoOnList,
	props<{
		expositionId: number | Array<number>;
		expositionLabel: string;
	}>()
);

export const confirmationBeforeClearDataOnList = createAction(
	ExpositionsActions.ConfirmationBeforeClearData,
	props<{
		expositionId: number | Array<number>;
		expositionLabel: string;
	}>()
);

export const confirmationBeforeDeactivateOnList = createAction(
	ExpositionsActions.ConfirmationBeforeDeactivate,
	props<{
		expositionId: number | Array<number>;
		expositionLabel: string;
	}>()
);

export const confirmationBeforeActivateOnList = createAction(
	ExpositionsActions.ConfirmationBeforeActivate,
	props<{
		expositionId: number | Array<number>;
		expositionLabel: string;
	}>()
);

export const confirmUpdateExpositionDataOnList = createAction(
	ExpositionsActions.ConfirmUpdateExpositionDataOnList,
	props<{
		expositionId: number | Array<number>;
		expositionLabel: string;
	}>()
);

export const openUpdateDataConfirmationPopup = createAction(
	ExpositionsActions.OpenUpdateDataConfirmationPopup,
	props<{
		expositionId: number;
		expositionLabel: string;
	}>()
);

export const openClearDataConfirmationPopup = createAction(
	ExpositionsActions.OpenClearDataConfirmationPopup,
	props<{
		expositionId: number;
		expositionLabel: string;
	}>()
);

export const openUpdateParamsConfirmationPopup = createAction(
	ExpositionsActions.OpenUpdateConfirmationPopup,
	props<{
		expositionId: number;
		expositionLabel: string;
		hasActiveAccess: boolean;
	}>()
);

export const openActivateConfirmationPopup = createAction(
	ExpositionsActions.OpenActivateConfirmationPopup,
	props<{
		expositionId: number;
		expositionLabel: string;
	}>()
);

export const openDeactivateConfirmationPopup = createAction(
	ExpositionsActions.OpenDeactivateConfirmationPopup,
	props<{
		expositionId: number;
		expositionLabel: string;
	}>()
);

export const openDeleteConfirmationPopup = createAction(
	ExpositionsActions.OpenDeleteConfirmationPopup,
	props<{
		expositionId: number;
		expositionLabel: string;
	}>()
);

export const trackExpositionAction = createAction(
	ExpositionsActions.TrackExpositionAction,
	props<{
		isBulkAction: boolean;
		clientId: string;
		expJobIdPair: ExpositionJobPair | Array<ExpositionJobPair>;
		action: PublicationAction;
	}>()
);

export const refreshAfterError = createAction(
	ExpositionsActions.RefreshAfterError
);

export const refreshAfterErrorSuccess = createAction(
	ExpositionsActions.RefreshAfterErrorSuccess,
	props<{
		exposition: ExpositionEntity;
	}>()
);

export const refreshExpositionView = createAction(
	ExpositionsActions.RefreshExpositionView,
	props<{ expositionId: number }>()
);

export const toggleAccessStatus = createAction(
	ExpositionsActions.ToggleAccessStatus,
	props<{ expositionId: number; accessId: string }>()
);

export const refreshDatablocks = createAction(
	ExpositionsActions.RefreshDatablocks
);

export const refreshDatablocksSuccess = createAction(
	ExpositionsActions.RefreshDatablocksSuccess,
	props<{
		datablocks: ExpositionDatablockListEntity;
	}>()
);

export const initMigratedExpositionsList = createAction(
	ExpositionsActions.InitMigratedExpositionsList
);

export const refreshMigratedExpositionsList = createAction(
	ExpositionsActions.RefreshMigratedExpositionsList
);

export const fetchMigratedExpositionsListSuccess = createAction(
	ExpositionsActions.FetchMigratedExpositionsListSuccess,
	props<{ migrated: MigratedExpositionListEntity }>()
);

export const fetchMigratedExpositionsListFail = createAction(
	ExpositionsActions.FetchMigratedExpositionsListFail,
	props<{ error: unknown }>()
);

export const fetchAvailableExpositionsForMigration = createAction(
	ExpositionsActions.FetchAvailableExpositionsForMigration
);

export const fetchAvailableExpositionsForMigrationSuccess = createAction(
	ExpositionsActions.FetchAvailableExpositionsForMigrationSuccess,
	props<{ eligible: MigrationReadyExpositionList }>()
);

export const fetchAvailableExpositionsForMigrationFail = createAction(
	ExpositionsActions.FetchAvailableExpositionsForMigrationFail,
	props<{ error: unknown }>()
);

export const retrievesConsumersToMap = createAction(
	ExpositionsActions.RetrievesConsumersToMap,
	props<{ datablockIds: Array<number> }>()
);

export const retrievesConsumersToMapSuccess = createAction(
	ExpositionsActions.RetrievesConsumersToMapSuccess,
	props<{
		requestId: string;
		unmappedConsumers: Array<ExpositionConsumerMappingEntity>;
		availableConsumers: Array<ExpositionConsumerEntity>;
	}>()
);

export const retrievesConsumersToMapFail = createAction(
	ExpositionsActions.RetrievesConsumersToMapFail,
	props<{ error: unknown }>()
);

export const projectImportConsumersToMapSuccess = createAction(
	ExpositionsActions.ProjectImportConsumersToMapSuccess,
	props<{
		availableConsumers: Array<ExpositionConsumerEntity>;
	}>()
);

export const projectImportConsumersToMapFail = createAction(
	ExpositionsActions.ProjectImportConsumersToMapFail,
	props<{ error: unknown }>()
);

export const goToExpositionHistoryRoute = createAction(
	ExpositionsActions.GoToExpositionHistoryRoute,
	props<{ expositionId: number }>()
);

export const goToExpositionSimulationRoute = createAction(
	ExpositionsActions.GoToExpositionSimulationRoute,
	props<{ expositionId: number }>()
);

export const getExpositionHistory = createAction(
	ExpositionsActions.GetExpositionHistory,
	props<{ expositionId: number }>()
);

export const refreshExpositionHistory = createAction(
	ExpositionsActions.RefreshExpositionHistory,
	props<{ expositionId: number }>()
);

export const fetchExpositionHistorySuccess = createAction(
	ExpositionsActions.FetchExpositionHistorySuccess,
	props<{ history: ExpositionHistoryListEntity }>()
);

export const getJobErrorMessage = createAction(
	ExpositionsActions.GetJobErrorMessage,
	props<{ expositionId: number; historyId: number }>()
);

export const fetchJobErrorMessageSuccess = createAction(
	ExpositionsActions.FetchJobErrorMessageSuccess,
	props<{ id: number; message: string }>()
);

export const fetchJobErrorMessageFail = createAction(
	ExpositionsActions.FetchJobErrorMessageFail,
	props<{ error: unknown }>()
);

export const initProjectImportConsumersMapping = createAction(
	ExpositionsActions.InitProjectImportConsumersMapping
);

export const refreshProjectImportConsumersMapping = createAction(
	ExpositionsActions.RefreshProjectImportConsumersMapping
);

export const fetchAvailableUsersForSimulation = createAction(
	ExpositionsActions.GetAvailableUsersForSimulation,
	props<{ payload: number }>()
);

export const fetchAvailableUsersForSimulationSuccess = createAction(
	ExpositionsActions.GetAvailableUsersForSimulationSuccess,
	props<{ payload: Array<SimulationUserEntity> }>()
);

export const fetchAvailableUsersForSimulationFail = createAction(
	ExpositionsActions.GetAvailableUsersForSimulationFail,
	props<{ error: unknown }>()
);

export const fetchAvailableGroupsForSimulation = createAction(
	ExpositionsActions.GetAvailableGroupsForSimulation,
	props<{ payload: number }>()
);

export const fetchAvailableGroupsForSimulationSuccess = createAction(
	ExpositionsActions.GetAvailableGroupsForSimulationSuccess,
	props<{ payload: Array<SimulationGroupEntity> }>()
);

export const fetchAvailableGroupsForSimulationFail = createAction(
	ExpositionsActions.GetAvailableGroupsForSimulationFail,
	props<{ error: unknown }>()
);

export const fetchAvailableAccessForSimulation = createAction(
	ExpositionsActions.GetAvailableAccessForSimulation,
	props<{ payload: number }>()
);

export const fetchAvailableAccessForSimulationSuccess = createAction(
	ExpositionsActions.GetAvailableAccessForSimulationSuccess,
	props<{ payload: Array<SimulationAccessEntity> }>()
);

export const fetchAvailableAccessForSimulationFail = createAction(
	ExpositionsActions.GetAvailableAccessForSimulationFail,
	props<{ error: unknown }>()
);

export const fetchAvailableColumnsForSim = createAction(
	ExpositionsActions.ComputeAvailableColumns,
	props<{
		expositionId: number;
		payload:
			| SimulationUserEntity
			| Array<SimulationGroupEntity>
			| Array<SimulationAccessEntity>;
	}>()
);

export const fetchAvailableColumnsForSimSuccess = createAction(
	ExpositionsActions.FetchAvailableColumnsForSimSuccess,
	props<{ payload: SimulationColumnsListEntity }>()
);

export const fetchAvailableColumnsForSimFail = createAction(
	ExpositionsActions.FetchAvailableColumnsForSimFail,
	props<{ error: unknown }>()
);

export const fetchExpositionGridData = createAction(
	ExpositionsActions.FetchExpositionGridData,
	props<{
		expositionId: number;
		payload: SimulationExecutionPayload;
	}>()
);

export const fetchExpositionGridDataSuccess = createAction(
	ExpositionsActions.FetchExpositionGridDataSuccess,
	props<{ payload: SimulationResultsEntity }>()
);

export const fetchExpositionGridDataFailedWithWarning = createAction(
	ExpositionsActions.FetchExpositionGridDataFailedWithWarning,
	props<{ payload: SimulationResultsEntity }>()
);

export const fetchExpositionGridDataErrored = createAction(
	ExpositionsActions.FetchExpositionGridDataErrored,
	props<{ error: unknown }>()
);

export const getCurrentExpositionSimulationMetadata = createAction(
	ExpositionsActions.GetCurrentExpositionSimulationMetadata,
	props<{ expositionId: number }>()
);

export const getCurrentExpositionMetadataSuccess = createAction(
	ExpositionsActions.GetCurrentExpositionMetadataSuccess,
	props<{ payload: SimulationExpositionMetadataEntity }>()
);

export const getCurrentExpositionMetadataFail = createAction(
	ExpositionsActions.GetCurrentExpositionMetadataFail,
	props<{ error: unknown }>()
);

export const computeUrisForConfig = createAction(
	ExpositionsActions.ComputeUrisForConfig,
	props<{
		expositionId: number;
		payload: SimulationUrisConfigPayload;
	}>()
);

export const computeUrisForConfigSuccess = createAction(
	ExpositionsActions.ComputeUrisForConfigSuccess,
	props<{ payload: SimulationUrisEntity; errors: Array<string> }>()
);

export const computeUrisForConfigFail = createAction(
	ExpositionsActions.ComputeUrisForConfigFail,
	props<{ error: unknown }>()
);

export const resetAvailableData = createAction(
	ExpositionsActions.ResetAvailableData
);

export const fetchRawJsonData = createAction(
	ExpositionsActions.FetchRawJsonData,
	props<{
		expositionId: number;
		simulationType: SimulationType;
		payload: SimulationExecutionPayload;
	}>()
);

export const fetchRawJsonDataSuccess = createAction(
	ExpositionsActions.FetchRawJsonDataSuccess,
	props<{ payload: SimulationResultsEntity }>()
);

export const fetchRawJsonDataWarning = createAction(
	ExpositionsActions.FetchRawJsonDataWarning,
	props<{ payload: SimulationResultsEntity }>()
);

export const fetchRawJsonDataFail = createAction(
	ExpositionsActions.FetchRawJsonDataErrored,
	props<{ error: unknown }>()
);

export const fetchRawXmlData = createAction(
	ExpositionsActions.FetchRawXmlData,
	props<{
		expositionId: number;
		payload: SimulationExecutionPayload;
	}>()
);

export const fetchRawXmlDataSuccess = createAction(
	ExpositionsActions.FetchRawXmlDataSuccess,
	props<{ payload: SimulationResultsEntity }>()
);

export const fetchRawXmlDataWarning = createAction(
	ExpositionsActions.FetchRawXmlDataWarning,
	props<{ payload: SimulationResultsEntity }>()
);

export const fetchRawXmlDataFail = createAction(
	ExpositionsActions.FetchRawXmlDataErrored,
	props<{ error: unknown }>()
);
