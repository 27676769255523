import { Component, OnInit, ViewChild } from '@angular/core';
import { combineAll, DcBaseComponent } from '@datachain/ui-sdk/common';
import { Store } from '@ngrx/store';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable } from 'rxjs';

import {
	ExpositionsSelector,
	goToMigrationsModalRoute,
	initMigratedExpositionsList,
	refreshMigratedExpositionsList,
} from '../../store';
import { MigratedExpositionListEntity } from './migrated-exposition-list.entity';

@Component({
	selector: 'app-exposition-migrations-list',
	templateUrl: './exposition-migrations-list.component.html',
	styleUrls: ['./exposition-migrations-list.component.scss'],
})
export class ExpositionMigrationsListComponent
	extends DcBaseComponent
	implements OnInit
{
	@ViewChild('expositionMigratedListDatagrid')
	private readonly migratedExpoListGridCmp: DxDataGridComponent | null = null;

	public vo$: Observable<{
		list: MigratedExpositionListEntity;
	}>;

	public constructor(
		private readonly store: Store,
		private readonly selector: ExpositionsSelector
	) {
		super();

		this.vo$ = combineAll({
			list: this.selector.getMigratedExpositions$(),
		});
	}

	public ngOnInit(): void {
		this.store.dispatch(initMigratedExpositionsList());
	}

	public goToMigrationsModalRoute(): void {
		this.store.dispatch(goToMigrationsModalRoute());
	}

	public refreshMigratedExpositionList(): void {
		this.store.dispatch(refreshMigratedExpositionsList());
	}

	public searchMigrated(searchTerm: string): void {
		if (!this.migratedExpoListGridCmp) {
			return;
		}
		this.migratedExpoListGridCmp.instance.searchByText(searchTerm);
	}
}
