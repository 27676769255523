import { Injectable } from '@angular/core';
import {
	CachePolicy,
	CachePolicyEntity,
	UrlService,
} from '@datachain/ui-sdk/common';
import { Observable } from 'rxjs';

import { DcBaseRequester } from './dc-base.requester';
import { IDcTag } from './models/tag.model';

@Injectable({
	providedIn: 'root',
})
export class TagRequester extends DcBaseRequester<IDcTag> {
	protected resource: string;
	protected elementsListUrl;
	protected singleElementUrl;
	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('TAGS');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = `${this.elementsListUrl}/{tagId}`;
	}

	public getAvailableTags(refresh = false): Observable<ReadonlyArray<IDcTag>> {
		const url = this.urlService.generateUrl(this.resource, {}, {});
		const cachingPolicy = refresh
			? CachePolicyEntity.build({
					policy: CachePolicy.NetworkAndCache,
					evictTimeout: 60,
					isCacheable: true,
			  })
			: CachePolicyEntity.build({
					policy: CachePolicy.CacheOnly,
					evictTimeout: 60,
					isCacheable: true,
			  });
		return this.get<ReadonlyArray<IDcTag>>(url, cachingPolicy);
	}
}
