(function () {
	'use strict';

	angular.module('dcApp').directive('semanticContextFilter', [
		'$parse',
		function ($parse) {
			var controller = [
				'$scope',
				'SemanticContextService',
				'toaster',
				'gettextCatalog',
				function ($scope, SemanticContextService, toaster, gettextCatalog) {
					let toasterPopSuccess = gettextCatalog.getString('Succès');
					let toasterPopDeleteRule = gettextCatalog.getString(
						'Enregistrement de la règle sémantique effectué'
					);

					var vm = this;

					$scope.init = function () {
						if (!$scope.vm.rule.semantic_context) {
							$scope.vm.rule.semantic_context = {};
							$scope.vm.rule.semantic_context.rule = {};
							$scope.vm.rule.semantic_context.relative_rules_operator = 'AND';
							$scope.vm.rule.semantic_context.relative_rules = [];
						}

						if (!$scope.vm.rule.semantic_context.relative_rules_operator) {
							$scope.vm.rule.semantic_context.relative_rules_operator = 'AND';
						}

						$scope.rule = $scope.vm.rule.semantic_context;
						$scope.rule.carac_id = $scope.vm.rule.filter.id;
						$scope.rule.rule.carac_id = $scope.vm.rule.filter.id;
						$scope.rule.rule.type = $scope.vm.rule.filter.type;
						if (angular.isFunction($scope.vm.widgetData.initFilter)) {
							$scope.vm.widgetData.initFilter($scope.rule.rule);
						}
					};

					$scope.addRule = function () {
						$scope.rule.relative_rules.push({
							carac_id: $scope.rule.carac_id,
							type: $scope.rule.rule.type,
							search_interval: true,
							search_in: false,
						});
					};

					$scope.deleteRule = function (rule) {
						$scope.rule.relative_rules.splice(
							$scope.rule.relative_rules.indexOf(rule),
							1
						);
					};

					$scope.changeRulesOperator = function (op) {
						$scope.rule.relative_rules_operator = op;
					};

					$scope.save = function () {
						$scope.vm.rule.semantic_context = $scope.rule;
						$scope.vm.widgetData.generateText();
						closeModal();
					};

					$scope.cancel = function () {
						$scope.vm.widgetData.cancelaction_l2();
						closeModal();
					};

					function closeModal() {
						$(vm.element).modal('hide');
						vm.widgetData.showSemanticContext = false;
					}

					$scope.hideMe = function (modalId) {
						$('#' + modalId).modal('hide');
					};

					$scope.showSemanticRulesList = function (modalId) {
						SemanticContextService.getSemantics().then(function (response) {
							$scope.semanticRulesList = response.data;
							$scope.semanticRulesListFiltred = $scope.semanticRulesList;
							$('#' + modalId).modal('show');
						});
					};

					$scope.searchRuleByLabel = function () {
						if (
							$scope.ruleSearchText == undefined ||
							$scope.ruleSearchText.length == 0
						) {
							$scope.semanticRulesListFiltred = $scope.semanticRulesList;
						} else {
							$scope.semanticRulesListFiltred = _.filter(
								$scope.semanticRulesList,
								function (item) {
									return (
										item.label
											.toLowerCase()
											.indexOf($scope.ruleSearchText.toLowerCase()) !== -1
									);
								}
							);
						}
					};

					$scope.showDeleteSemanticRuleConfirmation = function (
						modalId,
						itemId
					) {
						$scope.ruleIdToDelete = itemId;
						$('#' + modalId).modal('show');
					};

					$scope.deleteSemanticRuleItem = function (modalId) {
						SemanticContextService.deleteSemantic($scope.ruleIdToDelete).then(
							function (response) {
								for (var i in $scope.semanticRulesList) {
									if (response.data == $scope.semanticRulesList[i].id) {
										$scope.semanticRulesList.splice(i, 1);
										break;
									}
								}
								$('#' + modalId).modal('hide');
								toaster.pop('success', toasterPopSuccess, toasterPopDeleteRule);
							}
						);
					};

					$scope.useRuleItem = function (modalId, rule) {
						$scope.rule = rule;
						$scope.rule.relative_rules =
							$scope.rule != undefined &&
							$scope.rule.relative_rules != undefined
								? $scope.rule.relative_rules.rules
								: {};
						$scope.lastSelectedRulesItem = angular.copy($scope.rule);
						$scope.showruleLabel = true;
						$('#' + modalId).modal('hide');
					};

					$scope.unuseRuleItem = function () {
						delete $scope.rule;
						$scope.showruleLabel = false;
					};

					$scope.showAddRule = function (modalId) {
						$scope.newRuleToAdd = true;
						$scope.ruleDto = angular.copy($scope.rule);
						$('#' + modalId).modal('show');
					};

					$scope.showEditRule = function (modalId, rule) {
						$scope.newRuleToAdd = false;
						$scope.ruleDto = angular.copy(rule);
						$('#' + modalId).modal('show');
					};

					$scope.semanticRulesList = [];
					$scope.createSemantic = function (modalId) {
						var data = angular.copy($scope.ruleDto);
						var relativeRules = data.relative_rules;
						data.relative_rules = {};
						data.relative_rules.rules = relativeRules;
						SemanticContextService.createSemantic(data).then(function (
							response
						) {
							$('#' + modalId).modal('hide');
							toaster.pop('success', toasterPopSuccess, toasterPopDeleteRule);
							$scope.semanticRulesList.unshift(response.data);
						});
					};

					$scope.editSemantic = function (modalId) {
						var data = angular.copy($scope.ruleDto);
						SemanticContextService.updateSemantic(data).then(function (
							response
						) {
							$('#' + modalId).modal('hide');
							toaster.pop('success', toasterPopSuccess, toasterPopDeleteRule);
							for (var i in $scope.semanticRulesList) {
								if (response.data.id == $scope.semanticRulesList[i].id) {
									$scope.semanticRulesList[i] = response.data;
									break;
								}
							}
						});
					};

					// Check if any changes has been made so we can tell if we should show label or not
					$scope.$watch(
						'rule',
						function (newValue, oldValue) {
							var newVal = _.nestedOmit(angular.copy(newValue), [
								'$$hashKey',
								'id',
							]);
							var oldVal = _.nestedOmit(angular.copy(oldValue), [
								'$$hashKey',
								'id',
							]);
							var initVal = _.nestedOmit($scope.lastSelectedRulesItem, [
								'$$hashKey',
								'id',
							]);
							if (
								(!_.isEqual(newVal, oldVal) &&
									!_.isEqual(newVal, initVal) &&
									oldValue != undefined) ||
								_.isEmpty(initVal)
							) {
								$scope.showruleLabel = false;
							} else {
								$scope.showruleLabel = true;
							}
						},
						true
					);
				},
			];

			return {
				restrict: 'E',

				scope: {
					widgetData: '=',
					rule: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/semanticContextFilter/semanticContextFilter.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});
					$(element).on('hidden.bs.modal', function (event) {
						if (event.target == element[0]) {
							scope.$apply(function () {
								scope.vm.widgetData.showSemanticContext = false;
							});
						}
					});

					scope.$watch(
						function () {
							return scope.vm.widgetData.showSemanticContext;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								scope.init();
								$(element).modal('show');
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	]);
})();
