import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs';

import { ExpositionRequester } from '../../requesters/exposition/exposition.requester';
import { SimulationResultsEntity } from '../components/exposition-simulator/simulation-results.entity';
import { ExpositionsAdapter } from '../expositions.adapter';
import {
	computeUrisForConfig,
	computeUrisForConfigFail,
	computeUrisForConfigSuccess,
	fetchAvailableAccessForSimulation,
	fetchAvailableAccessForSimulationFail,
	fetchAvailableAccessForSimulationSuccess,
	fetchAvailableColumnsForSim,
	fetchAvailableColumnsForSimFail,
	fetchAvailableColumnsForSimSuccess,
	fetchAvailableGroupsForSimulation,
	fetchAvailableGroupsForSimulationFail,
	fetchAvailableGroupsForSimulationSuccess,
	fetchAvailableUsersForSimulation,
	fetchAvailableUsersForSimulationFail,
	fetchAvailableUsersForSimulationSuccess,
	fetchExpositionGridData,
	fetchExpositionGridDataErrored,
	fetchExpositionGridDataFailedWithWarning,
	fetchExpositionGridDataSuccess,
	fetchRawJsonData,
	fetchRawJsonDataFail,
	fetchRawJsonDataSuccess,
	fetchRawJsonDataWarning,
	fetchRawXmlData,
	fetchRawXmlDataFail,
	fetchRawXmlDataSuccess,
	fetchRawXmlDataWarning,
	getCurrentExpositionMetadataFail,
	getCurrentExpositionMetadataSuccess,
	getCurrentExpositionSimulationMetadata,
} from './actions';
import { SimulatorSelector } from './simulator.selector';

@Injectable()
export class SimulatorEffects {
	// ##########################################################
	// Navigation Effects
	// ##########################################################

	// ##########################################################
	// Business Effects
	// ##########################################################

	public fetchAvailableUsersForSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchAvailableUsersForSimulation),
			switchMap((action) =>
				this.expositionRequester.getAvailableSimulationsUsers(action.payload)
			),
			map((resp) =>
				fetchAvailableUsersForSimulationSuccess({
					payload: this.expositionsAdapter.generateSimulationUsers(resp),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				// this.ngCommService.notifyOnError(
				// 	$localize`:i18n=@@expositions.deleteAccess.error:`
				// );
				this.store.dispatch(
					fetchAvailableUsersForSimulationFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public fetchAvailableGroupsForSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchAvailableGroupsForSimulation),
			switchMap((action) =>
				this.expositionRequester.getAvailableSimulationsGroups(action.payload)
			),
			map((resp) =>
				fetchAvailableGroupsForSimulationSuccess({
					payload: this.expositionsAdapter.generateSimulationGroups(resp),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				// this.ngCommService.notifyOnError(
				// 	$localize`:i18n=@@expositions.deleteAccess.error:`
				// );
				this.store.dispatch(
					fetchAvailableGroupsForSimulationFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public fetchAvailableAccessForSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchAvailableAccessForSimulation),
			switchMap((action) =>
				this.expositionRequester.getAvailableSimulationsAccess(action.payload)
			),
			map((resp) =>
				fetchAvailableAccessForSimulationSuccess({
					payload: this.expositionsAdapter.generateSimulationAccess(resp),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				// this.ngCommService.notifyOnError(
				// 	$localize`:i18n=@@expositions.deleteAccess.error:`
				// );
				this.store.dispatch(
					fetchAvailableAccessForSimulationFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public fetchAvailableColumnsForSimulation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchAvailableColumnsForSim),
			switchMap((action) =>
				this.expositionRequester.getAvailableSimulationsColumns(
					action.expositionId,
					action.payload
				)
			),
			map((resp) =>
				fetchAvailableColumnsForSimSuccess({
					payload: this.expositionsAdapter.generateSimulationColumns(resp),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				// this.ngCommService.notifyOnError(
				// 	$localize`:i18n=@@expositions.deleteAccess.error:`
				// );
				this.store.dispatch(
					fetchAvailableColumnsForSimFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public fetchDataGridExpositionData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchExpositionGridData),
			switchMap((action) =>
				this.expositionRequester.fetchData(action.expositionId, action.payload)
			),
			withLatestFrom(
				this.selector.getSimulationColumns$(),
				(incoming, simulationCols) => ({
					resp: incoming,
					availableCols: simulationCols,
				})
			),
			map(({ resp, availableCols }) => {
				if (resp.errorPayload !== undefined) {
					return fetchExpositionGridDataFailedWithWarning({
						payload: this.expositionsAdapter.generateErrorDescription(
							resp.errorPayload
						),
					});
				}
				return fetchExpositionGridDataSuccess({
					payload: this.expositionsAdapter.generateSimulationData(
						resp,
						availableCols
					),
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				// this.ngCommService.notifyOnError(
				// 	$localize`:i18n=@@expositions.deleteAccess.error:`
				// );
				this.store.dispatch(
					fetchExpositionGridDataErrored({
						error,
					})
				);
				return caught;
			})
		)
	);

	public fetchRawJsonData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchRawJsonData),
			switchMap((action) =>
				this.expositionRequester.getRawJsonData(
					action.expositionId,
					action.simulationType,
					action.payload
				)
			),
			withLatestFrom(this.selector.getSimulationData$(), (resp, restData) => ({
				resp,
				restData,
			})),
			map(({ resp, restData }) => {
				if (resp.errorPayload !== undefined) {
					return fetchRawJsonDataWarning({
						payload: this.expositionsAdapter.generateErrorDescription(
							resp.errorPayload
						),
					});
				}
				return fetchRawJsonDataSuccess({
					payload: this.expositionsAdapter.generateSimulationResultWithRaw(
						resp,
						restData
					),
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				if (error.error?.title) {
					this.store.dispatch(
						fetchRawJsonDataWarning({
							payload: SimulationResultsEntity.buildWithErrors(
								error.error?.status ?? 400,
								error.error?.title ?? 'check query params'
							),
						})
					);
					return caught;
				} else {
					const parsedErr = error.error;
					if (parsedErr) {
						this.store.dispatch(
							fetchRawJsonDataWarning({
								payload: SimulationResultsEntity.buildWithErrors(
									400,
									parsedErr?.error?.message ?? 'check query params'
								),
							})
						);
						return caught;
					}
				}
				this.store.dispatch(
					fetchRawJsonDataFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public fetchRawXmlData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchRawXmlData),
			switchMap((action) =>
				this.expositionRequester.getRawXmlData(
					action.expositionId,
					action.payload
				)
			),
			withLatestFrom(this.selector.getSimulationData$(), (resp, restData) => ({
				resp,
				restData,
			})),
			map(({ resp, restData }) =>
				fetchRawXmlDataSuccess({
					payload: this.expositionsAdapter.generateSimulationResultWithRaw(
						resp,
						restData
					),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				const parsedErr = JSON.parse(error.error);
				if (parsedErr) {
					this.store.dispatch(
						fetchRawXmlDataWarning({
							payload: SimulationResultsEntity.buildWithErrors(
								400,
								parsedErr?.error?.message ?? 'check query params'
							),
						})
					);
					return caught;
				}
				this.store.dispatch(
					fetchRawXmlDataFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public expositionSimulationMetadata$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getCurrentExpositionSimulationMetadata),
			switchMap((action) =>
				this.expositionRequester.getCurrentExpositionSimMetadata(
					action.expositionId
				)
			),
			map((resp) =>
				getCurrentExpositionMetadataSuccess({
					payload: this.expositionsAdapter.generateExpSimMetadata(resp),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				// this.ngCommService.notifyOnError(
				// 	$localize`:i18n=@@expositions.deleteAccess.error:`
				// );
				this.store.dispatch(
					getCurrentExpositionMetadataFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public expositionUris$ = createEffect(() =>
		this.actions$.pipe(
			ofType(computeUrisForConfig),
			switchMap((action) =>
				this.expositionRequester.computeExpositionUris(
					action.expositionId,
					action.payload
				)
			),
			map((resp) =>
				computeUrisForConfigSuccess({
					payload: this.expositionsAdapter.generateAvailableUris(resp),
					errors: resp.errors ? resp.errors : [],
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				// this.ngCommService.notifyOnError(
				// 	$localize`:i18n=@@expositions.deleteAccess.error:`
				// );
				this.store.dispatch(
					computeUrisForConfigFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	// ##########################################################
	// Edit in popup effects
	// ##########################################################

	// ##########################################################
	// Confirmation popup effects
	// ##########################################################

	public constructor(
		private readonly actions$: Actions,
		private readonly store: Store,
		private readonly selector: SimulatorSelector,
		private readonly expositionRequester: ExpositionRequester,
		private readonly expositionsAdapter: ExpositionsAdapter
	) {}
}
