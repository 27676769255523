import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DcBaseComponent, combineAll } from '@datachain/ui-sdk/common';
import { ModalData, PanelAction, AppIcons } from '@datachain/ui-sdk/components';
import { Store } from '@ngrx/store';
import { Map } from 'immutable';
import {
	BehaviorSubject,
	debounceTime,
	firstValueFrom,
	Observable,
	ReplaySubject,
	takeUntil,
	tap,
} from 'rxjs';

import { ActionIcons } from '../../../../ui/app-actions.icons';
import { DcIcons } from '../../../../ui/app-dc.icons';
import { ActionIconsInjectable } from '../../../../ui/ui.module';
import {
	ColumnConfigErrorKey,
	ColumnConfigListError,
	ExpositionColumnConfigListEntity,
} from '../../../domain/exposition-column-config-list.entity';
import { ExpositionColumnConfigEntity } from '../../../domain/exposition-column-config.entity';
import {
	closeEditExpositionColumnsModal,
	ExpositionsSelector,
	getExpositionColumns,
	refreshExpositionColumns,
	saveExpositionColumns,
} from '../../../store';
import { ExpositionColumnsConfigComponent } from '../../exposition-columns-config/exposition-columns-config.component';

enum ColumnConfigAction {
	Activate = 'activate',
	Disable = 'disable',
	CaseInsensitive = 'case-insensitive',
	Filter = 'filter',
	Hash = 'hash',
	Hide = 'hide',
}

@Component({
	selector: 'app-exposition-columns-modal',
	templateUrl: './exposition-columns-modal.component.html',
	styleUrls: ['./exposition-columns-modal.component.scss'],
})
export class ExpositionColumnsModalComponent<T extends { expositionId: number }>
	extends DcBaseComponent
	implements OnInit
{
	private readonly columnsActions: Array<PanelAction> = [
		{
			order: 1,
			command: (): Promise<void> =>
				this.triggerAction(ColumnConfigAction.Activate),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.activate:`,
			typed: 'grouped-context-action',
		},
		{
			order: 2,
			command: (): Promise<void> =>
				this.triggerAction(ColumnConfigAction.Disable),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.disable:`,
			typed: 'grouped-context-action',
		},
		{
			order: 3,
			command: (): Promise<void> =>
				this.triggerAction(ColumnConfigAction.Filter),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.filter:`,
			typed: 'grouped-context-action',
		},
		{
			order: 4,
			command: (): Promise<void> =>
				this.triggerAction(ColumnConfigAction.CaseInsensitive),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.caseInsensitive:`,
			typed: 'grouped-context-action',
		},
		{
			order: 5,
			command: (): Promise<void> => this.triggerAction(ColumnConfigAction.Hash),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.hashable:`,
			typed: 'grouped-context-action',
		},
		{
			order: 6,
			command: (): Promise<void> => this.triggerAction(ColumnConfigAction.Hide),
			iconName: '',
			description: $localize`:@@expositions.columns.config.action.hide:`,
			typed: 'grouped-context-action',
		},
	];

	protected readonly DcIcons = DcIcons;
	protected readonly ColumnConfigErrorKey = ColumnConfigErrorKey;
	private readonly colConfigListErrorsSubject = new BehaviorSubject<
		Map<ColumnConfigErrorKey, ColumnConfigListError>
	>(Map());
	public list$ = new ReplaySubject<ExpositionColumnConfigListEntity>(1);
	public actions$ = new BehaviorSubject<Array<PanelAction>>(
		this.columnsActions
	);

	public vo$: Observable<{
		list: ExpositionColumnConfigListEntity;
		listErrors: Map<ColumnConfigErrorKey, ColumnConfigListError>;
		actions: Array<PanelAction>;
		canEdit: boolean;
	}>;

	@ViewChild(ExpositionColumnsConfigComponent)
	private readonly columnsConfigCmp: ExpositionColumnsConfigComponent | null = null;

	public constructor(
		private readonly store: Store,
		private readonly expositionsSelector: ExpositionsSelector,
		@Inject(MAT_DIALOG_DATA) public readonly modalData: ModalData & T,
		@Inject(ActionIconsInjectable) private readonly actionIcons: AppIcons
	) {
		super();
		this.cmpId = 'edit-exposition-columns';

		this.store.dispatch(
			getExpositionColumns({
				expositionId: this.modalData.expositionId,
			})
		);

		this.colConfigListErrorsSubject
			.pipe(
				takeUntil(this.onDestroy$),
				debounceTime(100),
				tap(() => {
					if (this.columnsConfigCmp) {
						this.list$.next(this.columnsConfigCmp.getUpdatedList());
					}
				})
			)
			.subscribe();

		this.expositionsSelector
			.getColumns$()
			.pipe(
				takeUntil(this.onDestroy$),
				debounceTime(320), // time to Devextreme finished loading the list view
				tap((columns) => this.list$.next(columns))
			)
			.subscribe();

		this.vo$ = combineAll({
			list: this.list$.pipe(tap((l) => this.bootstrapIcons(l))),
			listErrors: this.colConfigListErrorsSubject,
			actions: this.actions$,
			canEdit: this.expositionsSelector.getCanEditExposition$(),
		});
	}

	public close(): void {
		this.store.dispatch(
			closeEditExpositionColumnsModal({
				expositionId: this.modalData.expositionId,
			})
		);
	}

	public modifyColumnConfigValidity(
		errors: Map<ColumnConfigErrorKey, ColumnConfigListError>
	): void {
		this.colConfigListErrorsSubject.next(errors);
	}

	public save(): void {
		if (!this.columnsConfigCmp) {
			return;
		}
		const columns = this.columnsConfigCmp.getUpdatedList();
		this.store.dispatch(
			saveExpositionColumns({
				expositionId: this.modalData.expositionId,
				columns,
			})
		);
	}

	public fetchColumns(): void {
		this.store.dispatch(
			refreshExpositionColumns({
				expositionId: this.modalData.expositionId,
			})
		);
	}

	private async triggerAction(action: ColumnConfigAction): Promise<void> {
		if (!this.columnsConfigCmp) {
			return;
		}
		const selection =
			(await this.columnsConfigCmp.getSelectedColumns()) as Array<ExpositionColumnConfigEntity>;
		const columnsConfig = this.columnsConfigCmp.getUpdatedList();
		const selectedIds = selection.map((s) => s.id);
		switch (action) {
			case ColumnConfigAction.Activate:
				this.list$.next(columnsConfig.updateIsActive(selectedIds, true));
				break;
			case ColumnConfigAction.Disable:
				this.list$.next(columnsConfig.updateIsActive(selectedIds, false));
				break;
			case ColumnConfigAction.CaseInsensitive:
				this.list$.next(columnsConfig.updateIsCaseSensitive(selectedIds, true));
				break;
			case ColumnConfigAction.Filter:
				this.list$.next(columnsConfig.updateIsFiltered(selectedIds, true));
				break;
			case ColumnConfigAction.Hash:
				this.list$.next(columnsConfig.updateHash(selectedIds, true));
				break;
			case ColumnConfigAction.Hide:
				this.list$.next(columnsConfig.updateIsHidden(selectedIds, true));
				break;
		}
	}

	private async bootstrapIcons(
		list: ExpositionColumnConfigListEntity
	): Promise<void> {
		const actions = [ActionIcons.FilterWarning, ActionIcons.Filter];
		const actionMap = await firstValueFrom(
			this.actionIcons.getFromRegistry(...actions)
		);
		if (list.hasDuplicatedAliases) {
			this.actions$.next([
				...this.columnsActions,
				{
					order: 0,
					command: (): void => {
						if (this.columnsConfigCmp) {
							this.columnsConfigCmp.filterDuplicates();
						}
					},
					iconName: list.hasDuplicatedAliases
						? actionMap.get(ActionIcons.FilterWarning)?.iconName
						: actionMap.get(ActionIcons.Filter)?.iconName,
					tooltip: $localize`:@@expositions.columns.config.action.filterDuplicates:`,
					description: $localize`:@@expositions.columns.config.action.filterDuplicates:`,
					typed: '',
				},
			]);
		} else {
			this.actions$.next(this.columnsActions);
		}
	}
}
