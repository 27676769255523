import { IisEqual } from '@datachain/ui-sdk/common';
import { immerable } from 'immer';

export enum DbTableNamingStrategy {
	None = 'none',
	SameAsDatablock = 'sameAsDatablock',
	UserDefinedLabel = 'userDefined',
}

interface IExportDbConfigurationEntity {
	namingStrategy: DbTableNamingStrategy;
	schema: string;
	targetLabel: string;
	targetKey: string;
}

const entityDefaults: IExportDbConfigurationEntity = {
	namingStrategy: DbTableNamingStrategy.SameAsDatablock,
	schema: '',
	targetLabel: '',
	targetKey: '',
};

export class ExportDbConfigurationEntity
	implements
		IExportDbConfigurationEntity,
		IisEqual<ExportDbConfigurationEntity>
{
	private [immerable] = true;
	public namingStrategy = entityDefaults.namingStrategy;
	public schema = entityDefaults.schema;
	public targetLabel = entityDefaults.targetLabel;
	public targetKey = entityDefaults.targetKey;

	public static build(
		params: Partial<IExportDbConfigurationEntity> = {}
	): ExportDbConfigurationEntity {
		const instance = new ExportDbConfigurationEntity();
		instance.namingStrategy =
			params.namingStrategy ?? entityDefaults.namingStrategy;
		instance.schema = params.schema ?? entityDefaults.schema;
		instance.targetLabel = params.targetLabel ?? entityDefaults.targetLabel;
		instance.targetKey = params.targetKey ?? entityDefaults.targetKey;
		return instance;
	}

	private constructor() {}

	public isEqualTo(obj: ExportDbConfigurationEntity): boolean {
		if (this.schema.localeCompare(obj.schema) !== 0) {
			return false;
		}

		const hasSameStrategy =
			this.namingStrategy.localeCompare(obj.namingStrategy) === 0;
		if (
			hasSameStrategy &&
			this.namingStrategy === DbTableNamingStrategy.SameAsDatablock
		) {
			if (
				this.targetKey !== '' &&
				this.targetKey.localeCompare(obj.targetKey) !== 0
			) {
				return false;
			}
			return true;
		} else if (
			hasSameStrategy &&
			this.namingStrategy === DbTableNamingStrategy.UserDefinedLabel
		) {
			if (
				this.targetLabel !== '' &&
				this.targetLabel.localeCompare(obj.targetLabel) !== 0
			) {
				return false;
			}
			if (
				this.targetKey !== '' &&
				this.targetKey.localeCompare(obj.targetKey) !== 0
			) {
				return false;
			}
		} else {
			return false;
		}
		return true;
	}
}
