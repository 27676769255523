import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';

import { ExpositionConsumerEntity } from './domain/exposition-consumer.entity';
import {
	ExpositionsSelector,
	goToExpositionFromDatablockListState,
	goToExpositionFromDatablockViewState,
	openCreateNewExpositionModal,
	openCreateNewExpositionModalFromDatablock,
	openEditExpositionApiModal,
	openEditExpositionColumnsModal,
	openInitNewExpositionMigrationModal,
} from './store';

@Injectable()
export class NgExpositions {
	public constructor(
		private readonly store: Store,
		private readonly expositionSelector: ExpositionsSelector
	) {}

	public openCreateNewExpositionModal(): void {
		this.store.dispatch(openCreateNewExpositionModal());
	}

	public openInitNewExpositionMigration(): void {
		this.store.dispatch(openInitNewExpositionMigrationModal());
	}

	public openEditExpositionColumnsModal(expositionId: number): void {
		this.store.dispatch(
			openEditExpositionColumnsModal({
				expositionId,
			})
		);
	}

	public openEditExpositionApiModal(
		expositionId: number,
		accessId: string
	): void {
		this.store.dispatch(
			openEditExpositionApiModal({
				expositionId,
				accessId,
			})
		);
	}

	public async getAvailableConsumers(): Promise<
		Array<ExpositionConsumerEntity>
	> {
		return await firstValueFrom(
			this.expositionSelector.getAvailableConsumers$()
		);
	}

	public goToExpositionFromDatablockListState(id: number, label: string): void {
		this.store.dispatch(
			goToExpositionFromDatablockListState({
				id,
				label,
			})
		);
	}

	public goToExpositionFromDatablockViewState(id: number, label: string): void {
		this.store.dispatch(
			goToExpositionFromDatablockViewState({
				id,
				label,
			})
		);
	}

	public configureExpositionForDatablock(id: number, label: string): void {
		this.store.dispatch(
			openCreateNewExpositionModalFromDatablock({
				id,
				label,
			})
		);
	}
}
