import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { IAppRootState } from '../../core/store';
import { expositionOriginalReducer } from './expositions.reducer';
import {
	ExpositionSliceKey,
	ExpositionOriginalSliceState,
} from './expositions.state';
import { simulatorReducer } from './simulator.reducer';
import { SimulatorSliceKey, SimulatorSliceState } from './simulator.state';

export const ExpositionFeatureKey = 'Exposition';

export interface ExpositionFeatureState {
	[ExpositionSliceKey]: ExpositionOriginalSliceState;
	[SimulatorSliceKey]: SimulatorSliceState;
}

export interface ExpositionRootState extends IAppRootState {
	[ExpositionFeatureKey]: ExpositionFeatureState;
}

export const featureSelector =
	createFeatureSelector<ExpositionFeatureState>(ExpositionFeatureKey);

export const expositionReducers: ActionReducerMap<ExpositionFeatureState> = {
	[ExpositionSliceKey]: expositionOriginalReducer,
	[SimulatorSliceKey]: simulatorReducer,
};
