<app-modal>
	<div modal-body class="delete-confirmation-modal-content">
		<p i18n="@@export.delete.confirm.msg">
			Are you sure you want to delete this item?
		</p>
	</div>

	<div modal-footer class="delete-confirmation-modal-footer">
		<button
			id="{{ cmpId + '-cancel-btn' }}"
			(click)="cancelDelete()"
			i18n="@@export.delete.cancel"
			mat-raised-button
			color="warn">
			Cancel
		</button>
		<button
			id="{{ cmpId + '-confirm-btn' }}"
			(click)="confirmDelete()"
			i18n="@@export.delete.confirm"
			mat-raised-button
			color="primary">
			Delete
		</button>
	</div>
</app-modal>
