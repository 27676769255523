import { Component, OnInit, ViewChild } from '@angular/core';
import { combineAll, DcBaseComponent } from '@datachain/ui-sdk/common';
import { IBreadCrumbEntry } from '@datachain/ui-sdk/components';
import { Store } from '@ngrx/store';
import { DxDataGridComponent } from 'devextreme-angular';
import {
	BehaviorSubject,
	firstValueFrom,
	Observable,
	takeUntil,
	tap,
} from 'rxjs';

import { ComponentIcons } from '../../../ui/app-components.icons';
import { DcIcons } from '../../../ui/app-dc.icons';
import { PublicationStatus } from '../../domain/publication-status';
import {
	ExpositionsSelector,
	getExpositionHistory,
	getJobErrorMessage,
	goToExpositionsListRoute,
	navigateToExposition,
	refreshExpositionHistory,
} from '../../store';
import {
	ExpositionHistoryItemEntity,
	HistoryActionType,
} from './exposition-history-item.entity';
import { ExpositionHistoryListEntity } from './exposition-history-list.entity';

type ExpoHistoryListOverlayTypes = {
	columns: number | undefined;
	access: number | undefined;
	endpointMetadata: number | undefined;
	logs: number | undefined;
};

@Component({
	selector: 'app-exposition-history-list',
	templateUrl: './exposition-history-list.component.html',
	styleUrls: ['./exposition-history-list.component.scss'],
	inputs: ['expositionId'],
})
export class ExpositionHistoryListComponent
	extends DcBaseComponent
	implements OnInit
{
	public paths: Array<IBreadCrumbEntry> = [];
	public vo$: Observable<{
		isLoading: boolean;
		history: ExpositionHistoryListEntity;
		openedOverlays: ExpoHistoryListOverlayTypes;
		isLoadingJobLogs: boolean;
	}>;
	protected readonly ComponentIcons = ComponentIcons;
	protected readonly PublicationStatus = PublicationStatus;
	protected readonly DcIcons = DcIcons;
	protected readonly HistoryActionType = HistoryActionType;

	@ViewChild(DxDataGridComponent)
	private readonly grid: DxDataGridComponent | null = null;
	private readonly openedOverlaySubject =
		new BehaviorSubject<ExpoHistoryListOverlayTypes>({
			columns: undefined,
			access: undefined,
			endpointMetadata: undefined,
			logs: undefined,
		});
	public constructor(
		private readonly store: Store,
		private readonly expositionsSelector: ExpositionsSelector
	) {
		super();
		this.cmpId = 'exposition-history';

		this.vo$ = combineAll({
			isLoading: this.expositionsSelector.getIsLoading$(),
			history: this.expositionsSelector.getHistory$(),
			openedOverlays: this.openedOverlaySubject,
			isLoadingJobLogs: this.expositionsSelector.getIsLoadingJobErrorLogs$(),
		});
	}

	public ngOnInit(): void {
		super.ngOnInit();
		combineAll({
			expositionId: this.toObservable<number>('expositionId'),
		})
			.pipe(
				takeUntil(this.onDestroy$),
				tap(({ expositionId }) =>
					this.store.dispatch(
						getExpositionHistory({
							expositionId,
						})
					)
				)
			)
			.subscribe();

		this.expositionsSelector
			.getExpositionView$()
			.pipe(
				takeUntil(this.onDestroy$),
				tap((exposition) => {
					this.paths = [
						{
							label: $localize`:i18n=@@expositions.breadcrumb.list:`,
							isActive: true,
							tooltip: $localize`:i18n=@@expositions.breadcrumb.list.tooltip:`,
							callback: (): void => {
								this.store.dispatch(goToExpositionsListRoute());
							},
						},
						{
							label: exposition.metadata.label,
							isActive: true,
							callback: (): void => {
								this.goToExposition();
							},
						},
						{
							label: $localize`:i18n=@@expositions.history.list.path.publicationHistory:`,
							isActive: false,
							callback: (): void => {},
						},
					];
				})
			)
			.subscribe();
	}

	public async goToExposition(): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		this.store.dispatch(
			navigateToExposition({
				expositionId,
			})
		);
	}

	public async refresh(): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		this.store.dispatch(
			refreshExpositionHistory({
				expositionId,
			})
		);
	}

	public search(searchTerm: string): void {
		if (this.grid === null) {
			return;
		}
		this.grid.instance.searchByText(searchTerm);
	}

	public async openColumnsPopover(event: Event, id: number): Promise<void> {
		event.stopPropagation();
		const openedIds = await firstValueFrom(this.openedOverlaySubject);
		this.openedOverlaySubject.next({
			...openedIds,
			columns: id,
		});
	}

	public async openAccessPopover(event: Event, id: number): Promise<void> {
		event.stopPropagation();
		const openedIds = await firstValueFrom(this.openedOverlaySubject);
		this.openedOverlaySubject.next({
			...openedIds,
			access: id,
		});
	}

	public async openEndpointMetadataPopover(
		event: Event,
		id: number
	): Promise<void> {
		event.stopPropagation();
		const openedIds = await firstValueFrom(this.openedOverlaySubject);
		this.openedOverlaySubject.next({
			...openedIds,
			endpointMetadata: id,
		});
	}

	public closePopover(): void {
		this.openedOverlaySubject.next({
			columns: undefined,
			endpointMetadata: undefined,
			access: undefined,
			logs: undefined,
		});
	}

	public async openErrorLogsPopover(
		event: Event,
		history: ExpositionHistoryItemEntity
	): Promise<void> {
		event.stopPropagation();
		if (history.status !== PublicationStatus.Error) {
			return;
		}
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		const openedIds = await firstValueFrom(this.openedOverlaySubject);
		this.openedOverlaySubject.next({
			...openedIds,
			logs: history.id,
		});
		this.store.dispatch(
			getJobErrorMessage({
				expositionId,
				historyId: history.id,
			})
		);
	}
}
