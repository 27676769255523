import { AppTagItemEntity } from '@datachain/ui-sdk/components';
import { Record, Map, Set, OrderedSet } from 'immutable';

import { AppMetadataEntity } from '../../ui/components/app-metadata/app-metadata.entity';
import { ExportCsvConfigurationEntity } from '../components/export-csv-configuration/export-csv-configuration.entity';
import { ExportCharsetEntity } from '../components/export-template-details/export-charset.entity';
import { ExportConnectorEntity } from '../components/export-template-details/export-connector.entity';
import {
	ExportFormat,
	ExportTemplateDetailsEntity,
} from '../components/export-template-details/export-template-details.entity';
import { ExportTemplateListItemEntity } from '../components/export-template-list/export-template-list-item.entity';

export interface IExportTemplateState {
	loading: boolean;
	exportInProgress: boolean;
	hasDirtyFileNamePattern: boolean;
	selectedDatablockLabel: string;
	selectedDatablock: number | undefined;
	metadata: AppMetadataEntity;
	templateDetails: ExportTemplateDetailsEntity;
	connectors: Map<number, ExportConnectorEntity>;
	charsets: Set<ExportCharsetEntity>;
	exportTemplateList: Map<
		number,
		ExportTemplateListItemEntity<ExportTemplateDetailsEntity>
	>;
	availableTags: OrderedSet<AppTagItemEntity>;
}

const defaults: IExportTemplateState = {
	loading: false,
	exportInProgress: false,
	hasDirtyFileNamePattern: false,
	selectedDatablockLabel: '',
	selectedDatablock: undefined,
	metadata: AppMetadataEntity.build(),
	templateDetails: new ExportTemplateDetailsEntity(),
	connectors: Map(),
	charsets: Set(),
	exportTemplateList: Map(),
	availableTags: OrderedSet(),
};

export const ExportTemplateFeatureKey = 'exportTemplate';

export class ExportTemplateState extends Record<IExportTemplateState>(
	defaults
) {
	public static key = ExportTemplateFeatureKey;

	public reset(): ExportTemplateState {
		return this.setExportTemplateConfig(
			ExportTemplateDetailsEntity.build({
				connector: ExportConnectorEntity.localConnector(),
				outputType: ExportFormat.CSV,
				config: ExportCsvConfigurationEntity.build(),
			})
		)
			.setExportMetadata(AppMetadataEntity.build({}))
			.setEmptyList()
			.setHasDirtyFileNamePattern(false)
			.setDatablockId(undefined)
			.setDatablockLabel('');
	}

	public setLoading(isLoading: boolean): ExportTemplateState {
		return this.set('loading', isLoading);
	}

	public setExportTemplateConfig(
		config: ExportTemplateDetailsEntity
	): ExportTemplateState {
		return this.set('templateDetails', config);
	}

	public setExportMetadata(metadata: AppMetadataEntity): ExportTemplateState {
		return this.set('metadata', metadata);
	}

	public setAvailableConnectors(
		connectors: Map<number, ExportConnectorEntity>
	): ExportTemplateState {
		return this.set('connectors', connectors);
	}

	public setAvailableCharsets(
		charsets: Set<ExportCharsetEntity>
	): ExportTemplateState {
		return this.set('charsets', charsets);
	}

	public setExportTemplateList(
		list: Map<number, ExportTemplateListItemEntity<ExportTemplateDetailsEntity>>
	): ExportTemplateState {
		return this.set('exportTemplateList', list);
	}

	public setEmptyList(): ExportTemplateState {
		return this.set('exportTemplateList', Map());
	}

	public setExportInProgress(inProgress: boolean): ExportTemplateState {
		return this.set('exportInProgress', inProgress);
	}

	public setDatablockLabel(
		selectedDatablockLabel: string
	): ExportTemplateState {
		return this.set('selectedDatablockLabel', selectedDatablockLabel);
	}

	public setHasDirtyFileNamePattern(val: boolean): ExportTemplateState {
		return this.set('hasDirtyFileNamePattern', val);
	}

	public setDatablockId(datablockId: number | undefined): ExportTemplateState {
		return this.set('selectedDatablock', datablockId);
	}

	public setTags(tags: OrderedSet<AppTagItemEntity>): ExportTemplateState {
		return this.set('availableTags', tags);
	}
}
