import { immerable, produce } from 'immer';

import { ExpositionColumnConfigListEntity } from '../../domain/exposition-column-config-list.entity';
import { ExpositionEndpointMetadataEntity } from '../exposition-endpoint-metadata-config/exposition-endpoint-metadata.entity';
import { ExpositionMetadataEntity } from '../exposition-internal-metadata-config/exposition-metadata.entity';
import { ExpositionAccessListEntity } from './exposition-access-list/exposition-access-list.entity';
import { PublishedExpositionEntity } from './published-exposition/published-exposition.entity';

interface IExpositionEntity {
	id: number;
	metadata: ExpositionMetadataEntity;
	endpointMetadata: ExpositionEndpointMetadataEntity;
	columns: ExpositionColumnConfigListEntity;
	accessPoints: ExpositionAccessListEntity;
	published: PublishedExpositionEntity;
}

const entityDefaults: IExpositionEntity = {
	id: -1,
	metadata: ExpositionMetadataEntity.build(),
	endpointMetadata: ExpositionEndpointMetadataEntity.build(),
	columns: ExpositionColumnConfigListEntity.build([]),
	accessPoints: ExpositionAccessListEntity.build([]),
	published: PublishedExpositionEntity.build(),
};

export class ExpositionEntity implements IExpositionEntity {
	private [immerable] = true;
	public id = entityDefaults.id;
	public metadata = entityDefaults.metadata;
	public endpointMetadata = entityDefaults.endpointMetadata;
	public columns = entityDefaults.columns;
	public accessPoints = entityDefaults.accessPoints;
	public published = entityDefaults.published;

	public static build(
		params: Partial<IExpositionEntity> = {}
	): ExpositionEntity {
		const instance = new ExpositionEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.metadata = params.metadata ?? entityDefaults.metadata;
		instance.endpointMetadata =
			params.endpointMetadata ?? entityDefaults.endpointMetadata;
		instance.columns = params.columns ?? entityDefaults.columns;
		instance.accessPoints = params.accessPoints ?? entityDefaults.accessPoints;
		instance.published = params.published ?? entityDefaults.published;
		return instance;
	}

	public setMetadata(metadata: ExpositionMetadataEntity): ExpositionEntity {
		return produce(this, (draft: ExpositionEntity) => {
			draft.metadata = metadata;
		});
	}

	public setEndpointMetadata(
		endpointMetadata: ExpositionEndpointMetadataEntity
	): ExpositionEntity {
		return produce(this, (draft: ExpositionEntity) => {
			draft.endpointMetadata = endpointMetadata;
		});
	}

	public setColumns(
		columns: ExpositionColumnConfigListEntity
	): ExpositionEntity {
		return produce(this, (draft: ExpositionEntity) => {
			draft.columns = columns;
		});
	}

	public setAccessPoints(ap: ExpositionAccessListEntity): ExpositionEntity {
		return produce(this, (draft: ExpositionEntity) => {
			draft.accessPoints = ap;
		});
	}

	public setPublishedApi(
		published: PublishedExpositionEntity
	): ExpositionEntity {
		return produce(this, (draft: ExpositionEntity) => {
			draft.published = published;
		});
	}
}
