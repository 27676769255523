<ng-container *ngIf="vo$ | async; let vo">
	<app-panel
		headerHeight="3rem"
		isAlwaysOpen="true"
		hasRefresh="true"
		[hasSearchableGrid]="false"
		[itemsCount]="vo.consumersToMap.length"
		(refresh)="triggerReloadUsers()">
		<span
			panel-title
			i18n="@@exposition.migration.stepper.secondStep.grid.title">
			consumers_mapping_grid
		</span>
		<ng-container *ngIf="vo.consumersToMap.length !== 0">
			<div class="consumers-mapping-step__mapping">
				<span
					i18n="
						@@exposition.migration.stepper.secondStep.mapping.header.source">
					current_consumer
				</span>
				<span
					i18n="
						@@exposition.migration.stepper.secondStep.mapping.header.target">
					target_consumer
				</span>
			</div>
			<mat-divider></mat-divider>
			<div
				*ngFor="let unmapped of vo.consumersToMap; let rowIndex = index"
				class="consumers-mapping-step__mapping">
				<span class="consumers-mapping-step__mapping-source">
					<mat-icon
						*ngIf="unmapped.isGroup"
						[svgIcon]="DcIcons.Group"></mat-icon>
					<span>{{ unmapped.fullName }}</span>
				</span>
				<mat-form-field class="">
					<mat-label i18n="@@exposition.migration.stepper.secondStep.target">
						target
					</mat-label>
					<mat-select
						id="consumer-mapping-{{ rowIndex }}-target-select"
						[value]="unmapped.targetConsumerId"
						(selectionChange)="triggerAssignTargetConsumer($event, unmapped)"
						disableOptionCentering>
						<mat-option
							*ngFor="let targetConsumer of vo.availableConsumers"
							[value]="targetConsumer.id">
							<mat-icon
								*ngIf="targetConsumer.isGroup"
								[svgIcon]="DcIcons.Group"></mat-icon>
							<span>{{ targetConsumer.name }}</span>
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</ng-container>
	</app-panel>
</ng-container>
