import {
	Component,
	EventEmitter,
	Inject,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { combineAll, DcBaseComponent } from '@datachain/ui-sdk/common';
import { PanelAction, AppIcons } from '@datachain/ui-sdk/components';
import { Store } from '@ngrx/store';
import { DxDataGridComponent } from 'devextreme-angular';
import {
	CellHoverChangedEvent,
	SelectionChangedEvent,
} from 'devextreme/ui/data_grid';
import {
	BehaviorSubject,
	debounceTime,
	delay,
	firstValueFrom,
	merge,
	Observable,
	of,
	ReplaySubject,
	takeUntil,
	tap,
} from 'rxjs';

import { ActionIcons } from '../../../ui/app-actions.icons';
import { ComponentIcons } from '../../../ui/app-components.icons';
import { DcIcons } from '../../../ui/app-dc.icons';
import { ActionIconsInjectable } from '../../../ui/ui.module';
import { PublicationStatus } from '../../domain/publication-status';
import {
	confirmationBeforeActivateOnList,
	confirmationBeforeClearDataOnList,
	confirmationBeforeDeactivateOnList,
	confirmBeforeDeleteExpoOnList,
	confirmBeforePublishOnList,
	confirmUpdateExpositionDataOnList,
	ExpositionsSelector,
	fetchExpositionViewOnEndpointDetailsTrigger,
	goToCreateNewExpositionRoute,
	goToExpositionFromDatablockViewState,
	goToExpositionHistoryRoute,
	initExpositionsList,
	navigateToExposition,
	refreshExpositionsList,
} from '../../store';
import { ExpositionEndpointMetadataEntity } from '../exposition-endpoint-metadata-config/exposition-endpoint-metadata.entity';
import { ExpositionItemEntity } from './exposition-item.entity';
import { ExpositionListEntity } from './exposition-list.entity';

type RowAction = {
	description: string;
	actionName: string;
	icon: string;
	action: (...args: Array<unknown>) => void;
};

type ExpoListOverlayTypes = {
	columns: number | undefined;
	access: number | undefined;
	endpointMetadata: number | undefined;
};

@Component({
	selector: 'app-expositions-list',
	templateUrl: './exposition-list.component.html',
	styleUrls: ['./exposition-list.component.scss'],
	inputs: ['datablockId', 'datablockLabel', 'embedded'],
})
export class ExpositionListComponent extends DcBaseComponent implements OnInit {
	private filteredView = false;
	protected readonly DcIcons = DcIcons;
	public ActionIcons = ActionIcons;
	public ComponentIcons = ComponentIcons;
	public currentlyHovered = -1;
	public toggleActions = false;
	public selectionCount = 0;
	public PublicationStatus = PublicationStatus;
	private readonly defaultRowMenuActions: Array<RowAction> = [
		{
			actionName: 'view',
			description: $localize`:i18n=@@expositions.list.viewItem:`,
			action: (item: unknown): void => {
				const exp = item as ExpositionItemEntity;
				this.navigateToExposition(exp.id as number);
			},
			icon: DcIcons.View,
		},
		{
			actionName: 'delete',
			description: $localize`:i18n=@@expositions.list.delete:`,
			action: (item: unknown): void => {
				const exp = item as ExpositionItemEntity;
				this.store.dispatch(
					confirmBeforeDeleteExpoOnList({
						expositionId: [exp.id],
						expositionLabel: exp.label,
					})
				);
			},
			icon: ActionIcons.Delete,
		},
	];
	public columnsActions: Array<PanelAction> = [];
	public rowMenuPublishedActiveActions: Array<RowAction> = [];
	public rowMenuPublishedInActiveActions: Array<RowAction> = [];
	public rowMenuNotPublishedActions: Array<RowAction> = [];

	private readonly expositionListSubject =
		new ReplaySubject<ExpositionListEntity>(1);

	private readonly openedOverlaySubject =
		new BehaviorSubject<ExpoListOverlayTypes>({
			columns: undefined,
			access: undefined,
			endpointMetadata: undefined,
		});

	private selectedDatablockId$: Observable<number | null> = of(null);

	@Output()
	public emitElementCount = new EventEmitter<number>();

	@ViewChild(DxDataGridComponent)
	private readonly grid: DxDataGridComponent | null = null;

	public vo$: Observable<{
		embedded: boolean;
		isLoading: boolean;
		canCreate: boolean;
		list: ExpositionListEntity;
		openedOverlays: ExpoListOverlayTypes;
		endpointMetadata: ExpositionEndpointMetadataEntity;
		isLoadingMetadata: boolean;
	}>;

	public constructor(
		private readonly store: Store,
		private readonly expositionsSelector: ExpositionsSelector,
		@Inject(ActionIconsInjectable) private readonly actionIcons: AppIcons
	) {
		super();
		this.cmpId = 'expositions-list';
		this.dxLocalStorageKey = 'dx.grid.expositions';

		this.vo$ = combineAll({
			embedded: merge(of(false), this.toObservable<boolean>('embedded')),
			isLoading: this.expositionsSelector.getIsLoading$(),
			canCreate: this.expositionsSelector.canCreateNewExposition$(),
			list: this.expositionListSubject,
			openedOverlays: this.openedOverlaySubject,
			endpointMetadata: this.expositionsSelector
				.getEndpointMetadata$()
				.pipe(delay(300)),
			isLoadingMetadata: this.expositionsSelector.getIsLoadingMetadataInfo$(),
		});
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.selectedDatablockId$ = merge(
			of(null).pipe(delay(320)), // wait for 20 frames to make sure datablockId is not set then we are not on datablock edit view
			this.toObservable<number>('datablockId')
		);

		this.selectedDatablockId$
			.pipe(
				takeUntil(this.onDestroy$),
				debounceTime(300),
				tap((datablockId) =>
					this.store.dispatch(
						initExpositionsList({
							datablockId: datablockId ? +datablockId : null,
						})
					)
				)
			)
			.subscribe();

		this.expositionsSelector
			.getExpositions$()
			.pipe(
				debounceTime(320), // time to Devextreme finished loading the list view
				tap((l) => {
					this.emitElementCount.emit(l.elements.size);
					this.expositionListSubject.next(l);
					if (this.grid) {
						this.grid.selectedRowKeys = [];
					}
				})
			)
			.subscribe();

		of(null)
			.pipe(
				takeUntil(this.onDestroy$),
				tap(() => this.bootstrapIcons())
			)
			.subscribe();
	}

	public createNewExposition(): void {
		this.store.dispatch(goToCreateNewExpositionRoute());
	}

	public async createNewExpositionFromEmbeddedView(): Promise<void> {
		const datablockId = await firstValueFrom(this.selectedDatablockId$);
		const datablockLabel = await firstValueFrom(
			this.toObservable<string>('datablockLabel')
		);
		if (datablockId === null) {
			console.warn('no datablock selected');
			return;
		}
		this.store.dispatch(
			goToExpositionFromDatablockViewState({
				id: +datablockId,
				label: datablockLabel ?? '',
			})
		);
	}

	public onCellHover(event: CellHoverChangedEvent): void {
		if (event.eventType === 'mouseover') {
			this.currentlyHovered = event.rowIndex;
		} else {
			this.currentlyHovered = -1;
		}
	}

	public async onSelectionChange($event: SelectionChangedEvent): Promise<void> {
		this.toggleActions = $event.selectedRowsData.length > 0;
		this.selectionCount = $event.selectedRowsData.length;
		const list = await firstValueFrom(this.expositionListSubject);
		this.expositionListSubject.next(
			list.updateSelected($event.selectedRowKeys as Array<string>)
		);
	}

	public search(searchTerm: string): void {
		if (this.grid === null) {
			return;
		}
		this.grid.instance.searchByText(searchTerm);
	}

	public navigateToExposition(expositionId: number): void {
		this.store.dispatch(
			navigateToExposition({
				expositionId,
			})
		);
	}

	public navigateToExpositionHistory(expositionId: number): void {
		this.store.dispatch(
			goToExpositionHistoryRoute({
				expositionId,
			})
		);
	}

	public rowMenuOpen($event: MouseEvent, rowIndex: number): void {
		$event.stopPropagation();
		// fake show menu button
		setTimeout(() => {
			this.currentlyHovered = rowIndex;
		}, 300);
	}

	public edit(): void {}

	public delete(): void {}

	public async refresh(): Promise<void> {
		const datablockId = await firstValueFrom(this.selectedDatablockId$);
		this.store.dispatch(
			refreshExpositionsList({
				expositionId: undefined,
				datablockId: datablockId ? +datablockId : null,
			})
		);
	}

	public publishExposition(item: ExpositionItemEntity): void {
		this.store.dispatch(
			confirmBeforePublishOnList({
				expositionId: [item.id],
				expositionLabel: item.label,
			})
		);
	}

	public updateExpositionData(item: ExpositionItemEntity): void {
		this.store.dispatch(
			confirmUpdateExpositionDataOnList({
				expositionId: [item.id],
				expositionLabel: item.label,
			})
		);
	}

	public async openEndpointMetadataPopover(
		event: Event,
		exposition: ExpositionItemEntity
	): Promise<void> {
		event.stopPropagation();
		const openedIds = await firstValueFrom(this.openedOverlaySubject);
		this.openedOverlaySubject.next({
			...openedIds,
			endpointMetadata: exposition.id,
		});
		this.store.dispatch(
			fetchExpositionViewOnEndpointDetailsTrigger({
				expositionId: exposition.id,
			})
		);
	}

	public closeOverlay(): void {
		this.openedOverlaySubject.next({
			access: undefined,
			columns: undefined,
			endpointMetadata: undefined,
		});
	}

	private filterSelectedOnly(): void {
		if (!this.grid) {
			return;
		}
		if (!this.filteredView) {
			this.filteredView = true;
			const selectedRowKeys = this.grid.instance.getSelectedRowKeys();
			const filter = selectedRowKeys.reduce((acc, key, index) => {
				acc.push(['id', '=', key]);
				if (index < selectedRowKeys.length - 1) {
					acc.push('or');
				}
				return acc;
			}, []);
			this.grid.instance.filter(filter);
		} else {
			this.filteredView = false;
			this.grid.instance.clearFilter();
		}
	}

	private async activateSelected(): Promise<void> {
		if (!this.grid) {
			return;
		}
		const selectedRowKeys = this.grid.selectedRowKeys as Array<string>;
		const selectedExpositions = await firstValueFrom(
			this.expositionsSelector.getSelectedExpositions$(selectedRowKeys)
		);
		if (selectedExpositions.length === 1) {
			return this.store.dispatch(
				confirmationBeforeActivateOnList({
					expositionId: [selectedExpositions[0].id],
					expositionLabel: selectedExpositions[0].label,
				})
			);
		}
		return this.store.dispatch(
			confirmationBeforeActivateOnList({
				expositionId: selectedExpositions.map((k) => k.id),
				expositionLabel: '',
			})
		);
	}

	private async deactivateSelected(): Promise<void> {
		if (!this.grid) {
			return;
		}
		const selectedRowKeys = this.grid.selectedRowKeys as Array<string>;
		const selectedExpositions = await firstValueFrom(
			this.expositionsSelector.getSelectedExpositions$(selectedRowKeys)
		);
		if (selectedExpositions.length === 1) {
			return this.store.dispatch(
				confirmationBeforeDeactivateOnList({
					expositionId: [selectedExpositions[0].id],
					expositionLabel: selectedExpositions[0].label,
				})
			);
		}
		this.store.dispatch(
			confirmationBeforeDeactivateOnList({
				expositionId: selectedRowKeys.map((k) => Number.parseInt(k, 10)),
				expositionLabel: '',
			})
		);
	}

	private async clearDataForSelected(): Promise<void> {
		if (!this.grid) {
			return;
		}
		const selectedRowKeys = this.grid.selectedRowKeys as Array<string>;
		const selectedExpositions = await firstValueFrom(
			this.expositionsSelector.getSelectedExpositions$(selectedRowKeys)
		);
		if (
			selectedExpositions.length === 1 &&
			(selectedExpositions[0].actionInProgress === 'none' ||
				selectedExpositions[0].actionInProgress === undefined)
		) {
			return this.store.dispatch(
				confirmationBeforeClearDataOnList({
					expositionId: [selectedExpositions[0].id],
					expositionLabel: selectedExpositions[0].label,
				})
			);
		}
		this.store.dispatch(
			confirmationBeforeClearDataOnList({
				expositionId: selectedRowKeys
					.filter(
						(key) =>
							selectedExpositions.find((e) => e.id.toString() === key)
								?.actionInProgress === 'none' ||
							selectedExpositions.find((e) => e.id.toString() === key)
								?.actionInProgress === undefined
					)
					.map((k) => Number.parseInt(k, 10)),
				expositionLabel: '',
			})
		);
	}

	private async deleteSelected(): Promise<void> {
		if (!this.grid) {
			return;
		}
		const selectedRowKeys = this.grid.selectedRowKeys as Array<string>;
		const selectedExpositions = await firstValueFrom(
			this.expositionsSelector.getSelectedExpositions$(selectedRowKeys)
		);
		if (selectedExpositions.length === 1) {
			return this.store.dispatch(
				confirmBeforeDeleteExpoOnList({
					expositionId: [selectedExpositions[0].id],
					expositionLabel: selectedExpositions[0].label,
				})
			);
		}
		this.store.dispatch(
			confirmBeforeDeleteExpoOnList({
				expositionId: selectedRowKeys.map((k) => Number.parseInt(k, 10)),
				expositionLabel: '',
			})
		);
	}

	private async publishSelected(): Promise<void> {
		if (!this.grid) {
			return;
		}
		const selectedRowKeys = this.grid.selectedRowKeys as Array<string>;
		const selectedExpositions = await firstValueFrom(
			this.expositionsSelector.getSelectedExpositions$(selectedRowKeys)
		);
		if (selectedExpositions.length === 1) {
			return this.store.dispatch(
				confirmBeforePublishOnList({
					expositionId: [selectedExpositions[0].id],
					expositionLabel: selectedExpositions[0].label,
				})
			);
		}
		this.store.dispatch(
			confirmBeforePublishOnList({
				expositionId: selectedExpositions.map((e) => e.id),
				expositionLabel: '',
			})
		);
	}

	private async updateDataForSelected(): Promise<void> {
		if (!this.grid) {
			return;
		}
		const selectedRowKeys = this.grid.selectedRowKeys as Array<string>;
		const selectedExpositions = await firstValueFrom(
			this.expositionsSelector.getSelectedExpositions$(selectedRowKeys)
		);
		if (selectedExpositions.length === 1) {
			return this.store.dispatch(
				confirmUpdateExpositionDataOnList({
					expositionId: [selectedExpositions[0].id],
					expositionLabel: selectedExpositions[0].label,
				})
			);
		}
		return this.store.dispatch(
			confirmUpdateExpositionDataOnList({
				expositionId: selectedRowKeys.map((k) => Number.parseInt(k, 10)),
				expositionLabel: '',
			})
		);
	}

	private async bootstrapIcons(): Promise<void> {
		const actions = [
			ActionIcons.DeleteExpositionData,
			ActionIcons.UpdateExpositionData,
			ActionIcons.PublishExposition,
			ActionIcons.ActivateExposition,
			ActionIcons.DeActivateExposition,
			ActionIcons.Delete,
			ActionIcons.ColumnExpositionChooser,
			ActionIcons.Filter,
		];
		const actionMap = await firstValueFrom(
			this.actionIcons.getFromRegistry(...actions)
		);
		this.columnsActions = [
			{
				order: 0,
				command: (): void => {
					this.grid?.instance.showColumnChooser();
				},
				iconName: actionMap.get(ActionIcons.ColumnExpositionChooser)?.iconName,
				description: '',
				tooltip: $localize`:i18n=@@expositions.list.columnSelection:`,
				typed: '',
			},
			{
				order: 1,
				command: (): Promise<void> => this.updateDataForSelected(),
				iconName: actionMap.get(ActionIcons.UpdateExpositionData)?.iconName,
				description: '',
				tooltip: $localize`:i18n=@@expositions.list.data:`,
				typed: 'plain-context-action',
			},
			{
				order: 2,
				command: (): Promise<void> => this.activateSelected(),
				iconName: actionMap.get(ActionIcons.ActivateExposition)?.iconName,
				description: '',
				tooltip: $localize`:i18n=@@expositions.list.activate:`,
				typed: 'plain-context-action',
			},
			{
				order: 3,
				command: (): Promise<void> => this.deactivateSelected(),
				iconName: actionMap.get(ActionIcons.DeActivateExposition)?.iconName,
				description: '',
				tooltip: $localize`:i18n=@@expositions.list.deactivate:`,
				typed: 'plain-context-action',
			},
			{
				order: 4,
				command: (): Promise<void> => this.publishSelected(),
				iconName: actionMap.get(ActionIcons.PublishExposition)?.iconName,
				description: '',
				tooltip: $localize`:i18n=@@expositions.list.publish:`,
				typed: 'plain-context-action',
			},
			{
				order: 5,
				command: (): Promise<void> => this.clearDataForSelected(),
				iconName: actionMap.get(ActionIcons.DeleteExpositionData)?.iconName,
				description: '',
				tooltip: $localize`:i18n=@@expositions.list.clear:`,
				typed: 'plain-context-action',
			},
			{
				order: 6,
				command: (): Promise<void> => this.deleteSelected(),
				iconName: actionMap.get(ActionIcons.Delete)?.iconName,
				description: $localize`:i18n=@@expositions.list.delete:`,
				typed: 'grouped-context-action',
			},
			{
				order: 7,
				command: (): void => this.filterSelectedOnly(),
				iconName: actionMap.get(ActionIcons.Filter)?.iconName,
				description: $localize`:i18n=@@expositions.list.filterSelection:`,
				typed: 'grouped-context-action',
			},
		];

		this.rowMenuPublishedActiveActions = [
			...this.defaultRowMenuActions,
			{
				actionName: 'history',
				description: $localize`:i18n=@@expositions.history.action:`,
				action: (item: unknown): void => {
					const exp = item as ExpositionItemEntity;
					this.navigateToExpositionHistory(exp.id as number);
				},
				icon: DcIcons.Update,
			},
			{
				actionName: 'deactivate',
				description: $localize`:i18n=@@expositions.list.deactivate:`,
				action: (item: unknown): void => {
					const exp = item as ExpositionItemEntity;
					this.store.dispatch(
						confirmationBeforeDeactivateOnList({
							expositionId: [exp.id],
							expositionLabel: exp.label,
						})
					);
				},
				icon: ActionIcons.DeActivateExposition,
			},
			{
				actionName: 'delete-data',
				description: $localize`:i18n=@@expositions.list.clear:`,
				action: (item: unknown): void => {
					const exp = item as ExpositionItemEntity;
					this.confirmationBeforeClearData(exp.id, exp.label);
				},
				icon: ActionIcons.DeleteExpositionData,
			},
		];

		this.rowMenuPublishedInActiveActions = [
			...this.defaultRowMenuActions,
			{
				actionName: 'history',
				description: $localize`:i18n=@@expositions.history.action:`,
				action: (item: unknown): void => {
					const exp = item as ExpositionItemEntity;
					this.navigateToExpositionHistory(exp.id as number);
				},
				icon: DcIcons.Update,
			},
			{
				actionName: 'activate',
				description: $localize`:i18n=@@expositions.list.activate:`,
				action: (item: unknown): void => {
					const exp = item as ExpositionItemEntity;
					this.store.dispatch(
						confirmationBeforeActivateOnList({
							expositionId: [exp.id],
							expositionLabel: exp.label,
						})
					);
				},
				icon: ActionIcons.ActivateExposition,
			},
			{
				actionName: 'delete-data',
				description: $localize`:i18n=@@expositions.list.clear:`,
				action: (item: unknown): void => {
					const exp = item as ExpositionItemEntity;
					this.confirmationBeforeClearData(exp.id, exp.label);
				},
				icon: ActionIcons.DeleteExpositionData,
			},
		];

		this.rowMenuNotPublishedActions = [...this.defaultRowMenuActions];
	}

	public async confirmationBeforeClearData(
		id: number,
		label: string
	): Promise<void> {
		const expositions = await firstValueFrom(
			this.expositionsSelector.getExpositions$()
		);
		if (
			!expositions.elements.get(id.toString())?.actionInProgress ||
			expositions.elements.get(id.toString())?.actionInProgress !== 'none'
		) {
			return;
		}
		this.store.dispatch(
			confirmationBeforeClearDataOnList({
				expositionId: [id],
				expositionLabel: label,
			})
		);
	}
}
