import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import {
	combineAll,
	DcBaseComponent,
	IListColumnConfig,
} from '@datachain/ui-sdk/common';
import { Store } from '@ngrx/store';
import { DxDataGridComponent } from 'devextreme-angular';
import { Map } from 'immutable';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';

import { DcIcons } from '../../../ui/app-dc.icons';
import {
	fetchFormulaUsage,
	traceNavigateToReferenced,
} from '../../store/formula.actions';
import { FormulaSelector } from '../../store/formula.selector';
import { FormulaUsageEntity } from './formula-usage.entity';

const DX_DATA_GRID_TIMEOUT = 1000;

@Component({
	selector: 'app-formula-usages-list',
	templateUrl: './formula-usage-list.component.html',
	styleUrls: ['./formula-usage-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormulaUsageListComponent extends DcBaseComponent {
	public DcIcons = DcIcons;
	public DxDataGridTimeout = DX_DATA_GRID_TIMEOUT;
	public tranRefresh = $localize`:@@general.action.refresh:`;
	public tranSelectColumns = $localize`:@@general.action.columnSelector:`;
	public transExpandAll = $localize`:@@general.action.expandAll:`;
	public transCollapseAll = $localize`:@@general.action.collapseAll:`;

	public readonly allowedPageSizes = [5, 10, 100, 'all'];
	public readonly displayMode = 'full';

	@ViewChild(DxDataGridComponent, {
		static: false,
	})
	private readonly dataGrid!: DxDataGridComponent;

	public vo$: Observable<{
		isLoading: boolean;
		isAllExpanded: boolean;
		formulas: Map<string, FormulaUsageEntity>;
		availableColumns: Map<string, IListColumnConfig<FormulaUsageEntity>>;
		usageCount: number;
	}>;

	private readonly isAllExpandedSubject = new BehaviorSubject<boolean>(false);

	public constructor(
		private readonly store: Store,
		private readonly formulaSelector: FormulaSelector
	) {
		super();
		this.cmpId = 'formula-usage';
		this.dxLocalStorageKey = 'dx.grid.formula:usage';

		this.store.dispatch(fetchFormulaUsage());

		this.vo$ = combineAll({
			isLoading: this.formulaSelector.getLoading$(),
			list: this.formulaSelector.getFormulaUsages$(),
			usageCount: this.formulaSelector.getUsageCount$(),
			isAllExpanded: this.isAllExpandedSubject,
		}).pipe(
			map(({ isLoading, list, usageCount, isAllExpanded }) => ({
				isLoading,
				isAllExpanded,
				usageCount,
				formulas: list.elements,
				availableColumns: list.getAvailableColumns(),
			}))
		);
	}

	public navigateTo(url: string, referenced = 'link'): void {
		this.store.dispatch(
			traceNavigateToReferenced({
				url,
				referenced,
			})
		);
	}

	public refresh(): void {
		this.store.dispatch(fetchFormulaUsage());
	}

	public async toggle(): Promise<void> {
		const current = await firstValueFrom(this.isAllExpandedSubject);
		this.isAllExpandedSubject.next(!current);
		return current
			? this.dataGrid.instance.collapseAll(-1)
			: this.dataGrid.instance.expandAll(-1);
	}
}
