import { Injectable } from '@angular/core';
import { UrlService } from '@datachain/ui-sdk/common';
import { Observable } from 'rxjs';

import { DcBaseRequester } from './dc-base.requester';
import { IConnectorModel } from './models/connector.model';

@Injectable({
	providedIn: 'root',
})
export class ConnectorRequester extends DcBaseRequester<IConnectorModel> {
	protected resource;
	protected elementsListUrl;
	protected singleElementUrl;
	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('CONNECTORS');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = this.resource;
	}

	public getAvailableConnectors(
		excludedTypes: Array<string>
	): Observable<Array<IConnectorModel>> {
		const url = this.urlService.generateUrl(
			`${this.resource}`,
			{},
			{
				details: true,
				project: this.projectId,
				excludedTypes,
			}
		);
		return this.get<Array<IConnectorModel>>(url);
	}
}
