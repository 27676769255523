import { Component } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	Validators,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DcValueAccessorComponent, combineAll } from '@datachain/ui-sdk/common';
import { filter, takeUntil, tap } from 'rxjs';

import { ValidationErrorKeys } from '../../../ui/form/validation-erros-keys';
import { ConnectorMode } from '../export-template-details/export-connector.entity';
import { ExportTemplateDetailsHelper } from '../export-template-details/export-template-details.helper';
import { WriteMode } from '../export-write-mode-configuration/export-write-mode-configuration.entity';
import {
	DbTableNamingStrategy,
	ExportDbConfigurationEntity,
} from './export-db-configuration.entity';

interface IDatabaseLabelOptions {
	label: string;
	value: string;
	disabled?: boolean;
}

enum ExportDbFormControls {
	NamingStrategy = 'namingStrategy',
	TargetLabel = 'table/node-Label',
	NodeKeys = 'nodeKeys',
	DbSchema = 'schema',
}

@Component({
	selector: 'app-export-db-configuration',
	templateUrl: './export-db-configuration.component.html',
	styleUrls: ['./export-db-configuration.component.scss'],
	providers: [
		DcValueAccessorComponent.generateAccessorToken(
			ExportDbConfigurationComponent
		),
		DcValueAccessorComponent.generateValidatorToken(
			ExportDbConfigurationComponent
		),
	],
})
export class ExportDbConfigurationComponent extends DcValueAccessorComponent<
	ExportDbConfigurationComponent,
	ExportDbConfigurationEntity
> {
	public Appearance: MatFormFieldAppearance = 'outline';
	public ExportDbFormControls = ExportDbFormControls;
	public ValidationErrorKeys = ValidationErrorKeys;
	public DbTableNamingStrategy = DbTableNamingStrategy;

	public options: Array<IDatabaseLabelOptions> = [
		{
			label: $localize`:i18n=@@export.database.option.useDatablockLabel:`,
			value: DbTableNamingStrategy.SameAsDatablock,
		},
		{
			label: $localize`:i18n=@@export.database.option.userDefinedLabel:`,
			value: DbTableNamingStrategy.UserDefinedLabel,
		},
	];

	public static currentCmpEntity: ExportDbConfigurationEntity | undefined =
		undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static saveCurrent(value: any): void {
		this.currentCmpEntity = ExportDbConfigurationComponent.toEntity(value);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private static toEntity(formValues: any): ExportDbConfigurationEntity {
		return ExportDbConfigurationEntity.build({
			namingStrategy: formValues[ExportDbFormControls.NamingStrategy],
			targetLabel: formValues[ExportDbFormControls.TargetLabel],
			targetKey: formValues[ExportDbFormControls.NodeKeys],
			schema: formValues[ExportDbFormControls.DbSchema],
		});
	}

	public constructor(
		private readonly fb: FormBuilder,
		private readonly exportTemplateDetailsHelper: ExportTemplateDetailsHelper
	) {
		super();
		this.cmpId = 'export-db-config';

		this.form = this.fb.group({
			[ExportDbFormControls.NamingStrategy]: [
				DbTableNamingStrategy.SameAsDatablock,
				Validators.required,
			],
			[ExportDbFormControls.TargetLabel]: ['', Validators.required],
			[ExportDbFormControls.NodeKeys]: ['', Validators.required],
			[ExportDbFormControls.DbSchema]: new FormControl(
				{
					value: '',
					disabled: false,
				},
				[]
			),
		});

		const dbFullFormConfig$ = combineAll({
			namingStrategy: (
				this.form.get(
					ExportDbFormControls.NamingStrategy
				) as FormControl<DbTableNamingStrategy>
			).valueChanges,
			writeMode: this.exportTemplateDetailsHelper.selectedWriteMode$,
			connectorType: this.exportTemplateDetailsHelper.selectedConnectorType$,
			selectedDatablockLabel:
				this.exportTemplateDetailsHelper.getSelectedDatablockLabel$(),
		});

		// on each naming strategy change add or remove controls
		dbFullFormConfig$
			.pipe(
				takeUntil(this.onDestroy$),
				tap(({ namingStrategy, ...fullFormConfig }) => {
					if (namingStrategy === DbTableNamingStrategy.SameAsDatablock) {
						this.form.removeControl(ExportDbFormControls.TargetLabel);
						if (fullFormConfig.writeMode !== WriteMode.Overwrite) {
							this.form.removeControl(ExportDbFormControls.NodeKeys);
						}
						return;
					} else {
						this.form.addControl(
							ExportDbFormControls.TargetLabel,
							new FormControl(fullFormConfig.selectedDatablockLabel, [
								Validators.required,
								Validators.pattern(
									/^(?<first>"?)[^+\-/*\\\";=&|#><^'{}%\[\]]*(\k<first>)$/
								),
							])
						);
					}
					if (
						fullFormConfig.writeMode === WriteMode.Overwrite &&
						fullFormConfig.connectorType === ConnectorMode.Neo4j
					) {
						this.form.addControl(
							ExportDbFormControls.NodeKeys,
							new FormControl('', Validators.required)
						);
					} else if (
						fullFormConfig.writeMode !== WriteMode.Overwrite &&
						fullFormConfig.connectorType === ConnectorMode.Neo4j
					) {
						this.form.removeControl(ExportDbFormControls.NodeKeys);
					}
				})
			)
			.subscribe();

		// on each writing mode change add/remove appropriate controls
		dbFullFormConfig$
			.pipe(
				takeUntil(this.onDestroy$),
				filter(
					(obj) =>
						obj.connectorType === ConnectorMode.SQL ||
						obj.connectorType === ConnectorMode.NoSQL ||
						obj.connectorType === ConnectorMode.Neo4j
				),
				tap((obj) => {
					if (
						obj.writeMode === WriteMode.Overwrite &&
						obj.connectorType === ConnectorMode.Neo4j
					) {
						this.form.addControl(
							ExportDbFormControls.NodeKeys,
							new FormControl('', Validators.required)
						);
					} else {
						this.form.removeControl(ExportDbFormControls.NodeKeys);
					}
					if (
						obj.connectorType === ConnectorMode.NoSQL ||
						obj.connectorType === ConnectorMode.Neo4j
					) {
						this.form.get(ExportDbFormControls.DbSchema)?.disable();
					} else {
						this.form.get(ExportDbFormControls.DbSchema)?.enable();
					}
				})
			)
			.subscribe();

		this.form.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				tap((val) => {
					this.onChange(val);
					ExportDbConfigurationComponent.saveCurrent(val);
					Object.keys(this.form.controls).forEach((k) => {
						const ctrl = this.form.get(k) as AbstractControl;
						ctrl.markAsTouched();
					});
				})
			)
			.subscribe();
	}

	public extract(): ExportDbConfigurationEntity {
		const formValues = this.form.value;
		return ExportDbConfigurationComponent.toEntity(formValues);
	}

	protected writeIntoForm(obj: ExportDbConfigurationEntity): void {
		this.form.patchValue({
			[ExportDbFormControls.NamingStrategy]: obj.namingStrategy,
			[ExportDbFormControls.TargetLabel]: DbTableNamingStrategy.UserDefinedLabel
				? obj.targetLabel
				: '',
			[ExportDbFormControls.NodeKeys]: obj.targetKey ?? '',
			[ExportDbFormControls.DbSchema]: obj.schema ?? '',
		});
		ExportDbConfigurationComponent.saveCurrent(this.form.value);
	}
}
