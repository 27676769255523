import { Injectable } from '@angular/core';
import { AppTagItemEntity } from '@datachain/ui-sdk/components';
import { Store, select } from '@ngrx/store';
import { Map, OrderedSet, Set } from 'immutable';
import { Observable, map, firstValueFrom, distinctUntilChanged } from 'rxjs';

import { IAppRootState } from '../../core/store';
import { AppMetadataEntity } from '../../ui/components/app-metadata/app-metadata.entity';
import { ExportCharsetEntity } from '../components/export-template-details/export-charset.entity';
import { ExportConnectorEntity } from '../components/export-template-details/export-connector.entity';
import { ExportTemplateDetailsEntity } from '../components/export-template-details/export-template-details.entity';
import { ExportTemplateListItemEntity } from '../components/export-template-list/export-template-list-item.entity';
import {
	ExportTemplateFeatureKey,
	ExportTemplateState,
} from './export-template.state';

interface ExportTemplateRootState extends IAppRootState {
	[ExportTemplateFeatureKey]: ExportTemplateState;
}

@Injectable()
export class ExportTemplateSelector {
	public constructor(private readonly store: Store<ExportTemplateRootState>) {}

	private get state(): Observable<ExportTemplateState> {
		return this.store.pipe(select((store) => store[ExportTemplateFeatureKey]));
	}

	public getExportTemplateConfig$(): Observable<ExportTemplateDetailsEntity> {
		return this.state.pipe(map((state) => state.get('templateDetails')));
	}

	public getExportMetadata$(): Observable<AppMetadataEntity> {
		return this.state.pipe(map((state) => state.get('metadata')));
	}

	public getLoading$(): Observable<boolean> {
		return this.state.pipe(map((state) => state.get('loading')));
	}

	public getAvailableConnectors$(): Observable<
		Map<number, ExportConnectorEntity>
	> {
		return this.state.pipe(map((state) => state.get('connectors')));
	}

	public getCharsets$(): Observable<Set<ExportCharsetEntity>> {
		return this.state.pipe(map((state) => state.get('charsets')));
	}

	public getExportTemplateList$(): Observable<
		Map<number, ExportTemplateListItemEntity<ExportTemplateDetailsEntity>>
	> {
		return this.state.pipe(map((state) => state.get('exportTemplateList')));
	}

	public getIsExportInProgress$(): Observable<boolean> {
		return this.state.pipe(map((state) => state.get('exportInProgress')));
	}

	public getSelectedDatablockLabel$(): Observable<string> {
		return this.state.pipe(map((state) => state.get('selectedDatablockLabel')));
	}

	public getSelectedDatablock$(): Observable<number | undefined> {
		return this.state.pipe(map((state) => state.get('selectedDatablock')));
	}

	public getPredictedFileName$(): Observable<string> {
		return this.state.pipe(
			map((state) => state.get('templateDetails').fileNameConfig.predicted)
		);
	}

	public getHasDirtyFileNamePattern$(): Observable<boolean> {
		return this.state.pipe(
			map((state) => state.get('hasDirtyFileNamePattern'))
		);
	}

	public getAvailableTags$(): Observable<OrderedSet<AppTagItemEntity>> {
		return this.state.pipe(map((state) => state.get('availableTags')));
	}

	public async getExportTemplateList(): Promise<
		Map<number, ExportTemplateListItemEntity<ExportTemplateDetailsEntity>>
	> {
		return await firstValueFrom(
			this.getExportTemplateList$().pipe(distinctUntilChanged())
		);
	}
}
