'use strict';

angular
	.module('dcApp')
	.factory('AllDepotService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		'$window',
		'UrlService',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q, $window, UrlService) {
			let AllDepotService = {};

			/**
			 * Get Depot list
			 * @param excludedTypes
			 *      Depot type to be excluded. This can be a string or an array of string
			 * @param includedTypes
			 *      Depot type to be included. This can be a string or an array of string
			 * @param details
			 *      The service should return a lib and the code or a metadata object. True to return metadata object. Default value true
			 * @returns {*}
			 */
			AllDepotService.findAllDepots = function (
				excludedTypes,
				includedTypes,
				details,
				excludePublic,
				excludeInactive
			) {
				const url = UrlService.generateUrl('DEPOTS', {},
					{
						excludePublic: excludePublic ? excludePublic : false,
						excludeInactive: excludeInactive ? excludeInactive : false,
						details: typeof details === 'boolean' ? details : true,
						excludedTypes: excludedTypes,
						includedTypes: includedTypes
					}
				);

				return $http.get(url).then(function (response) {
					return response;
				});
			};

			AllDepotService.downloadLocalFile = function (
				fileName,
				depotId,
				depotType
			) {
				let urlPathForToken = $rootScope.getUrl(
					'CSV_LOCAL_FILE_DEPOT_DOWNLOAD_TOKEN'
				);
				let urlPathForDownload = $rootScope.getUrl(
					'CSV_LOCAL_FILE_DEPOT_DOWNLOAD'
				);
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase().includes('binary')) {
						urlPathForToken = $rootScope.getUrl(
							'BINARY_LOCAL_FILE_DEPOT_DOWNLOAD_TOKEN'
						);
						urlPathForDownload = $rootScope.getUrl(
							'BINARY_LOCAL_FILE_DEPOT_DOWNLOAD'
						);
					} else if (depotType.toLowerCase().includes('excel')) {
						urlPathForToken = $rootScope.getUrl(
							'EXCEL_LOCAL_FILE_DEPOT_DOWNLOAD_TOKEN'
						);
						urlPathForDownload = $rootScope.getUrl(
							'EXCEL_LOCAL_FILE_DEPOT_DOWNLOAD'
						);
					} else if (depotType.toLowerCase().includes('json')) {
						urlPathForToken = $rootScope.getUrl(
							'JSON_LOCAL_FILE_DEPOT_DOWNLOAD_TOKEN'
						);
						urlPathForDownload = $rootScope.getUrl(
							'JSON_LOCAL_FILE_DEPOT_DOWNLOAD'
						);
					} else if (depotType.toLowerCase().includes('parquet')) {
						urlPathForToken = $rootScope.getUrl(
							'PARQUET_LOCAL_FILE_DEPOT_DOWNLOAD_TOKEN'
						);
						urlPathForDownload = $rootScope.getUrl(
							'PARQUET_LOCAL_FILE_DEPOT_DOWNLOAD'
						);
					} else if (depotType.toLowerCase().includes('xml')) {
						urlPathForToken = $rootScope.getUrl(
							'XML_LOCAL_FILE_DEPOT_DOWNLOAD_TOKEN'
						);
						urlPathForDownload = $rootScope.getUrl(
							'XML_LOCAL_FILE_DEPOT_DOWNLOAD'
						);
					}
				}
				var encodedFileName = encodeURIComponent(fileName);
				let url = API_BASE_URL_BACKEND + urlPathForToken;
				url = url.replace('{depotId}', depotId);
				url = url + '?fileName=' + encodedFileName;
				return $http.get(url).then(function (response) {
					const urlPathForDownloadFormatted = urlPathForDownload.replace(
						'{depotId}',
						depotId
					);
					const downloadFileUrl =
						API_BASE_URL_BACKEND +
						urlPathForDownloadFormatted +
						'?token=' +
						encodeURIComponent(response.data);
					$window.open(downloadFileUrl, '_blank');
				});
			};

			AllDepotService.findAllDepotsByConnectorId = function (connectorId) {
				const url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DEPOT_BY_CONNECTOR_ID') +
					'?connectorId=' +
					connectorId;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			AllDepotService.findOneDepotById = function (depotId) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('DEPOTS_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			AllDepotService.deleteDepots = function (depotIds) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('DEPOTS');
				if (Array.isArray(depotIds)) {
					url = url + '?depotsIds=' + depotIds[0];
					for (let i = 1; i < depotIds.length; i++) {
						url = url + '&depotsIds=' + depotIds[i];
					}
				} else {
					url = url + '?depotsIds=' + depotIds;
				}
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			AllDepotService.findAllDepotsHeadersReferencingModes = function () {
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('DEPOTS_REFERENCING_MODES');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			return AllDepotService;
		},
	])
	.factory('DepotService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			let DepotService = {};

			DepotService.updateDepotActivationStatus = function (
				depotId,
				active,
				depotType
			) {
				let urlPath = $rootScope.getUrl('DEPOTS_ACTIVE');
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('CSV_DEPOTS_ACTIVE');
					} else if (depotType.toLowerCase() === 'binary') {
						urlPath = $rootScope.getUrl('BINARY_DEPOTS_ACTIVE');
					} else if (depotType.toLowerCase() === 'sql') {
						urlPath = $rootScope.getUrl('DB_SQL_DEPOTS_ACTIVE');
					} else if (depotType.toLowerCase() === 'mongodb') {
						urlPath = $rootScope.getUrl('MONGODB_DEPOTS_ACTIVE');
					} else if (depotType.toLowerCase() === 'neo4j') {
						urlPath = $rootScope.getUrl('NEO4J_DEPOTS_ACTIVE');
					} else if (depotType.toLowerCase() === 'excel') {
						urlPath = $rootScope.getUrl('EXCEL_DEPOTS_ACTIVE');
					} else if (depotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('JSON_DEPOTS_ACTIVE');
					} else if (depotType.toLowerCase() === 'parquet') {
						urlPath = $rootScope.getUrl('PARQUET_DEPOTS_ACTIVE');
					} else if (depotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('XML_DEPOTS_ACTIVE');
					}
					let url = API_BASE_URL_BACKEND + urlPath;
					url = url.replace('{depotId}', depotId);
					url = url + '?active=' + active;
					return $http.put(url).then(function (response) {
						return response;
					});
				}
			};

			DepotService.createHeader = function (depotId, depotHeader, depotType) {
				let url = API_BASE_URL_BACKEND + getDepotHeadersPath(depotType);
				url = url.replace('{depotId}', depotId);
				return $http.post(url, depotHeader).then(function (response) {
					return response;
				});
			};

			DepotService.editHeader = function (headerId, depotHeader, depotType) {
				let url = API_BASE_URL_BACKEND + getDepotHeadersByIdPath(depotType);
				url = url.replace('{headerId}', headerId);
				return $http.put(url, depotHeader).then(function (response) {
					return response;
				});
			};

			DepotService.deleteHeader = function (headerId, depotType) {
				let url = API_BASE_URL_BACKEND + getDepotHeadersByIdPath(depotType);
				url = url.replace('{headerId}', headerId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			DepotService.deleteAllHeaders = function (depotId, depotType) {
				let url = API_BASE_URL_BACKEND + getDepotHeadersPath(depotType);
				url = url.replace('{depotId}', depotId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			DepotService.updateAllHeadersPositions = function (
				depotId,
				headers,
				reuploadedHeaders,
				depotType
			) {
				let urlPath = $rootScope.getUrl('DEPOT_UPDATE_HEADERS_POSITIONS');
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('CSV_DEPOT_UPDATE_HEADERS_POSITIONS');
					} else if (depotType.toLowerCase() === 'binary') {
						urlPath = $rootScope.getUrl(
							'BINARY_DEPOT_UPDATE_HEADERS_POSITIONS'
						);
					} else if (depotType.toLowerCase() === 'sql') {
						urlPath = $rootScope.getUrl(
							'DB_SQL_DEPOT_UPDATE_HEADERS_POSITIONS'
						);
					} else if (depotType.toLowerCase() === 'mongodb') {
						urlPath = $rootScope.getUrl(
							'MONGODB_DEPOT_UPDATE_HEADERS_POSITIONS'
						);
					} else if (depotType.toLowerCase() === 'neo4j') {
						urlPath = $rootScope.getUrl('NEO4J_DEPOT_UPDATE_HEADERS_POSITIONS');
					} else if (depotType.toLowerCase() === 'excel') {
						urlPath = $rootScope.getUrl('EXCEL_DEPOT_UPDATE_HEADERS_POSITIONS');
					} else if (depotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('JSON_DEPOT_UPDATE_HEADERS_POSITIONS');
					} else if (depotType.toLowerCase() === 'parquet') {
						urlPath = $rootScope.getUrl(
							'PARQUET_DEPOT_UPDATE_HEADERS_POSITIONS'
						);
					} else if (depotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('XML_DEPOT_UPDATE_HEADERS_POSITIONS');
					}
					let url = API_BASE_URL_BACKEND + urlPath;
					url = url.replace('{depotId}', depotId);
					reuploadedHeaders =
						reuploadedHeaders !== undefined ? reuploadedHeaders : false;
					url = url + '?headresReUploaded=' + reuploadedHeaders;
					return $http.put(url, headers).then(function (response) {
						return response;
					});
				}
			};

			DepotService.getHeadersByDepotId = function (depotId, depotType) {
				let url = API_BASE_URL_BACKEND + getDepotHeadersPath(depotType);
				url = url.replace('{depotId}', depotId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			DepotService.detectCharset = function (depotId, depotType) {
				let urlPath = $rootScope.getUrl('DEPOT_DETECT_CHARSET');
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('CSV_DEPOT_DETECT_CHARSET');
					} else if (depotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('JSON_DEPOT_DETECT_CHARSET');
					} else if (depotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('XML_DEPOT_DETECT_CHARSET');
					}
					let url = API_BASE_URL_BACKEND + urlPath;
					url = url.replace('{depotId}', depotId);
					return $http.get(url).then(
						function (response) {
							return response;
						},
						function (err) {
							return $q.reject(err);
						}
					);
				}
			};

			DepotService.getHeadersFromDepot = function (
				depot,
				inferSchema,
				depotType
			) {
				inferSchema = inferSchema !== undefined ? inferSchema : false;
				let urlPath = $rootScope.getUrl('HEADERS_FROM_DEPOT');
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('HEADERS_FROM_CSV_DEPOT');
					} else if (depotType.toLowerCase() === 'binary') {
						urlPath = $rootScope.getUrl('HEADERS_FROM_BINARY_DEPOT');
					} else if (depotType.toLowerCase() === 'sql') {
						urlPath = $rootScope.getUrl('HEADERS_FROM_DB_SQL_DEPOT');
					} else if (depotType.toLowerCase() === 'mongodb') {
						urlPath = $rootScope.getUrl('HEADERS_FROM_MONGODB_DEPOT');
					} else if (depotType.toLowerCase() === 'neo4j') {
						urlPath = $rootScope.getUrl('HEADERS_FROM_NEO4J_DEPOT');
					} else if (depotType.toLowerCase() === 'excel') {
						urlPath = $rootScope.getUrl('HEADERS_FROM_EXCEL_DEPOT');
					} else if (depotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('HEADERS_FROM_JSON_DEPOT');
					} else if (depotType.toLowerCase() === 'parquet') {
						urlPath = $rootScope.getUrl('HEADERS_FROM_PARQUET_DEPOT');
					} else if (depotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('HEADERS_FROM_XML_DEPOT');
					}
					let url = API_BASE_URL_BACKEND + urlPath;
					url = url + '?inferSchema=' + inferSchema;
					return $http.post(url, depot).then(
						function (response) {
							return response;
						},
						function (err) {
							return $q.reject(err);
						}
					);
				}
			};

			let getDepotHeadersPath = function (depotType) {
				let urlPath = $rootScope.getUrl('DEPOT_HEADER');
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('CSV_DEPOT_HEADERS');
					} else if (depotType.toLowerCase() === 'binary') {
						urlPath = $rootScope.getUrl('BINARY_DEPOT_HEADERS');
					} else if (depotType.toLowerCase() === 'sql') {
						urlPath = $rootScope.getUrl('DB_SQL_DEPOT_HEADERS');
					} else if (depotType.toLowerCase() === 'mongodb') {
						urlPath = $rootScope.getUrl('MONGODB_DEPOT_HEADERS');
					} else if (depotType.toLowerCase() === 'neo4j') {
						urlPath = $rootScope.getUrl('NEO4J_DEPOT_HEADERS');
					} else if (depotType.toLowerCase() === 'excel') {
						urlPath = $rootScope.getUrl('EXCEL_DEPOT_HEADERS');
					} else if (depotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('JSON_DEPOT_HEADERS');
					} else if (depotType.toLowerCase() === 'parquet') {
						urlPath = $rootScope.getUrl('PARQUET_DEPOT_HEADERS');
					} else if (depotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('XML_DEPOT_HEADERS');
					}
				}

				return urlPath;
			};

			let getDepotHeadersByIdPath = function (depotType) {
				let urlPath = $rootScope.getUrl('HEADER_BY_ID');
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('CSV_HEADER_BY_ID');
					} else if (depotType.toLowerCase() === 'binary') {
						urlPath = $rootScope.getUrl('BINARY_HEADER_BY_ID');
					} else if (depotType.toLowerCase() === 'sql') {
						urlPath = $rootScope.getUrl('DB_SQL_HEADER_BY_ID');
					} else if (depotType.toLowerCase() === 'mongodb') {
						urlPath = $rootScope.getUrl('MONGODB_HEADER_BY_ID');
					} else if (depotType.toLowerCase() === 'neo4j') {
						urlPath = $rootScope.getUrl('NEO4J_HEADER_BY_ID');
					} else if (depotType.toLowerCase() === 'excel') {
						urlPath = $rootScope.getUrl('EXCEL_HEADER_BY_ID');
					} else if (depotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('JSON_HEADER_BY_ID');
					} else if (depotType.toLowerCase() === 'parquet') {
						urlPath = $rootScope.getUrl('PARQUET_HEADER_BY_ID');
					} else if (depotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('XML_HEADER_BY_ID');
					}
				}

				return urlPath;
			};

			return DepotService;
		},
	])
	.factory('DepotExtractionFilterService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		function ($http, $rootScope, API_BASE_URL_BACKEND) {
			let DepotExtractionFilterService = {};

			DepotExtractionFilterService.findAllExtractionsFilters = function (
				depotId,
				depotType
			) {
				let url = API_BASE_URL_BACKEND + getDepotFiltersPath(depotType);
				url = url.replace('{depotId}', depotId);
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			DepotExtractionFilterService.createExtractionFilter = function (
				depotId,
				filter,
				depotType
			) {
				let url = API_BASE_URL_BACKEND + getDepotFiltersPath(depotType);
				url = url.replace('{depotId}', depotId);
				return $http.post(url, filter).then(function (response) {
					return response;
				});
			};

			DepotExtractionFilterService.editExtractionFilter = function (
				filterId,
				filter,
				depotType
			) {
				let url = API_BASE_URL_BACKEND + getDepotFiltersByIdPath(depotType);
				url = url.replace('{filterId}', filterId);
				return $http.put(url, filter).then(function (response) {
					return response;
				});
			};

			DepotExtractionFilterService.deleteExtractionFilter = function (
				filterId,
				depotType
			) {
				let url = API_BASE_URL_BACKEND + getDepotFiltersByIdPath(depotType);
				url = url.replace('{filterId}', filterId);
				return $http.delete(url).then(function (response) {
					return response;
				});
			};

			let getDepotFiltersPath = function (depotType) {
				let urlPath = $rootScope.getUrl('DEPOT_FILTERS');
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('CSV_DEPOT_FILTERS');
					} else if (depotType.toLowerCase() === 'binary') {
						urlPath = $rootScope.getUrl('BINARY_DEPOT_FILTERS');
					} else if (depotType.toLowerCase() === 'sql') {
						urlPath = $rootScope.getUrl('DB_SQL_DEPOT_FILTERS');
					} else if (depotType.toLowerCase() === 'mongodb') {
						urlPath = $rootScope.getUrl('MONGODB_DEPOT_FILTERS');
					} else if (depotType.toLowerCase() === 'excel') {
						urlPath = $rootScope.getUrl('EXCEL_DEPOT_FILTERS');
					} else if (depotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('JSON_DEPOT_FILTERS');
					} else if (depotType.toLowerCase() === 'parquet') {
						urlPath = $rootScope.getUrl('PARQUET_DEPOT_FILTERS');
					} else if (depotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('XML_DEPOT_FILTERS');
					} else if (depotType.toLowerCase() === 'neo4j') {
						urlPath = $rootScope.getUrl('NEO4J_DEPOT_FILTERS');
					}
				}

				return urlPath;
			};

			let getDepotFiltersByIdPath = function (depotType) {
				let urlPath = $rootScope.getUrl('DEPOT_FILTERS_BY_ID');
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('CSV_DEPOT_FILTERS_BY_ID');
					} else if (depotType.toLowerCase() === 'binary') {
						urlPath = $rootScope.getUrl('BINARY_DEPOT_FILTERS_BY_ID');
					} else if (depotType.toLowerCase() === 'sql') {
						urlPath = $rootScope.getUrl('DB_SQL_DEPOT_FILTERS_BY_ID');
					} else if (depotType.toLowerCase() === 'mongodb') {
						urlPath = $rootScope.getUrl('MONGODB_DEPOT_FILTERS_BY_ID');
					} else if (depotType.toLowerCase() === 'excel') {
						urlPath = $rootScope.getUrl('EXCEL_DEPOT_FILTERS_BY_ID');
					} else if (depotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('JSON_DEPOT_FILTERS_BY_ID');
					} else if (depotType.toLowerCase() === 'parquet') {
						urlPath = $rootScope.getUrl('PARQUET_DEPOT_FILTERS_BY_ID');
					} else if (depotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('XML_DEPOT_FILTERS_BY_ID');
					} else if (depotType.toLowerCase() === 'neo4j') {
						urlPath = $rootScope.getUrl('NEO4J_DEPOT_FILTERS_BY_ID');
					}
				}

				return urlPath;
			};

			return DepotExtractionFilterService;
		},
	])
	.factory('DepotExtractionService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		'UrlParamService',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q, UrlParamService) {
			let DepotExtractionService = {};

			DepotExtractionService.getExtractions = function (depotId, depotType) {
				let url = API_BASE_URL_BACKEND + getDepotExtractionsPath(depotType);
				url = url.replace('{depotId}', depotId);
				return $http.get(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			DepotExtractionService.deleteExtraction = function (
				depotId,
				extractionName,
				depotType
			) {
				let url = API_BASE_URL_BACKEND + getDepotExtractionsPath(depotType);
				url = url.replace('{depotId}', depotId);
				url =
					url +
					'?extractionName=' +
					UrlParamService.escapeCharsForEncoding(extractionName);
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			DepotExtractionService.getExtractionPreview = function (
				depotId,
				extractionName,
				depotType
			) {
				extractionName = UrlParamService.escapeCharsForEncoding(extractionName);
				let urlPath = $rootScope.getUrl('DEPOT_EXTRACTIONS_PREVIEW');

				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('CSV_DEPOT_EXTRACTIONS_PREVIEW');
					} else if (depotType.toLowerCase() === 'binary') {
						urlPath = $rootScope.getUrl('BINARY_DEPOT_EXTRACTIONS_PREVIEW');
					} else if (depotType.toLowerCase() === 'sql') {
						urlPath = $rootScope.getUrl('DB_SQL_DEPOT_EXTRACTIONS_PREVIEW');
					} else if (depotType.toLowerCase() === 'mongodb') {
						urlPath = $rootScope.getUrl('MONGODB_DEPOT_EXTRACTIONS_PREVIEW');
					} else if (depotType.toLowerCase() === 'neo4j') {
						urlPath = $rootScope.getUrl('NEO4J_DEPOT_EXTRACTIONS_PREVIEW');
					} else if (depotType.toLowerCase() === 'excel') {
						urlPath = $rootScope.getUrl('EXCEL_DEPOT_EXTRACTIONS_PREVIEW');
					} else if (depotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('JSON_DEPOT_EXTRACTIONS_PREVIEW');
					} else if (depotType.toLowerCase() === 'parquet') {
						urlPath = $rootScope.getUrl('PARQUET_DEPOT_EXTRACTIONS_PREVIEW');
					} else if (depotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('XML_DEPOT_EXTRACTIONS_PREVIEW');
					}
					let url = API_BASE_URL_BACKEND + urlPath;
					url = url.replace('{depotId}', depotId);
					url = url + '?extractionName=' + extractionName;
					if ($rootScope.jobCacheActive) {
						url = url + '&useCache=' + true;
					}
					return $http.get(url).then(
						function (response) {
							return response;
						},
						function (err) {
							return $q.reject(err);
						}
					);
				}
			};

			DepotExtractionService.createExtraction = function (depotId, depotType) {
				let url = API_BASE_URL_BACKEND + getDepotExtractionsPath(depotType);
				url = url.replace('{depotId}', depotId);
				return $http.post(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			let getDepotExtractionsPath = function (depotType) {
				let urlPath = $rootScope.getUrl('DEPOT_EXTRACTIONS');
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('CSV_DEPOT_EXTRACTIONS');
					} else if (depotType.toLowerCase() === 'binary') {
						urlPath = $rootScope.getUrl('BINARY_DEPOT_EXTRACTIONS');
					} else if (depotType.toLowerCase() === 'sql') {
						urlPath = $rootScope.getUrl('DB_SQL_DEPOT_EXTRACTIONS');
					} else if (depotType.toLowerCase() === 'mongodb') {
						urlPath = $rootScope.getUrl('MONGODB_DEPOT_EXTRACTIONS');
					} else if (depotType.toLowerCase() === 'neo4j') {
						urlPath = $rootScope.getUrl('NEO4J_DEPOT_EXTRACTIONS');
					} else if (depotType.toLowerCase() === 'excel') {
						urlPath = $rootScope.getUrl('EXCEL_DEPOT_EXTRACTIONS');
					} else if (depotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('JSON_DEPOT_EXTRACTIONS');
					} else if (depotType.toLowerCase() === 'parquet') {
						urlPath = $rootScope.getUrl('PARQUET_DEPOT_EXTRACTIONS');
					} else if (depotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('XML_DEPOT_EXTRACTIONS');
					}
				}

				return urlPath;
			};

			return DepotExtractionService;
		},
	])
	.factory('DepotFileService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		'UrlParamService',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q, UrlParamService) {
			let DepotFileService = {};

			DepotFileService.getDepotRemoteFiles = function (
				depotId,
				path,
				filenameMask,
				depotType,
				limit,
				includeTemp
			) {
				let url = API_BASE_URL_BACKEND + getDepotRemoteFilesPath(depotType);
				url = url.replace('{depotId}', depotId);
				includeTemp = includeTemp !== undefined ? includeTemp : false;
				let pathInUrl = path
					? '&remotePath=' + UrlParamService.escapeCharsForEncoding(path)
					: '';
				url =
					url +
					'?limit=' +
					limit +
					'&includeTemp=' +
					includeTemp +
					'&filenameMask=' +
					UrlParamService.escapeCharsForEncoding(filenameMask) +
					pathInUrl;
				return $http.get(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			DepotFileService.getRemoteFilePreview = function (
				depotId,
				fileName,
				depotType
			) {
				let urlPath = $rootScope.getUrl('DEPOT_FILE_PREVIEW');
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('CSV_DEPOT_FILE_PREVIEW');
					} else if (depotType.toLowerCase() === 'binary') {
						urlPath = $rootScope.getUrl('BINARY_DEPOT_FILE_PREVIEW');
					} else if (depotType.toLowerCase() === 'excel') {
						urlPath = $rootScope.getUrl('EXCEL_DEPOT_FILE_PREVIEW');
					} else if (depotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('JSON_DEPOT_FILE_PREVIEW');
					} else if (depotType.toLowerCase() === 'parquet') {
						urlPath = $rootScope.getUrl('PARQUET_DEPOT_FILE_PREVIEW');
					} else if (depotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('XML_DEPOT_FILE_PREVIEW');
					}
					let url = API_BASE_URL_BACKEND + urlPath;
					url = url.replace('{depotId}', depotId);
					url =
						url +
						'?fileName=' +
						UrlParamService.escapeCharsForEncoding(fileName);
					if ($rootScope.jobCacheActive) {
						url = url + '&useCache=' + true;
					}
					return $http.get(url).then(
						function (response) {
							return response;
						},
						function (err) {
							return $q.reject(err);
						}
					);
				}
			};

			DepotFileService.getRemoteBinaryFilePreview = function (
				depotId,
				fileName
			) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('BINARY_DEPOT_FILE_PREVIEW');
				url = url.replace('{depotId}', depotId);
				return $http
					.get(
						url +
							'?fileName=' +
							UrlParamService.escapeCharsForEncoding(fileName)
					)
					.then(
						function (response) {
							return response;
						},
						function (err) {
							return $q.reject(err);
						}
					);
			};

			DepotFileService.getDataPreviewForDepot = function (depotId, depotType) {
				let urlPath = $rootScope.getUrl('DEPOT_FILE_PREVIEW');
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase().includes('csv')) {
						urlPath = $rootScope.getUrl('CSV_DEPOT_PREVIEW');
					} else if (depotType.toLowerCase().includes('sql')) {
						urlPath = $rootScope.getUrl('DB_SQL_PREVIEW');
					} else if (depotType.toLowerCase().includes('mongodb')) {
						urlPath = $rootScope.getUrl('MONGO_DB_SQL_PREVIEW');
					} else if (depotType.toLowerCase().includes('binary')) {
						urlPath = $rootScope.getUrl('BINARY_DEPOT_PREVIEW');
					} else if (depotType.toLowerCase().includes('excel')) {
						urlPath = $rootScope.getUrl('EXCEL_DEPOT_PREVIEW');
					} else if (depotType.toLowerCase().includes('json')) {
						urlPath = $rootScope.getUrl('JSON_DEPOT_PREVIEW');
					} else if (depotType.toLowerCase().includes('parquet')) {
						urlPath = $rootScope.getUrl('PARQUET_DEPOT_PREVIEW');
					} else if (depotType.toLowerCase().includes('xml')) {
						urlPath = $rootScope.getUrl('XML_DEPOT_PREVIEW');
					}
					let url = API_BASE_URL_BACKEND + urlPath;
					url = url.replace('{depotId}', depotId);
					return $http.get(url).then(
						function (response) {
							return response;
						},
						function (err) {
							return $q.reject(err);
						}
					);
				}
			};

			DepotFileService.deleteFileByName = function (file, depotId, depotType) {
				let fileName = encodeURIComponent(file.name);
				let isTemp = file.temp !== undefined ? file.temp : false;
				let url = API_BASE_URL_BACKEND + getDepotRemoteFilesPath(depotType);
				url = url.replace('{depotId}', depotId);
				url =
					url +
					'?fileName=' +
					UrlParamService.escapeCharsForEncoding(fileName) +
					'&isTemp=' +
					isTemp;
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			let getDepotRemoteFilesPath = function (depotType) {
				let urlPath = $rootScope.getUrl('DEPOT_REMOTE_DEPOT_FILES');
				if (depotType && typeof depotType === 'string') {
					if (depotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('CSV_DEPOT_REMOTE_DEPOT_FILES');
					} else if (depotType.toLowerCase() === 'binary') {
						urlPath = $rootScope.getUrl('BINARY_DEPOT_REMOTE_DEPOT_FILES');
					} else if (depotType.toLowerCase() === 'excel') {
						urlPath = $rootScope.getUrl('EXCEL_DEPOT_REMOTE_DEPOT_FILES');
					} else if (depotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('JSON_DEPOT_REMOTE_DEPOT_FILES');
					} else if (depotType.toLowerCase() === 'parquet') {
						urlPath = $rootScope.getUrl('PARQUET_DEPOT_REMOTE_DEPOT_FILES');
					} else if (depotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('XML_DEPOT_REMOTE_DEPOT_FILES');
					}
				}

				return urlPath;
			};

			DepotFileService.moveTmpFolderOnTypeUpdate = function (
				oldDepotType,
				newDepotType,
				depotCode
			) {
				let urlPath = '/';
				if (newDepotType && typeof newDepotType === 'string') {
					if (newDepotType.toLowerCase() === 'csv') {
						urlPath = $rootScope.getUrl('CSV_MOVE_TEMP_FOLDER_ON_TYPE_UPDATE');
					} else if (newDepotType.toLowerCase() === 'binary') {
						urlPath = $rootScope.getUrl(
							'BINARY_MOVE_TEMP_FOLDER_ON_TYPE_UPDATE'
						);
					} else if (newDepotType.toLowerCase() === 'excel') {
						urlPath = $rootScope.getUrl(
							'EXCEL_MOVE_TEMP_FOLDER_ON_TYPE_UPDATE'
						);
					} else if (newDepotType.toLowerCase() === 'json') {
						urlPath = $rootScope.getUrl('JSON_MOVE_TEMP_FOLDER_ON_TYPE_UPDATE');
					} else if (newDepotType.toLowerCase() === 'parquet') {
						urlPath = $rootScope.getUrl(
							'PARQUET_MOVE_TEMP_FOLDER_ON_TYPE_UPDATE'
						);
					} else if (newDepotType.toLowerCase() === 'xml') {
						urlPath = $rootScope.getUrl('XML_MOVE_TEMP_FOLDER_ON_TYPE_UPDATE');
					}
				}
				let url = API_BASE_URL_BACKEND + urlPath;
				url =
					url +
					'?oldDepotType=' +
					oldDepotType +
					'&newDepotType=' +
					newDepotType +
					'&depotCode=' +
					UrlParamService.escapeCharsForEncoding(depotCode);
				return $http.put(url).then(function (response) {
					return response;
				});
			};

			return DepotFileService;
		},
	])
	.factory('DbSQLDepotService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			let DbSQLDepotService = {};

			DbSQLDepotService.getDbDepots = function () {
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('DB_SQL_DEPOTS');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			DbSQLDepotService.getDbPreview = function (depot, withData) {
				let endPoint =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('DB_SQL_DEPOT_QUERY_PREVIEW');
				let url =
					withData !== undefined
						? endPoint + '?withData=' + withData
						: endPoint;
				return $http.post(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			DbSQLDepotService.getDbDepotById = function (depotId, withHeaders) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('DB_SQL_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				withHeaders = withHeaders !== undefined ? withHeaders : false;
				url = url + '?withHeaders=' + withHeaders;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			DbSQLDepotService.createDbDepot = function (depot) {
				return $http
					.post(
						API_BASE_URL_BACKEND + $rootScope.getUrl('DB_SQL_DEPOTS'),
						depot
					)
					.then(
						function (response) {
							return response;
						},
						function (err) {
							return $q.reject(err);
						}
					);
			};

			DbSQLDepotService.editDbDepot = function (depotId, depot) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('DB_SQL_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.put(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			DbSQLDepotService.deleteDbDepot = function (depotId) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('DB_SQL_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			DbSQLDepotService.getHeadersFromDb = function (depot) {
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('HEADERS_FROM_DB_SQL_DEPOT');
				return $http.post(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			return DbSQLDepotService;
		},
	])
	.factory('CsvDepotService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			let CsvDepotService = {};

			CsvDepotService.getCsvDepots = function () {
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('CSV_DEPOTS');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			CsvDepotService.getCsvDepotById = function (depotId, withHeaders) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('CSV_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				withHeaders = withHeaders !== undefined ? withHeaders : false;
				url = url + '?withHeaders=';
				return $http.get(url + withHeaders).then(function (response) {
					return response;
				});
			};

			CsvDepotService.createCsvDepot = function (depot) {
				return $http
					.post(API_BASE_URL_BACKEND + $rootScope.getUrl('CSV_DEPOTS'), depot)
					.then(
						function (response) {
							return response;
						},
						function (err) {
							return $q.reject(err);
						}
					);
			};

			CsvDepotService.editCsvDepot = function (depotId, depot) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('CSV_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.put(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			CsvDepotService.deleteCsvDepot = function (depotId) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('CSV_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			return CsvDepotService;
		},
	])
	.factory('ParquetDepotService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			let ParquetDepotService = {};

			ParquetDepotService.getParquetDepots = function () {
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('PARQUET_DEPOTS');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ParquetDepotService.getParquetDepotById = function (
				depotId,
				withHeaders
			) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PARQUET_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				withHeaders = withHeaders !== undefined ? withHeaders : false;
				url = url + '?withHeaders=' + withHeaders;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ParquetDepotService.createParquetDepot = function (depot) {
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('PARQUET_DEPOTS');
				return $http.post(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			ParquetDepotService.editParquetDepot = function (depotId, depot) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PARQUET_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.put(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			ParquetDepotService.deleteParquetDepot = function (depotId) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('PARQUET_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			return ParquetDepotService;
		},
	])
	.factory('JsonDepotService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			let JsonDepotService = {};

			JsonDepotService.getJsonDepots = function () {
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('JSON_DEPOTS');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			JsonDepotService.getJsonDepotById = function (depotId, withHeaders) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('JSON_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				withHeaders = withHeaders !== undefined ? withHeaders : false;
				url = url + '?withHeaders=' + withHeaders;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			JsonDepotService.createJsonDepot = function (depot) {
				return $http
					.post(API_BASE_URL_BACKEND + $rootScope.getUrl('JSON_DEPOTS'), depot)
					.then(
						function (response) {
							return response;
						},
						function (err) {
							return $q.reject(err);
						}
					);
			};

			JsonDepotService.editJsonDepot = function (depotId, depot) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('JSON_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.put(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			JsonDepotService.deleteJsonDepot = function (depotId) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('JSON_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			return JsonDepotService;
		},
	])
	.factory('ExcelDepotService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			let ExcelDepotService = {};

			ExcelDepotService.getExcelDepots = function () {
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('EXCEL_DEPOTS');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ExcelDepotService.getExcelDepotById = function (depotId, withHeaders) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('EXCEL_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				withHeaders = withHeaders !== undefined ? withHeaders : false;
				url = url + '?withHeaders=' + withHeaders;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			ExcelDepotService.createExcelDepot = function (depot) {
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('EXCEL_DEPOTS');
				return $http.post(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			ExcelDepotService.editExcelDepot = function (depotId, depot) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('EXCEL_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.put(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			ExcelDepotService.deleteExcelDepot = function (depotId) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('EXCEL_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			return ExcelDepotService;
		},
	])
	.factory('BinaryDepotService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			let BinaryDepotService = {};

			BinaryDepotService.getBinaryDepots = function () {
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('BINARY_DEPOTS');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			BinaryDepotService.getBinaryDepotById = function (depotId, withHeaders) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('BINARY_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				withHeaders = withHeaders !== undefined ? withHeaders : false;
				url = url + '?withHeaders=' + withHeaders;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			BinaryDepotService.createBinaryDepot = function (depot) {
				return $http
					.post(
						API_BASE_URL_BACKEND + $rootScope.getUrl('BINARY_DEPOTS'),
						depot
					)
					.then(
						function (response) {
							return response;
						},
						function (err) {
							return $q.reject(err);
						}
					);
			};

			BinaryDepotService.editBinaryDepot = function (depotId, depot) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('BINARY_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.put(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			BinaryDepotService.deleteBinaryDepot = function (depotId) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('BINARY_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			return BinaryDepotService;
		},
	])
	.factory('XmlDepotService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			let XmlDepotService = {};

			XmlDepotService.getXmlDepots = function () {
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('XML_DEPOTS');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			XmlDepotService.getXmlDepotById = function (depotId, withHeaders) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('XML_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				withHeaders = withHeaders !== undefined ? withHeaders : false;
				url = url + '?withHeaders=' + withHeaders;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			XmlDepotService.createXmlDepot = function (depot) {
				return $http
					.post(API_BASE_URL_BACKEND + $rootScope.getUrl('XML_DEPOTS'), depot)
					.then(
						function (response) {
							return response;
						},
						function (err) {
							return $q.reject(err);
						}
					);
			};

			XmlDepotService.editXmlDepot = function (depotId, depot) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('XML_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.put(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			XmlDepotService.deleteXmlDepot = function (depotId) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('XML_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			return XmlDepotService;
		},
	])
	.factory('MongodbDepotService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			let MongodbDepotService = {};

			MongodbDepotService.getMongodbDepots = function () {
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('MONGODB_DEPOTS');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			MongodbDepotService.getMongodbPreview = function (depot, withData) {
				let endPoint =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('MONGODB_DEPOT_QUERY_PREVIEW');
				let url =
					withData !== undefined
						? endPoint + '?withData=' + withData
						: endPoint;
				return $http.post(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			MongodbDepotService.getMongodbDepotById = function (
				depotId,
				withHeaders
			) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('MONGODB_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				withHeaders = withHeaders !== undefined ? withHeaders : false;
				url = url + '?withHeaders=' + withHeaders;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			MongodbDepotService.createMongodbDepot = function (depot) {
				return $http
					.post(
						API_BASE_URL_BACKEND + $rootScope.getUrl('MONGODB_DEPOTS'),
						depot
					)
					.then(
						function (response) {
							return response;
						},
						function (err) {
							return $q.reject(err);
						}
					);
			};

			MongodbDepotService.editMongodbDepot = function (depotId, depot) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('MONGODB_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.put(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			MongodbDepotService.deleteMongodbDepot = function (depotId) {
				let url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('MONGODB_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			MongodbDepotService.getHeadersFromMongodb = function (depot) {
				const url =
					API_BASE_URL_BACKEND +
					$rootScope.getUrl('HEADERS_FROM_MONGODB_DEPOT');
				return $http.post(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			return MongodbDepotService;
		},
	])
	.factory('Neo4jDepotService', [
		'$http',
		'$rootScope',
		'API_BASE_URL_BACKEND',
		'$q',
		function ($http, $rootScope, API_BASE_URL_BACKEND, $q) {
			let Neo4jDepotService = {};

			Neo4jDepotService.getNeo4jDepots = function () {
				const url = API_BASE_URL_BACKEND + $rootScope.getUrl('NEO4J_DEPOTS');
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			Neo4jDepotService.getNeo4jPreview = function (depot, withData) {
				let endPoint =
					API_BASE_URL_BACKEND + $rootScope.getUrl('NEO4J_DEPOT_QUERY_PREVIEW');
				let url =
					withData !== undefined
						? endPoint + '?withData=' + withData
						: endPoint;
				return $http.post(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			Neo4jDepotService.getNeo4jDepotById = function (depotId, withHeaders) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('NEO4J_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				withHeaders = withHeaders !== undefined ? withHeaders : false;
				url = url + '?withHeaders=' + withHeaders;
				return $http.get(url).then(function (response) {
					return response;
				});
			};

			Neo4jDepotService.createNeo4jDepot = function (depot) {
				return $http
					.post(API_BASE_URL_BACKEND + $rootScope.getUrl('NEO4J_DEPOTS'), depot)
					.then(
						function (response) {
							return response;
						},
						function (err) {
							return $q.reject(err);
						}
					);
			};

			Neo4jDepotService.editNeo4jDepot = function (depotId, depot) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('NEO4J_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.put(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			Neo4jDepotService.deleteNeo4jDepot = function (depotId) {
				let url = API_BASE_URL_BACKEND + $rootScope.getUrl('NEO4J_DEPOT_BY_ID');
				url = url.replace('{depotId}', depotId);
				return $http.delete(url).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			Neo4jDepotService.getHeadersFromNeo4j = function (depot) {
				const url =
					API_BASE_URL_BACKEND + $rootScope.getUrl('HEADERS_FROM_NEO4J_DEPOT');
				return $http.post(url, depot).then(
					function (response) {
						return response;
					},
					function (err) {
						return $q.reject(err);
					}
				);
			};

			return Neo4jDepotService;
		},
	]);
