import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineAll, DcBaseComponent } from '@datachain/ui-sdk/common';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

import { ComponentIcons } from '../../../../ui/app-components.icons';
import { DcIcons } from '../../../../ui/app-dc.icons';
import { PublicationStatus } from '../../../domain/publication-status';
import { goToExpositionHistoryRoute } from '../../../store';
import { PublishedExpositionEntity } from './published-exposition.entity';

type EndPoints = { rest: boolean; odata: boolean };

@Component({
	selector: 'app-published-exposition',
	templateUrl: './published-exposition.component.html',
	styleUrls: ['./published-exposition.component.scss'],
	inputs: ['published'],
})
export class PublishedExpositionComponent extends DcBaseComponent {
	public readonly endPointsOverlay$ = new BehaviorSubject<EndPoints>({
		rest: false,
		odata: false,
	});

	public vo$: Observable<{
		published: PublishedExpositionEntity;
	}>;

	protected readonly PublicationStatus = PublicationStatus;
	protected readonly DcIcons = DcIcons;
	protected readonly ComponentIcons = ComponentIcons;
	public constructor(
		private readonly store: Store,
		private readonly snackBar: MatSnackBar
	) {
		super();
		this.cmpId = 'exposition-api-publication';

		this.vo$ = combineAll({
			published: this.toObservable<PublishedExpositionEntity>('published'),
		});
	}

	public async goToHistoryRoute(): Promise<void> {
		const published = await firstValueFrom(
			this.toObservable<PublishedExpositionEntity>('published')
		);
		this.store.dispatch(
			goToExpositionHistoryRoute({
				expositionId: published.id,
			})
		);
	}

	public openSnackBar(): void {
		this.snackBar.open($localize`:i18n=@@general.copied:`, '', {
			duration: 2000,
		});
	}

	public async openEndpointMetadataPopover(
		event: Event,
		val: 'REST' | 'ODATA'
	): Promise<void> {
		event.stopPropagation();
		const current = await firstValueFrom(this.endPointsOverlay$);
		this.endPointsOverlay$.next({
			rest: val === 'REST' ? true : current.rest,
			odata: val === 'ODATA' ? true : current.odata,
		});
	}

	public closeEndpointMetadataPopover(): void {
		this.endPointsOverlay$.next({
			rest: false,
			odata: false,
		});
	}
}
