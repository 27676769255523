import { IComparable } from '@datachain/ui-sdk/common';
import { immerable } from 'immer';

export enum WriteMode {
	None = 'none',
	Append = 'append',
	Overwrite = 'overwrite',
}

interface IExportWriteModeConfigurationEntity {
	mode: WriteMode;
	isEnabled: boolean;
}

const entityDefaults: IExportWriteModeConfigurationEntity = {
	mode: WriteMode.Overwrite,
	isEnabled: true,
};

export class ExportWriteModeConfigurationEntity
	implements
		IExportWriteModeConfigurationEntity,
		IComparable<ExportWriteModeConfigurationEntity>
{
	private [immerable] = true;
	public mode = entityDefaults.mode;
	public isEnabled = entityDefaults.isEnabled;

	public static build(
		params: Partial<IExportWriteModeConfigurationEntity> = {}
	): ExportWriteModeConfigurationEntity {
		const instance = new ExportWriteModeConfigurationEntity();
		instance.mode = params.mode ?? entityDefaults.mode;
		instance.isEnabled = params.isEnabled ?? entityDefaults.isEnabled;
		return instance;
	}
	private constructor() {}

	public compareTo(obj: ExportWriteModeConfigurationEntity): number {
		return this.mode.localeCompare(obj.mode);
	}
}
