import { Injectable } from '@angular/core';
import { Map, Set } from 'immutable';

import {
	ConnectorType,
	IConnectorModel,
	IExportConnectorConfig,
	IExportTemplateResponse,
	ISupportedCharset,
} from '../requesters';
import { AppMetadataEntity } from '../ui/components/app-metadata/app-metadata.entity';
import { ExportCsvConfigurationEntity } from './components/export-csv-configuration/export-csv-configuration.entity';
import {
	DbTableNamingStrategy,
	ExportDbConfigurationEntity,
} from './components/export-db-configuration/export-db-configuration.entity';
import { ExportExcelConfigurationEntity } from './components/export-excel-configuration/export-excel-configuration.entity';
import {
	ExportFileConfigurationEntity,
	FileNameGenStrategy,
} from './components/export-file-configuration/export-file-configuration.entity';
import { ExportPartitionConfigurationEntity } from './components/export-partition-configuration/export-partition-configuration.entity';
import { ExportCharsetEntity } from './components/export-template-details/export-charset.entity';
import {
	ConnectorMode,
	ExportConnectorEntity,
} from './components/export-template-details/export-connector.entity';
import {
	ExportFormat,
	ExportNoConfigEntity,
	ExportTemplateConfig,
	ExportTemplateDetailsEntity,
} from './components/export-template-details/export-template-details.entity';
import { ExportTemplateListItemEntity } from './components/export-template-list/export-template-list-item.entity';
import { ExportTxtMetaConfigurationEntity } from './components/export-txt-meta-configuration/export-txt-meta-configuration.entity';
import {
	ExportWriteModeConfigurationEntity,
	WriteMode,
} from './components/export-write-mode-configuration/export-write-mode-configuration.entity';
import { ExportXmlConfigurationEntity } from './components/export-xml-configuration/export-xml-configuration.entity';

export type ExportTemplateTypeBE =
	| 'csv'
	| 'txt'
	| 'xml'
	| 'parquet'
	| 'json'
	| 'database'
	| 'neo4j'
	| 'excel';

@Injectable()
export class ExportTemplateAdapter {
	private readonly DatabaseType = 'DATABASE';
	private readonly templateType: { [k: string]: ExportFormat } = {
		CSV: ExportFormat.CSV,
		TXT: ExportFormat.TXT_META,
		XML: ExportFormat.XML,
		PARQUET: ExportFormat.Parquet,
		JSON: ExportFormat.JSON,
		EXCEL: ExportFormat.Excel,
		DATABASE: ExportFormat.None,
	};

	private readonly fileNamingStrategy: { [k: string]: FileNameGenStrategy } = {
		UUID: FileNameGenStrategy.UUID,
		FILE_NAME: FileNameGenStrategy.UserDefinedFileNamePattern,
	};

	public parseTemplateBasicDetails(response: IExportTemplateResponse): {
		id: number;
		type: ExportTemplateTypeBE;
	} {
		if (response.id === undefined || response.config.type === undefined) {
			throw new Error('missing id or type from export template response');
		}
		const type = this.getExportTemplateType(response.config.type.toLowerCase());
		if (!type) {
			throw new Error('could not parse export template type');
		}
		return {
			id: response.id,
			type,
		};
	}

	public generateList(
		res: ReadonlyArray<IExportTemplateResponse>,
		shouldExcludeInactive = false
	): Map<number, ExportTemplateListItemEntity<ExportTemplateDetailsEntity>> {
		const tuples = res
			.filter((res) => {
				if (shouldExcludeInactive) {
					return res.metadata?.actif;
				}
				return true;
			})
			.map<[number, ExportTemplateListItemEntity<ExportTemplateDetailsEntity>]>(
				(item, idx) => {
					const tConfig = item.config;
					const metadata = AppMetadataEntity.build({
						id: item.metadata?.id ?? -1,
						title: item.metadata?.label ?? '',
						description: item.metadata?.description ?? '',
						code: item.metadata?.code,
						createdBy: item.metadata?.created_by,
						updatedBy: item.metadata?.updated_by,
						creationDate: item.metadata?.creation_date,
						updateDate: item.metadata?.update_date,
						iconId: item.metadata?.icon_id,
						project: {
							id: item.metadata?.project?.id ?? -1,
							isPublic: item.metadata?.project?.is_public ?? false,
							metadata: {
								label: item.metadata?.project?.metadata?.label ?? '',
								title: item.metadata?.project?.metadata?.label ?? '',
							},
						},
					});
					const exportDetails = ExportTemplateDetailsEntity.build({
						id: item.id,
						outputType:
							tConfig.type !== 'DATABASE' && tConfig.type !== 'NEO4J'
								? this.templateType[tConfig.type]
								: ('DATABASE' as ExportFormat),
						connector: this.generateConnector(tConfig.connector),
					});
					return [
						item.id ?? idx,
						new ExportTemplateListItemEntity<ExportTemplateDetailsEntity>(
							exportDetails,
							metadata
						),
					];
				}
			);
		return Map(tuples);
	}

	public generateExportTemplate(
		res: IExportTemplateResponse
	): ExportTemplateDetailsEntity {
		const { config } = res;
		return ExportTemplateDetailsEntity.build({
			id: res.id,
			outputType: config.type
				? this.templateType[config.type]
				: ExportFormat.None,
			fileNameConfig: this.generateFileConfigOptions(res),
			config: this.generateConfiguration(res),
			connector: this.generateConnector(res.config.connector),
			partition: this.generatePartitioningOpts(res),
			writeMode: this.generateWritingOptions(res),
			compress: config.compress ? (config.compress as boolean) : false,
			keepOriginalLabels: config.keep_original_labels
				? (config.keep_original_labels as boolean)
				: false,
			path: config.path ? (config.path as string) : '',
		});
	}

	public generateConnectorsList(
		res: ReadonlyArray<IConnectorModel>,
		filterOut = ConnectorMode.None
	): Map<number, ExportConnectorEntity> {
		const tuples = res
			.map<[number, ExportConnectorEntity]>((item, idx) => {
				const type = this.getConnectorType(item.connector_type);
				return [
					idx + 1,
					ExportConnectorEntity.build({
						id: item.id,
						label: `${item.metadata.label} (${type.toUpperCase()})`,
						type,
					}),
				];
			})
			.filter(
				([id, e]) => e.type !== ConnectorMode.None && e.type !== filterOut
			);
		tuples.push([0, ExportConnectorEntity.localConnector()]);
		return Map(tuples);
	}

	public generateCharsets(
		response: ReadonlyArray<ISupportedCharset>
	): Set<ExportCharsetEntity> {
		if (!Array.isArray(response)) {
			return Set();
		}
		const tuple = response
			.map<ExportCharsetEntity>((item) =>
				ExportCharsetEntity.build({
					value: item.label,
					label: item.value,
				})
			)
			.sort((e1, e2) => e1.compareTo(e2));
		return Set(tuple);
	}

	private generateConfiguration(
		res: IExportTemplateResponse
	): ExportTemplateConfig {
		const { type } = res.config;
		if (type === 'csv' || type === 'CSV') {
			return this.getExportCsvConfig(res);
		}
		if (type === 'txt' || type === 'TXT') {
			return this.getExportTxtMetaConfig(res);
		}
		if (type === 'xml' || type === 'XML') {
			return this.getExportXmlConfig(res);
		}
		if (type === 'excel' || type === 'EXCEL') {
			return this.getExportExcelConfig(res);
		}
		if (type === 'database' || type === 'DATABASE') {
			return this.getExportDatabaseConfig(res);
		}
		if (type === 'neo4j' || type === 'NEO4J') {
			return this.getExportNeo4jConfig(res);
		}
		return new ExportNoConfigEntity();
	}

	private getExportCsvConfig(
		res: IExportTemplateResponse
	): ExportCsvConfigurationEntity {
		const { config } = res;
		return ExportCsvConfigurationEntity.build({
			withHeaders: config.with_headers
				? (config.with_headers as boolean)
				: false,
			keepLeadingWhiteSpace: config.keep_leading_white_space
				? (config.keep_leading_white_space as boolean)
				: false,
			keepTrailingWhiteSpace: config.keep_trailing_white_space
				? (config.keep_trailing_white_space as boolean)
				: false,
			separator: config.separator ? (config.separator as string) : '',
			quote: config.quote ? (config.quote as string) : '',
			shouldApplyOnAllFields: config.should_apply_on_all_fields
				? (config.should_apply_on_all_fields as boolean)
				: false,
			shouldApplyEmptyNullFieldPlaceholderValue:
				config.should_apply_invalid_field_placeholder_value
					? (config.should_apply_invalid_field_placeholder_value as boolean)
					: false,
			emptyNullFieldPlaceholderValue: config.empty_null_field_placeholder_value
				? (config.empty_null_field_placeholder_value as string)
				: '',
			escapeCharacter: config.escape_character
				? (config.escape_character as string)
				: '',
			encoding: config.encoding ? (config.encoding as string) : '',
		});
	}

	private getExportTxtMetaConfig(
		res: IExportTemplateResponse
	): ExportTxtMetaConfigurationEntity {
		const { config } = res;
		return ExportTxtMetaConfigurationEntity.build({
			withHeaders: config.with_headers
				? (config.with_headers as boolean)
				: false,
			keepLeadingWhiteSpace: config.keep_leading_white_space
				? (config.keep_leading_white_space as boolean)
				: false,
			keepTrailingWhiteSpace: config.keep_trailing_white_space
				? (config.keep_trailing_white_space as boolean)
				: false,
			separator: config.separator ? (config.separator as string) : '',
			quote: config.quote ? (config.quote as string) : '',
			shouldApplyOnAllFields: config.should_apply_on_all_fields
				? (config.should_apply_on_all_fields as boolean)
				: false,
			shouldApplyEmptyNullFieldPlaceholderValue:
				config.should_apply_invalid_field_placeholder_value
					? (config.should_apply_invalid_field_placeholder_value as boolean)
					: false,
			emptyNullFieldPlaceholderValue: config.empty_null_field_placeholder_value
				? (config.empty_null_field_placeholder_value as string)
				: '',
			escapeCharacter: config.escape_character
				? (config.escape_character as string)
				: '',
			encoding: config.encoding ? (config.encoding as string) : '',
			metadata: config.metadata ? (config.metadata as string) : '',
		});
	}

	private getExportXmlConfig(
		res: IExportTemplateResponse
	): ExportXmlConfigurationEntity {
		const { config } = res;
		return ExportXmlConfigurationEntity.build({
			groupTag: config.group_tag
				? (config.group_tag as string)
				: 'no group tag',
			childTag: config.child_tag
				? (config.child_tag as string)
				: 'no child tag',
		});
	}

	private getExportExcelConfig(
		res: IExportTemplateResponse
	): ExportExcelConfigurationEntity {
		const { config } = res;
		return ExportExcelConfigurationEntity.build({
			cell: config.cell_coordinates
				? (config.cell_coordinates as string)
				: 'no cell',
			withHeaders: config.with_headers
				? (config.with_headers as boolean)
				: false,
		});
	}

	private getExportDatabaseConfig(
		res: IExportTemplateResponse
	): ExportDbConfigurationEntity {
		const { config } = res;
		const strategy = config.naming_strategy
			? this.getTableNamingStrategy(config.naming_strategy as string)
			: DbTableNamingStrategy.None;
		return ExportDbConfigurationEntity.build({
			targetLabel:
				strategy === DbTableNamingStrategy.UserDefinedLabel
					? config.key
						? (config.key as string)
						: 'unspecified table name'
					: '',
			namingStrategy: strategy,
			schema: config.schema ? (config.schema as string) : '',
		});
	}

	private getExportNeo4jConfig(
		res: IExportTemplateResponse
	): ExportDbConfigurationEntity {
		const { config } = res;
		const strategy = config.naming_strategy
			? this.getTableNamingStrategy(config.naming_strategy as string)
			: DbTableNamingStrategy.None;
		return ExportDbConfigurationEntity.build({
			targetLabel:
				strategy === DbTableNamingStrategy.UserDefinedLabel
					? config.key
						? (config.key as string)
						: 'unspecified table name'
					: '',
			namingStrategy: strategy,
			targetKey: config.node_keys ? (config.node_keys as string) : '',
		});
	}

	private generatePartitioningOpts(
		res: IExportTemplateResponse
	): ExportPartitionConfigurationEntity {
		return ExportPartitionConfigurationEntity.build({
			count: res.config.partition_count
				? (res.config.partition_count as number)
				: -1,
		});
	}

	private generateFileConfigOptions(
		res: IExportTemplateResponse
	): ExportFileConfigurationEntity {
		const { config } = res;
		const type = config.type ? (config.type as string) : undefined;
		if (type !== this.DatabaseType) {
			if (config.file_name) {
				return ExportFileConfigurationEntity.build({
					strategy: FileNameGenStrategy.UserDefinedFileNamePattern,
					userDefinedLabel: config.file_name as string,
				});
			}
			return ExportFileConfigurationEntity.build({
				strategy: config.naming_strategy
					? this.fileNamingStrategy[config.naming_strategy as string]
					: FileNameGenStrategy.UUID,
			});
		}
		return ExportFileConfigurationEntity.build({});
	}

	private generateWritingOptions(
		res: IExportTemplateResponse
	): ExportWriteModeConfigurationEntity {
		const { config } = res;
		const type = config.type ? (config.type as string) : undefined;
		const mode = config.mode ? (config.mode as string) : undefined;
		return type === 'database' ||
			type === 'DATABASE' ||
			type === 'neo4j' ||
			type === 'NEO4J'
			? ExportWriteModeConfigurationEntity.build({
					isEnabled: true,
					mode: this.selectWritingMode(mode),
			  })
			: ExportWriteModeConfigurationEntity.build({
					isEnabled: false,
					mode: WriteMode.None,
			  });
	}

	private getConnectorType(type: ConnectorType | string): ConnectorMode {
		switch (type) {
			case 'SQL':
				return ConnectorMode.SQL;
			case 'NEO4J':
				return ConnectorMode.Neo4j;
			case 'MONGODB':
				return ConnectorMode.NoSQL;
			case 'HDFS':
				return ConnectorMode.HDFS;
			case 'HTTP':
				return ConnectorMode.HTTP;
			case 'S3':
				return ConnectorMode.S3;
			case 'SFTP':
				return ConnectorMode.SFTP;
			default:
				return ConnectorMode.None;
		}
	}

	private getExportTemplateType(
		type: string
	): ExportTemplateTypeBE | undefined {
		if (type === 'csv') {
			return 'csv';
		}
		if (type === 'xml') {
			return 'xml';
		}
		if (type === 'database') {
			return 'database';
		}
		if (type === 'neo4j') {
			return 'neo4j';
		}
		if (type === 'json') {
			return 'json';
		}
		if (type === 'parquet') {
			return 'parquet';
		}
		if (type === 'excel') {
			return 'excel';
		}
		if (type === 'txt') {
			return 'txt';
		}
		return undefined;
	}

	private getTableNamingStrategy(res: string): DbTableNamingStrategy {
		if (res === 'SAME_AS_DATABLOCK') {
			return DbTableNamingStrategy.SameAsDatablock;
		}
		if (res === 'USER_DEFINED_LABEL') {
			return DbTableNamingStrategy.UserDefinedLabel;
		}
		return DbTableNamingStrategy.None;
	}

	private selectWritingMode(mode: string | undefined): WriteMode {
		if (mode === 'OVERWRITE') {
			return WriteMode.Overwrite;
		}
		if (mode === 'APPEND') {
			return WriteMode.Append;
		}
		return WriteMode.None;
	}

	private generateConnector(
		config: IExportConnectorConfig | undefined | null
	): ExportConnectorEntity {
		if (config === null || config === undefined) {
			return ExportConnectorEntity.localConnector();
		}
		return ExportConnectorEntity.build({
			id: config.id,
			type: this.getConnectorType(config.connector_type),
			label: config.lib,
		});
	}
}
