import {
	BaseSimulationType,
	SimulationBaseEntity,
} from './simulation-base.entity';

interface ISimulationGroupEntity {
	id: number;
	name: string;
}

const entityDefaults: ISimulationGroupEntity = {
	id: -1,
	name: '',
};

export class SimulationGroupEntity
	extends SimulationBaseEntity
	implements ISimulationGroupEntity
{
	protected simType: BaseSimulationType = 'Groups';
	public id = entityDefaults.id;
	public name = entityDefaults.name;

	public constructor(params: Partial<ISimulationGroupEntity>) {
		super();
		this.id = params.id ?? entityDefaults.id;
		this.name = params.name ?? entityDefaults.name;
	}
}
