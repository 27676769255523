<app-modal *ngIf="vo$ | async; let vo" (triggerClose)="close()">
	<ng-container modal-body>
		<app-exposition-access-config
			[accessConfig]="vo.access"
			(hasValidityChanged)="
				modifyValidity($event)
			"></app-exposition-access-config>
	</ng-container>

	<div modal-footer class="app-expositions-access-modal__footer">
		<button
			*ngIf="vo.canEdit"
			[color]="'primary'"
			id="{{ cmpId }}-save-btn"
			class="app-expositions-access-modal__footer__btn"
			mat-raised-button
			[disabled]="!vo.isValid"
			(click)="save()">
			<mat-icon [svgIcon]="DcIcons.Save"></mat-icon>
			<span *ngIf="vo.isNewConfig" i18n="@@general.action.save">save</span>
			<span *ngIf="!vo.isNewConfig" i18n="@@general.action.update">update</span>
		</button>
	</div>
</app-modal>
