import produce, { immerable } from 'immer';

interface IExpositionPermissionEntity {
	create: boolean;
	edit: boolean;
	view: boolean;
	delete: boolean;
}

const entityDefaults: IExpositionPermissionEntity = {
	create: false,
	edit: false,
	view: false,
	delete: false,
};

export class ExpositionPermissionsEntity
	implements IExpositionPermissionEntity
{
	private [immerable] = true;

	public create = entityDefaults.create;
	public edit = entityDefaults.edit;
	public view = entityDefaults.view;
	public delete = entityDefaults.delete;

	public static build(
		params: Partial<IExpositionPermissionEntity>
	): ExpositionPermissionsEntity {
		return new ExpositionPermissionsEntity();
	}

	private constructor() {}

	public setHasCreateGlobalPermission(
		val: boolean
	): ExpositionPermissionsEntity {
		return produce(this, (draft: ExpositionPermissionsEntity) => {
			draft.create = val;
		});
	}

	public setExpositionViewPermissions(perms: {
		delete: boolean;
		view: boolean;
		edit: boolean;
	}): ExpositionPermissionsEntity {
		return produce(this, (draft: ExpositionPermissionsEntity) => {
			draft.edit = perms.edit;
			draft.delete = perms.delete;
			draft.view = perms.view;
		});
	}
}
