export const states = {
	DatablockList: 'datablocks',
	DatablockEdit: 'datablocks-edit',
	ExportTemplateList: 'export-template-list',
	ExportTemplateEdit: 'export-template-edit',
	ExpositionsList: 'expositions',
	ExpositionCreate: 'exposition-new',
	ExpositionView: 'exposition-view',
	ExpositionEditColumns: 'exposition-edit-columns',
	ExpositionEditApi: 'exposition-edit-api',
	ExpositionInitMigration: 'exposition-init-migration',
	ExpositionListMigration: 'expositions-migrate',
	ExpositionHistory: 'exposition-history',
	ExpositionDataSimulator: 'exposition-data-simulator',
	ExpositionFromDatablockList: 'exposition-from-datablock-list',
	ExpositionFromDatablockView: 'exposition-from-datablock-view',
	Home: 'home',
	Projects: 'projects',
};
