import { Injectable } from '@angular/core';
import {
	CachePolicy,
	CachePolicyEntity,
	UrlService,
} from '@datachain/ui-sdk/common';
import { Observable } from 'rxjs';

import { DcBaseRequester } from './dc-base.requester';
import { IMarketPlaceMembers } from './models/marketplace-members.model';

@Injectable({
	providedIn: 'root',
})
export class DcMarketPlaceRequester extends DcBaseRequester<IMarketPlaceMembers> {
	protected resource: string;
	protected elementsListUrl;
	protected singleElementUrl;
	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('DC_MARKETPLACE_MEMBERS');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = this.resource;
	}

	public getMarketPlaceMembers(): Observable<IMarketPlaceMembers> {
		const url = this.urlService.generateUrl(this.resource, {}, {});
		const cachingPolicy = CachePolicyEntity.build({
			policy: CachePolicy.NetworkAndCache,
			evictTimeout: 60,
			isCacheable: true,
		});

		return this.get<IMarketPlaceMembers>(url, cachingPolicy);
	}

	public getMarketPlaceMembersOnProjectImport(): Observable<IMarketPlaceMembers> {
		let url = this.generateResourceUrl('PROJECTS_IMPORT_DC_MARKETPLACE_MEMBERS');
		url = this.urlService.generateUrl(url, {}, {});
		const cachingPolicy = CachePolicyEntity.build({
			policy: CachePolicy.NetworkAndCache,
			evictTimeout: 60,
			isCacheable: true,
		});

		return this.get<IMarketPlaceMembers>(url, cachingPolicy);
	}
}
