import { Component } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	Validators,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DcValueAccessorComponent, combineAll } from '@datachain/ui-sdk/common';
import { Set } from 'immutable';
import { Observable, takeUntil, tap } from 'rxjs';

import { ValidationErrorKeys } from '../../../ui/form/validation-erros-keys';
import { ExportCharsetEntity } from '../export-template-details/export-charset.entity';
import { ExportCsvConfigurationEntity } from './export-csv-configuration.entity';

enum ExportCsvFormControls {
	Encoding = 'encoding',
	Separator = 'separator',
	EscapeCharacter = 'escapeCharacter',
	Quote = 'field-quotes',
	QuoteAll = 'quote-all-fields',
	ShouldApplyInvalidFieldPlaceholderValue = 'ShouldApplyInvalidFieldPlaceholderValue',
	InvalidFieldValuePlaceholderValue = 'EmptyNullFieldValuePlaceholderValue',
	KeepLeftWhiteSpace = 'keepLeftWhiteSpace',
	KeepRightWhiteSpace = 'keepRightWhiteSpace',
	KeepHeaders = 'keepHeaders',
}

@Component({
	selector: 'app-export-csv-configuration',
	templateUrl: 'export-csv-configuration.component.html',
	styleUrls: ['export-csv-configuration.component.scss'],
	inputs: ['charsets'],
	providers: [
		DcValueAccessorComponent.generateAccessorToken(
			ExportCsvConfigurationComponent
		),
		DcValueAccessorComponent.generateValidatorToken(
			ExportCsvConfigurationComponent
		),
	],
})
export class ExportCsvConfigurationComponent extends DcValueAccessorComponent<
	ExportCsvConfigurationComponent,
	ExportCsvConfigurationEntity
> {
	public Appearance: MatFormFieldAppearance = 'outline';
	public ExportCsvFormControls = ExportCsvFormControls;
	public ValidationErrorKeys = ValidationErrorKeys;
	public vo$: Observable<{
		charsets: Set<ExportCharsetEntity>;
	}>;
	public static currentCmpEntity: ExportCsvConfigurationEntity | undefined =
		undefined;

	private static toEntity(
		formValues: Record<string, never>
	): ExportCsvConfigurationEntity {
		return ExportCsvConfigurationEntity.build({
			encoding: formValues[ExportCsvFormControls.Encoding],
			separator: formValues[ExportCsvFormControls.Separator],
			escapeCharacter: formValues[ExportCsvFormControls.EscapeCharacter],
			quote: formValues[ExportCsvFormControls.Quote],
			shouldApplyOnAllFields: formValues[ExportCsvFormControls.QuoteAll],
			keepTrailingWhiteSpace:
				formValues[ExportCsvFormControls.KeepRightWhiteSpace],
			keepLeadingWhiteSpace:
				formValues[ExportCsvFormControls.KeepLeftWhiteSpace],
			withHeaders: formValues[ExportCsvFormControls.KeepHeaders],
			emptyNullFieldPlaceholderValue:
				formValues[ExportCsvFormControls.InvalidFieldValuePlaceholderValue],
			shouldApplyEmptyNullFieldPlaceholderValue:
				formValues[
					ExportCsvFormControls.ShouldApplyInvalidFieldPlaceholderValue
				],
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static saveCurrent(value: any): void {
		this.currentCmpEntity = ExportCsvConfigurationComponent.toEntity(value);
	}

	public constructor(private readonly fb: FormBuilder) {
		super();
		this.cmpId = 'export-csv-config';

		this.form = this.fb.group({
			[ExportCsvFormControls.Encoding]: ['UTF-8', Validators.required],
			[ExportCsvFormControls.Separator]: this.fb.control<string>(';', [
				Validators.required,
				Validators.maxLength(1),
			]),
			[ExportCsvFormControls.EscapeCharacter]: ['\\', Validators.required],
			[ExportCsvFormControls.QuoteAll]: [false],
			[ExportCsvFormControls.KeepLeftWhiteSpace]: [false],
			[ExportCsvFormControls.KeepRightWhiteSpace]: [false],
			[ExportCsvFormControls.KeepHeaders]: [false],
			[ExportCsvFormControls.ShouldApplyInvalidFieldPlaceholderValue]: false,
			[ExportCsvFormControls.Quote]: this.fb.control<string>(
				{
					value: '"',
					disabled: true,
				},
				[Validators.required, Validators.maxLength(1)]
			),
			[ExportCsvFormControls.InvalidFieldValuePlaceholderValue]:
				new FormControl({
					value: '',
					disabled: true,
				}),
		});

		this.form.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				tap((val) => {
					this.onChange(val);
					ExportCsvConfigurationComponent.saveCurrent(val);
					Object.keys(this.form.controls).forEach((k) => {
						const ctrl = this.form.get(k) as AbstractControl;
						ctrl.markAsTouched();
					});
				})
			)
			.subscribe();

		this.form
			.get(ExportCsvFormControls.QuoteAll)
			?.valueChanges.pipe(
				takeUntil(this.onDestroy$),
				tap((value) => {
					const control = this.form.get(ExportCsvFormControls.Quote);
					if (control && value) {
						control.enable();
					} else if (control && !value) {
						control.disable();
					}
				})
			)
			.subscribe();

		this.form
			.get(ExportCsvFormControls.ShouldApplyInvalidFieldPlaceholderValue)
			?.valueChanges.pipe(
				takeUntil(this.onDestroy$),
				tap((value) => {
					const control = this.form.get(
						ExportCsvFormControls.InvalidFieldValuePlaceholderValue
					);
					if (control && value) {
						control.enable();
					} else if (control && !value) {
						control.disable();
					}
				})
			)
			.subscribe();

		this.vo$ = combineAll({
			charsets: this.toObservable<Set<ExportCharsetEntity>>('charsets'),
		});
	}

	protected writeIntoForm(obj: ExportCsvConfigurationEntity): void {
		this.form.patchValue({
			[ExportCsvFormControls.Encoding]: obj.encoding,
			[ExportCsvFormControls.Quote]: obj.quote,
			[ExportCsvFormControls.EscapeCharacter]: obj.escapeCharacter,
			[ExportCsvFormControls.Separator]: obj.separator,
			[ExportCsvFormControls.QuoteAll]: obj.shouldApplyOnAllFields,
			[ExportCsvFormControls.KeepLeftWhiteSpace]: obj.keepLeadingWhiteSpace,
			[ExportCsvFormControls.KeepRightWhiteSpace]: obj.keepTrailingWhiteSpace,
			[ExportCsvFormControls.KeepHeaders]: obj.withHeaders,
			[ExportCsvFormControls.ShouldApplyInvalidFieldPlaceholderValue]:
				obj.shouldApplyEmptyNullFieldPlaceholderValue,
			[ExportCsvFormControls.InvalidFieldValuePlaceholderValue]:
				obj.emptyNullFieldPlaceholderValue,
		});
		ExportCsvConfigurationComponent.saveCurrent(this.form.value);
	}

	public extract(): ExportCsvConfigurationEntity {
		const formValues = this.form.value;
		return ExportCsvConfigurationComponent.toEntity(formValues);
	}
}
