import { AvatarEntity } from '@datachain/ui-sdk/components';
import { immerable } from 'immer';

import { PublicationStatus } from '../../../domain/publication-status';

interface IExpositionHistoryEntity {
	id: number;
	updateDescription: string;
	status: PublicationStatus;
	date: string;
	duration: number;
	linesCount: number;
	access: number;
	avatar: AvatarEntity;
}

const entityDefaults: IExpositionHistoryEntity = {
	id: -1,
	updateDescription: '',
	status: PublicationStatus.Unknown,
	date: '',
	duration: 0,
	linesCount: 0,
	access: 0,
	avatar: AvatarEntity.build('user'),
};

export class ExpositionHistoryEntity implements IExpositionHistoryEntity {
	private [immerable] = true;
	public id = entityDefaults.id;
	public status = entityDefaults.status;
	public date = entityDefaults.date;
	public duration = entityDefaults.duration;
	public linesCount = entityDefaults.linesCount;
	public access = entityDefaults.access;
	public updateDescription = entityDefaults.updateDescription;
	public avatar = entityDefaults.avatar;

	public static build(
		params: Partial<IExpositionHistoryEntity> = {}
	): ExpositionHistoryEntity {
		const instance = new ExpositionHistoryEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.status = params.status ?? entityDefaults.status;
		instance.date = params.date ?? entityDefaults.date;
		instance.duration = params.duration ?? entityDefaults.duration;
		instance.linesCount = params.linesCount ?? entityDefaults.linesCount;
		instance.access = params.access ?? entityDefaults.access;
		instance.avatar = params.avatar ?? entityDefaults.avatar;
		instance.updateDescription =
			params.updateDescription ?? entityDefaults.updateDescription;
		return instance;
	}
}
