import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';

@Injectable()
export class DomUtilsService {
	public constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly renderer: Renderer2
	) {}

	public setProperty(
		el: Element | HTMLElement | null,
		prop: string,
		value: string
	): void {
		if (el === null) {
			return;
		}
		this.renderer.setProperty(el, 'style', `${prop}:${value}`);
	}

	public getProperty(el: Element | HTMLElement | null, prop: string): string {
		if (el === null) {
			return '';
		}
		return getComputedStyle(el).getPropertyValue(prop);
	}

	public getFullHeight(el: Element | HTMLElement | null): string {
		if (el === null) {
			return '';
		}
		return `calc(${this.getProperty(el, 'height')} + ${this.getProperty(
			el,
			'margin-top'
		)} + ${this.getProperty(el, 'margin-bottom')})`;
	}

	public computeProp(...props: Array<string>): string {
		if (props.length === 1) {
			return props[0];
		}
		return props.reduce<string>((acc, curr, idx, arr) => {
			let acc_;
			if (acc === '') {
				acc_ = `calc(${curr}`;
			} else if (curr === '') {
				acc_ = acc;
			} else {
				acc_ = `${acc} + ${curr}`;
			}
			if (idx === arr.length - 1) {
				acc_ = `${acc_});`;
			}
			return acc_;
		}, '');
	}
}
