import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { combineAll, DcBaseComponent } from '@datachain/ui-sdk/common';
import {
	AppTagConfiguratorComponent,
	AppTagItemEntity,
} from '@datachain/ui-sdk/components';
import { OrderedSet } from 'immutable';
import {
	debounceTime,
	merge,
	Observable,
	of,
	switchMap,
	takeUntil,
	tap,
} from 'rxjs';

import { EndpointRequester } from '../../../requesters/async-validators/endpoint.requester';
import { ValidationErrorKeys } from '../../../ui/form/validation-erros-keys';
import { ExpositionEndpointMetadataEntity } from './exposition-endpoint-metadata.entity';
import {
	EndpointMetadataFormControls,
	ExpositionEndpointMetadataForm,
} from './exposition-endpoint-metadata.form';

@Component({
	selector: 'app-exposition-endpoint-metadata-config',
	templateUrl: './exposition-endpoint-metadata-config.component.html',
	styleUrls: ['./exposition-endpoint-metadata-config.component.scss'],
	inputs: ['expositionMetadata', 'isInViewMode', 'expositionId'],
	providers: [ExpositionEndpointMetadataForm, EndpointRequester],
})
export class ExpositionEndpointMetadataConfigComponent extends DcBaseComponent {
	public Appearance: MatFormFieldAppearance = 'legacy';
	public EndpointMetadataFormControls = EndpointMetadataFormControls;

	@ViewChild(AppTagConfiguratorComponent)
	public tagConfiguratorComponentCmp: AppTagConfiguratorComponent | null = null;

	@Output()
	public hasEndPointMetadataChanged = new EventEmitter<boolean>();

	public vo$: Observable<{
		expositionMetadata: ExpositionEndpointMetadataEntity;
		isInViewMode: boolean;
	}>;

	protected readonly ValidationErrorKeys = ValidationErrorKeys;

	public constructor(
		public readonly endpointMetadataForm: ExpositionEndpointMetadataForm,
		private readonly endpointRequester: EndpointRequester
	) {
		super();
		this.cmpId = 'exposition-endpoint-metadata-config';

		const expositionId$ = merge(
			of(null),
			this.toObservable<number>('expositionId')
		);

		const inViewMode$ = merge(
			of(false),
			this.toObservable<boolean>('isInViewMode')
		);

		this.toObservable<ExpositionEndpointMetadataEntity>('expositionMetadata')
			.pipe(
				takeUntil(this.onDestroy$),
				tap((endpointMetadata) => {
					this.endpointMetadataForm.populate(endpointMetadata);
				})
			)
			.subscribe();

		this.endpointMetadataForm.form.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				debounceTime(1000),
				tap(() => {
					this.hasEndPointMetadataChanged.emit();
				})
			)
			.subscribe();

		const accessPointCtrl = this.endpointMetadataForm.form.get(
			EndpointMetadataFormControls.AccessPoint
		);
		if (accessPointCtrl) {
			combineAll({
				ctrlVal: accessPointCtrl.valueChanges,
				expositionId: expositionId$,
				inViewMode: inViewMode$,
			})
				.pipe(
					takeUntil(this.onDestroy$),
					switchMap(({ ctrlVal, expositionId, inViewMode }) => {
						if (accessPointCtrl.valid && !inViewMode) {
							return this.endpointRequester.validateEndpointName(
								ctrlVal,
								expositionId
							);
						}
						return of(null);
					}),
					tap((res) => {
						if (res === null) {
							return;
						}
						if (!res) {
							return accessPointCtrl.setErrors({
								[ValidationErrorKeys.UnavailableToUse]: true,
							});
						} else {
							return accessPointCtrl.setErrors(null);
						}
					})
				)
				.subscribe();
		}

		// this.endpointMetadataForm.form.statusChanges
		// 	.pipe(
		// 		takeUntil(this.onDestroy$),
		// 		tap((st) => console.log(st))
		// 	)
		// 	.subscribe();

		this.vo$ = combineAll({
			expositionMetadata: merge(
				of(ExpositionEndpointMetadataEntity.build({})),
				this.toObservable<ExpositionEndpointMetadataEntity>(
					'expositionMetadata'
				)
			),
			isInViewMode: inViewMode$,
		});
	}

	public hasKeyWordsChanged(isChanged: boolean): void {
		this.hasEndPointMetadataChanged.emit(isChanged);
	}

	public async extractFormData(): Promise<ExpositionEndpointMetadataEntity> {
		let keywords = new Array<AppTagItemEntity>();
		if (this.tagConfiguratorComponentCmp) {
			keywords = await this.tagConfiguratorComponentCmp.getUsedTags();
		}
		const endpointMetadata = this.endpointMetadataForm.extract();
		return ExpositionEndpointMetadataEntity.build({
			accessPoint: endpointMetadata?.accessPoint,
			title: endpointMetadata?.title,
			details: endpointMetadata?.details,
			keywords: OrderedSet(keywords),
		});
	}
}
