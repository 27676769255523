import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DcBaseComponent, combineAll } from '@datachain/ui-sdk/common';
import { ModalData } from '@datachain/ui-sdk/components';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

import { DcIcons } from '../../../../ui/app-dc.icons';
import {
	ExpositionsSelector,
	saveExpositionEndpointMetadata,
} from '../../../store';
import { ExpositionEndpointMetadataConfigComponent } from '../../exposition-endpoint-metadata-config/exposition-endpoint-metadata-config.component';
import { ExpositionEndpointMetadataEntity } from '../../exposition-endpoint-metadata-config/exposition-endpoint-metadata.entity';

@Component({
	selector: 'app-exposition-endpoint-metadata-popup',
	templateUrl: './exposition-endpoint-metadata-popup.component.html',
	styleUrls: ['./exposition-endpoint-metadata-popup.component.scss'],
})
export class ExpositionEndpointMetadataPopupComponent<
	T extends { expositionId: number }
> extends DcBaseComponent {
	public readonly Icons = DcIcons;
	public isValid$ = new BehaviorSubject<boolean>(true);
	public vo$: Observable<{
		expositionId: number;
		metadata: ExpositionEndpointMetadataEntity;
		isValid: boolean;
		canEdit: boolean;
	}>;

	@ViewChild(ExpositionEndpointMetadataConfigComponent)
	public endpointMetadataCmp: ExpositionEndpointMetadataConfigComponent | null =
		null;
	public constructor(
		private readonly store: Store,
		private readonly expositionsSelector: ExpositionsSelector,
		@Inject(MAT_DIALOG_DATA) public readonly modalData: ModalData & T
	) {
		super();
		this.cmpId = 'edit-exposition-endpoint-metadata';

		this.vo$ = combineAll({
			expositionId: this.modalData.expositionId,
			metadata: this.expositionsSelector.getEndpointMetadata$(),
			isValid: this.isValid$,
			canEdit: this.expositionsSelector.getCanEditExposition$(),
		});
	}

	public async checkValidity(): Promise<void> {
		const initialVal = await firstValueFrom(
			this.expositionsSelector.getEndpointMetadata$()
		);
		if (this.endpointMetadataCmp) {
			if (!this.endpointMetadataCmp.endpointMetadataForm.form.valid) {
				this.isValid$.next(false);
				return;
			}
			const current = await this.endpointMetadataCmp.extractFormData();
			const hasChanged = !initialVal.isEqualTo(current);
			this.isValid$.next(hasChanged);
		}
	}

	public async save(): Promise<void> {
		if (this.endpointMetadataCmp) {
			const metadata = await this.endpointMetadataCmp.extractFormData();
			this.store.dispatch(
				saveExpositionEndpointMetadata({
					expositionId: this.modalData.expositionId,
					metadata,
				})
			);
		}
	}
}
