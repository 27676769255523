import { immerable, produce } from 'immer';

interface ISimulationUrisEntity {
	rest: {
		countUrl: string;
		dataUrl: string;
	};
	odata: {
		xmlUrl: string;
		jsonUrl: string;
	};
}

const entityDefaults: ISimulationUrisEntity = {
	rest: {
		countUrl: '',
		dataUrl: '',
	},
	odata: {
		jsonUrl: '',
		xmlUrl: '',
	},
};

export class SimulationUrisEntity implements ISimulationUrisEntity {
	private [immerable] = true;
	public rest = entityDefaults.rest;
	public odata = entityDefaults.odata;

	public static build(
		opts: Partial<ISimulationUrisEntity>
	): SimulationUrisEntity {
		const inst = new SimulationUrisEntity();
		inst.rest = opts.rest ?? entityDefaults.rest;
		inst.odata = opts.odata ?? entityDefaults.odata;
		return inst;
	}

	private constructor() {}
}
