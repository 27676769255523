import { Injectable } from '@angular/core';
import {
	CachePolicy,
	CachePolicyEntity,
	UrlService,
} from '@datachain/ui-sdk/common';
import { Observable } from 'rxjs';

import { DcBaseRequester } from '../core/dc-base.requester';
import { IFormulaUsage } from './formula-usages.models';

@Injectable({
	providedIn: 'root',
})
export class FormulaRequester extends DcBaseRequester<IFormulaUsage> {
	protected resource;
	protected elementsListUrl;
	protected singleElementUrl;

	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('FORMULAS_USAGES');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = this.resource;
	}

	public getFormulasUsages(): Observable<ReadonlyArray<IFormulaUsage>> {
		const url = this.urlService.generateUrl(
			this.elementsListUrl,
			{},
			{
				project: this.projectId,
			}
		);
		return this.get<ReadonlyArray<IFormulaUsage>>(
			url,
			CachePolicyEntity.build({
				policy: CachePolicy.NetworkAndCache,
				evictTimeout: 5 * 60,
				isCacheable: true,
			})
		);
	}
}
