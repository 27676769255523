import { Component } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	Validators,
} from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DcValueAccessorComponent, combineAll } from '@datachain/ui-sdk/common';
import { Set } from 'immutable';
import { Observable, takeUntil, tap } from 'rxjs';

import { ValidationErrorKeys } from '../../../ui/form/validation-erros-keys';
import { ExportCharsetEntity } from '../export-template-details/export-charset.entity';
import { ExportTxtMetaConfigurationEntity } from './export-txt-meta-configuration.entity';

enum ExportTxtMetaFormControls {
	Encoding = 'encoding',
	Separator = 'separator',
	EscapeCharacter = 'escapeCharacter',
	Quote = 'field-quotes',
	QuoteAll = 'quote-all-fields',
	ShouldApplyInvalidFieldPlaceholderValue = 'ShouldApplyInvalidFieldPlaceholderValue',
	InvalidFieldValuePlaceholderValue = 'EmptyNullFieldValuePlaceholderValue',
	KeepLeftWhiteSpace = 'keepLeftWhiteSpace',
	KeepRightWhiteSpace = 'keepRightWhiteSpace',
	KeepHeaders = 'keepHeaders',
	Metadata = 'Metadata',
}

@Component({
	selector: 'app-export-txt-meta-configuration',
	templateUrl: 'export-txt-meta-configuration.component.html',
	styleUrls: ['export-txt-meta-configuration.component.scss'],
	inputs: ['charsets'],
	providers: [
		DcValueAccessorComponent.generateAccessorToken(
			ExportTxtMetaConfigurationComponent
		),
		DcValueAccessorComponent.generateValidatorToken(
			ExportTxtMetaConfigurationComponent
		),
	],
})
export class ExportTxtMetaConfigurationComponent extends DcValueAccessorComponent<
	ExportTxtMetaConfigurationComponent,
	ExportTxtMetaConfigurationEntity
> {
	public Appearance: MatFormFieldAppearance = 'outline';
	public ExportTxtMetaFormControls = ExportTxtMetaFormControls;
	public ValidationErrorKeys = ValidationErrorKeys;
	public vo$: Observable<{
		charsets: Set<ExportCharsetEntity>;
	}>;
	public static currentCmpEntity: ExportTxtMetaConfigurationEntity | undefined =
		undefined;

	private static toEntity(
		formValues: Record<string, never>
	): ExportTxtMetaConfigurationEntity {
		return ExportTxtMetaConfigurationEntity.build({
			encoding: formValues[ExportTxtMetaFormControls.Encoding],
			separator: formValues[ExportTxtMetaFormControls.Separator],
			escapeCharacter: formValues[ExportTxtMetaFormControls.EscapeCharacter],
			quote: formValues[ExportTxtMetaFormControls.Quote],
			shouldApplyOnAllFields: formValues[ExportTxtMetaFormControls.QuoteAll],
			keepTrailingWhiteSpace:
				formValues[ExportTxtMetaFormControls.KeepRightWhiteSpace],
			keepLeadingWhiteSpace:
				formValues[ExportTxtMetaFormControls.KeepLeftWhiteSpace],
			withHeaders: formValues[ExportTxtMetaFormControls.KeepHeaders],
			emptyNullFieldPlaceholderValue:
				formValues[ExportTxtMetaFormControls.InvalidFieldValuePlaceholderValue],
			shouldApplyEmptyNullFieldPlaceholderValue:
				formValues[
					ExportTxtMetaFormControls.ShouldApplyInvalidFieldPlaceholderValue
				],
			metadata: formValues[ExportTxtMetaFormControls.Metadata],
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static saveCurrent(value: any): void {
		this.currentCmpEntity = ExportTxtMetaConfigurationComponent.toEntity(value);
	}

	public constructor(private readonly fb: FormBuilder) {
		super();
		this.cmpId = 'export-txt-meta-config';

		this.form = this.fb.group({
			[ExportTxtMetaFormControls.Encoding]: ['UTF-8', Validators.required],
			[ExportTxtMetaFormControls.Separator]: this.fb.control<string>(';', [
				Validators.required,
				Validators.maxLength(1),
			]),
			[ExportTxtMetaFormControls.EscapeCharacter]: ['\\', Validators.required],
			[ExportTxtMetaFormControls.QuoteAll]: [false],
			[ExportTxtMetaFormControls.KeepLeftWhiteSpace]: [false],
			[ExportTxtMetaFormControls.KeepRightWhiteSpace]: [false],
			[ExportTxtMetaFormControls.KeepHeaders]: [false],
			[ExportTxtMetaFormControls.ShouldApplyInvalidFieldPlaceholderValue]:
				false,
			[ExportTxtMetaFormControls.Metadata]: new FormControl({
				value: '',
				disabled: false,
			}),
			[ExportTxtMetaFormControls.Quote]: this.fb.control<string>(
				{
					value: '"',
					disabled: true,
				},
				[Validators.required, Validators.maxLength(1)]
			),
			[ExportTxtMetaFormControls.InvalidFieldValuePlaceholderValue]:
				new FormControl({
					value: '',
					disabled: true,
				}),
		});

		this.form.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				tap((val) => {
					this.onChange(val);
					ExportTxtMetaConfigurationComponent.saveCurrent(val);
					Object.keys(this.form.controls).forEach((k) => {
						const ctrl = this.form.get(k) as AbstractControl;
						ctrl.markAsTouched();
					});
				})
			)
			.subscribe();

		this.form
			.get(ExportTxtMetaFormControls.QuoteAll)
			?.valueChanges.pipe(
				takeUntil(this.onDestroy$),
				tap((value) => {
					const control = this.form.get(ExportTxtMetaFormControls.Quote);
					if (control && value) {
						control.enable();
					} else if (control && !value) {
						control.disable();
					}
				})
			)
			.subscribe();

		this.form
			.get(ExportTxtMetaFormControls.ShouldApplyInvalidFieldPlaceholderValue)
			?.valueChanges.pipe(
				takeUntil(this.onDestroy$),
				tap((value) => {
					const control = this.form.get(
						ExportTxtMetaFormControls.InvalidFieldValuePlaceholderValue
					);
					if (control && value) {
						control.enable();
					} else if (control && !value) {
						control.disable();
					}
				})
			)
			.subscribe();

		this.vo$ = combineAll({
			charsets: this.toObservable<Set<ExportCharsetEntity>>('charsets'),
		});
	}

	protected writeIntoForm(obj: ExportTxtMetaConfigurationEntity): void {
		this.form.patchValue({
			[ExportTxtMetaFormControls.Encoding]: obj.encoding,
			[ExportTxtMetaFormControls.Quote]: obj.quote,
			[ExportTxtMetaFormControls.EscapeCharacter]: obj.escapeCharacter,
			[ExportTxtMetaFormControls.Separator]: obj.separator,
			[ExportTxtMetaFormControls.QuoteAll]: obj.shouldApplyOnAllFields,
			[ExportTxtMetaFormControls.KeepLeftWhiteSpace]: obj.keepLeadingWhiteSpace,
			[ExportTxtMetaFormControls.KeepRightWhiteSpace]:
				obj.keepTrailingWhiteSpace,
			[ExportTxtMetaFormControls.KeepHeaders]: obj.withHeaders,
			[ExportTxtMetaFormControls.ShouldApplyInvalidFieldPlaceholderValue]:
				obj.shouldApplyEmptyNullFieldPlaceholderValue,
			[ExportTxtMetaFormControls.InvalidFieldValuePlaceholderValue]:
				obj.emptyNullFieldPlaceholderValue,
			[ExportTxtMetaFormControls.Metadata]: obj.metadata,
		});
		ExportTxtMetaConfigurationComponent.saveCurrent(this.form.value);
	}

	public extract(): ExportTxtMetaConfigurationEntity {
		const formValues = this.form.value;
		return ExportTxtMetaConfigurationComponent.toEntity(formValues);
	}
}
