import {
	IListAttrs,
	IListColumnConfig,
	IListOperations,
	toImmutableMap,
} from '@datachain/ui-sdk/common';
import { immerable, produce } from 'immer';
import { Map, Set } from 'immutable';

import { SimulationColumnsItemEntity } from './simulation-columns-item.entity';

type ISimulationColumnsListEntity = IListAttrs<SimulationColumnsItemEntity>;

const entityDefaults: ISimulationColumnsListEntity = {
	elements: Map(),
	availableColumns: Map(),
};

export class SimulationColumnsListEntity
	implements
		ISimulationColumnsListEntity,
		IListOperations<SimulationColumnsListEntity, SimulationColumnsItemEntity>
{
	private [immerable] = true;
	public elements = entityDefaults.elements;
	public availableColumns = entityDefaults.availableColumns;

	public static build(
		availableCols: Array<IListColumnConfig<SimulationColumnsItemEntity>>
	): SimulationColumnsListEntity {
		const inst = new SimulationColumnsListEntity();
		inst.availableColumns = toImmutableMap(availableCols, 'field');
		return inst;
	}

	private constructor() {}

	public getAvailableColumns(): Map<
		string,
		IListColumnConfig<SimulationColumnsItemEntity>
	> {
		return this.availableColumns;
	}

	public getElements(): Map<string, SimulationColumnsItemEntity> {
		return this.elements;
	}

	public extractColAliasAndType(
		cols: Array<string>
	): Map<string, SimulationColumnsItemEntity> {
		const rows = this.getElements();
		const colsToChecks = Set(cols);
		return rows
			.toList()
			.reduce<Map<string, SimulationColumnsItemEntity>>((acc, curr) => {
				if (colsToChecks.has(curr.exposedLabel)) {
					acc = acc.set(curr.exposedLabel, curr);
				}
				return acc;
			}, Map());
	}

	public setElements(
		elems: Map<string, SimulationColumnsItemEntity>
	): SimulationColumnsListEntity {
		return produce(this, (draft: SimulationColumnsListEntity) => {
			draft.elements = elems;
		});
	}
}
