<ng-container *ngIf="vo$ | async; let vo">
	<dx-data-grid
		keyExpr="id"
		[elementAttr]="{
			id: 'expositions-access-list',
			class: 'app-exposition-access-list'
		}"
		[dataSource]="vo.list.elements | toArray"
		[hoverStateEnabled]="true"
		[showColumnHeaders]="true"
		[allowColumnResizing]="true"
		[columnAutoWidth]="true"
		[showRowLines]="false"
		[showColumnLines]="false"
		[rowAlternationEnabled]="false"
		[showBorders]="false"
		[width]="'100%'">
		<dxo-selection mode="none"></dxo-selection>
		<dxo-load-panel
			shadingColor="rgba(0,0,0,0.4)"
			indicatorSrc="/src/img/loader-duotone.svg"
			[height]="100"
			[width]="100"
			[enabled]="true"
			[showIndicator]="true"
			[showPane]="true"
			[shading]="true"></dxo-load-panel>

		<!-- Fixable in position columns	-->
		<dxo-column-fixing [enabled]="true"></dxo-column-fixing>

		<dxo-scrolling mode="virtual"></dxo-scrolling>

		<ng-container *ngFor="let col of vo.list.availableColumns | toArray">
			<dxi-column
				*ngIf="col.field === 'label'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[visibleIndex]="col.visibleIndex"
				[width]="'35%'"
				cellTemplate="labelTemplate"></dxi-column>
			<dxi-column
				*ngIf="col.field === 'accessType'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[visibleIndex]="col.visibleIndex"
				[width]="'15%'"
				cellTemplate="accessTypeTemplate"></dxi-column>
			<dxi-column
				*ngIf="col.field === 'users'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[visibleIndex]="col.visibleIndex"
				[width]="'300'"
				cellTemplate="consumersTemplate"></dxi-column>
			<dxi-column
				*ngIf="col.field === 'columns'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[visibleIndex]="col.visibleIndex"
				[width]="'100'"
				cellTemplate="columnsTemplate"></dxi-column>
			<dxi-column
				*ngIf="col.field === 'lines'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[alignment]="'left'"
				[visibleIndex]="col.visibleIndex"
				[width]="'100'"
				cellTemplate="linesTemplate"></dxi-column>
			<dxi-column
				*ngIf="
					col.field !== 'label' &&
					col.field !== 'accessType' &&
					col.field !== 'columns' &&
					col.field !== 'users' &&
					col.field !== 'lines'
				"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[format]="col.format"
				[visibleIndex]="col.visibleIndex"
				[width]="100"
				[alignment]="'left'"></dxi-column>
		</ng-container>

		<dxi-column
			[fixed]="false"
			[allowResizing]="true"
			[allowHiding]="true"
			[allowGrouping]="true"
			[allowSearch]="false"
			[allowSorting]="true"
			[alignment]="'left'"
			[visibleIndex]="5"
			cellTemplate="actionsTemplate"></dxi-column>

		<div *dxTemplate="let cell of 'labelTemplate'" class="label-template">
			<span>{{ cell.data['label'] }}</span>
			<span
				*ngIf="cell.data['isActive']"
				class="label-template-icon"
				matTooltip="active"
				i18n-matTooltip="@@exposition.metadata.form.active"
				matTooltipPosition="after">
				<mat-icon [svgIcon]="DcIcons.Flash"></mat-icon>
			</span>
			<span
				*ngIf="!cell.data['isActive']"
				class="label-template-icon"
				matTooltip="inactive"
				i18n-matTooltip="@@exposition.metadata.form.inactive"
				matTooltipPosition="after">
				<mat-icon [svgIcon]="DcIcons.FlashOff"></mat-icon>
			</span>
		</div>

		<div *dxTemplate="let cell of 'accessTypeTemplate'">
			<div
				*ngIf="cell.data['accessType'] === AccessType.Open"
				class="access-type-template">
				<mat-icon [svgIcon]="DcIcons.LockOpen"></mat-icon>
				<span i18n="@@expositions.access.config.open">open</span>
			</div>
			<div
				*ngIf="cell.data['accessType'] === AccessType.Limited"
				class="access-type-template">
				<mat-icon [svgIcon]="DcIcons.LockClose"></mat-icon>
				<span i18n="@@expositions.access.config.limited">limited</span>
			</div>
		</div>

		<div *dxTemplate="let cell of 'columnsTemplate'">
			<app-overlay-badge
				id="{{ cmpId }}-{{ cell.rowIndex }}-columns-number-btn"
				[icon]="ComponentIcons.Column"
				[value]="cell.data['columnsCount']"
				[isOpen]="vo.openedOverlays.columns === cell.data['id']"
				(overlayDismiss)="closeOverlay()"
				(click)="openColumnsOverlay(cell.data)">
				<app-popover
					overlayContent
					title="columns"
					[icon]="{ iconName: ComponentIcons.Column }"
					i18n-title="@@exposition.access.list.column.columns"
					[count]="cell.data['columns'].length"
					(adbPopoverClose)="closeOverlay()">
					<mat-spinner
						*ngIf="vo.isOverlayContentLoading"
						[diameter]="20"></mat-spinner>
					<ng-container *ngIf="!vo.isOverlayContentLoading">
						<div
							*ngFor="let column of cell.data['columns'] | toArray"
							class="overlay-content-body-row">
							<app-column-type
								[type]="column['type']"
								[isList]="column['isList']"></app-column-type>
							<span>{{ column.label }}</span>
						</div>
					</ng-container>
				</app-popover>
			</app-overlay-badge>
		</div>

		<div *dxTemplate="let cell of 'consumersTemplate'">
			<div
				class="app-exposition-access-consumers"
				*ngIf="cell.data['accessType'] === AccessType.Limited">
				<app-overlay-badge
					[icon]="ComponentIcons.User"
					[value]="cell.data['usersCount']"
					[isOpen]="vo.openedOverlays.users === cell.data['id']"
					(overlayDismiss)="closeOverlay()"
					(click)="openUsersOverlay(cell.data)">
					<app-popover
						overlayContent
						title="users"
						[icon]="{ iconName: ComponentIcons.User }"
						i18n-title="@@exposition.access.list.consumers.users"
						[count]="cell.data['users'].length"
						(adbPopoverClose)="closeOverlay()">
						<mat-spinner
							*ngIf="vo.isOverlayContentLoading"
							[diameter]="20"></mat-spinner>
						<ng-container *ngIf="!vo.isOverlayContentLoading">
							<div
								*ngFor="let user of cell.data['users'] | toArray"
								class="overlay-content-body-row">
								<app-avatar [avatar]="user.avatar"></app-avatar>
								<span>{{ user.label }}</span>
							</div>
						</ng-container>
					</app-popover>
				</app-overlay-badge>

				<app-overlay-badge
					[icon]="ComponentIcons.Group"
					[value]="cell.data['groupsCount']"
					[isOpen]="vo.openedOverlays.groups === cell.data['id']"
					(overlayDismiss)="closeOverlay()"
					(click)="openGroupsOverlay(cell.data)">
					<app-popover
						overlayContent
						title="groups"
						[icon]="{ iconName: ComponentIcons.Group }"
						i18n-title="@@exposition.access.list.consumers.groups"
						[count]="cell.data['groups'].length"
						(adbPopoverClose)="closeOverlay()">
						<mat-spinner
							*ngIf="vo.isOverlayContentLoading"
							[diameter]="20"></mat-spinner>
						<ng-container *ngIf="!vo.isOverlayContentLoading">
							<div
								*ngFor="let group of cell.data['groups'] | toArray"
								class="overlay-content-body-row">
								<app-avatar [avatar]="group.avatar"></app-avatar>
								<span>{{ group.label }}</span>
							</div>
						</ng-container>
					</app-popover>
				</app-overlay-badge>
			</div>
		</div>

		<div *dxTemplate="let cell of 'linesTemplate'">
			<app-overlay-badge
				[icon]="
					cell.data['lines'].length
						? ComponentIcons.Filter
						: ComponentIcons.FilterOff
				"
				[isOpen]="vo.openedOverlays.lines === cell.data['id']"
				[active]="cell.data['lines'].length"
				(overlayDismiss)="closeOverlay()"
				(click)="openLinesOverlay(cell.data)">
				<app-popover
					overlayContent
					title="lines"
					i18n-title="@@exposition.access.list.column.lines"
					(adbPopoverClose)="closeOverlay()">
					<mat-spinner
						*ngIf="vo.isOverlayContentLoading"
						[diameter]="20"></mat-spinner>
					<span *ngIf="!vo.isOverlayContentLoading">
						{{ cell.data['lines'] }}
					</span>
				</app-popover>
			</app-overlay-badge>
		</div>

		<div *dxTemplate="let cell of 'actionsTemplate'" class="actions-template">
			<button
				id="{{ cmpId }}-{{ cell.rowIndex }}-actions-menu-btn"
				mat-icon-button
				class="actions-template__menu-btn"
				[matMenuTriggerFor]="actions">
				<mat-icon [svgIcon]="DcIcons.MenuMoreOptionsHandle"></mat-icon>
			</button>

			<mat-menu #actions="matMenu">
				<ng-template matMenuContent>
					<button
						id="{{ cmpId }}-{{ cell.rowIndex }}-edit-btn"
						(click)="goToAccessConfig(cell.data['id'])"
						mat-menu-item>
						<span i18n="@@general.action.edit">edit</span>
					</button>
					<button
						*ngIf="vo.canEdit"
						id="{{ cmpId }}-{{ cell.rowIndex }}-toggle-status-btn"
						(click)="toggleStatus(cell.data['id'])"
						mat-menu-item>
						<span
							*ngIf="cell.data['isActive']"
							i18n="@@general.action.deactivate">
							deactivate
						</span>
						<span
							*ngIf="!cell.data['isActive']"
							i18n="@@general.action.activate">
							activate
						</span>
					</button>
					<button
						*ngIf="vo.canEdit"
						[disabled]="vo.list.elements.size < 2"
						id="{{ cmpId }}-{{ cell.rowIndex }}-delete-btn"
						(click)="deleteExpositionAccess(cell.data)"
						mat-menu-item>
						<span i18n="@@general.action.delete">delete</span>
					</button>
				</ng-template>
			</mat-menu>
		</div>

		<dxo-group-panel [visible]="false"></dxo-group-panel>

		<!-- Search input -->
		<dxo-search-panel [visible]="false"></dxo-search-panel>

		<!-- Sorting	-->
		<dxo-sorting mode="multiple"></dxo-sorting>

		<!-- State persistence	-->
		<dxo-state-storing
			type="localStorage"
			[enabled]="false"
			[storageKey]="dxLocalStorageKey"></dxo-state-storing>

		<dxo-paging [pageSize]="1000"></dxo-paging>
		<dxo-pager #pager [visible]="false"></dxo-pager>
	</dx-data-grid>
</ng-container>
