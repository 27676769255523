import { IisEqual } from '@datachain/ui-sdk/common';
import { immerable, produce } from 'immer';

import { ExportCsvConfigurationEntity } from '../export-csv-configuration/export-csv-configuration.entity';
import { ExportDbConfigurationEntity } from '../export-db-configuration/export-db-configuration.entity';
import { ExportExcelConfigurationEntity } from '../export-excel-configuration/export-excel-configuration.entity';
import { ExportFileConfigurationEntity } from '../export-file-configuration/export-file-configuration.entity';
import { ExportPartitionConfigurationEntity } from '../export-partition-configuration/export-partition-configuration.entity';
import { ExportTxtMetaConfigurationEntity } from '../export-txt-meta-configuration/export-txt-meta-configuration.entity';
import { ExportWriteModeConfigurationEntity } from '../export-write-mode-configuration/export-write-mode-configuration.entity';
import { ExportXmlConfigurationEntity } from '../export-xml-configuration/export-xml-configuration.entity';
import { ExportConnectorEntity } from './export-connector.entity';

export class ExportNoConfigEntity {}
export class ExportJSONConfigEntity {}
export class ExportParquetConfigEntity {}

export type ExportTemplateConfig =
	| ExportCsvConfigurationEntity
	| ExportTxtMetaConfigurationEntity
	| ExportXmlConfigurationEntity
	| ExportExcelConfigurationEntity
	| ExportDbConfigurationEntity
	| ExportJSONConfigEntity
	| ExportParquetConfigEntity
	| ExportNoConfigEntity;

export enum ExportFormat {
	None = '',
	CSV = 'csv',
	XML = 'xml',
	JSON = 'json',
	Parquet = 'parquet',
	Excel = 'excel',
	TXT_META = 'txt_metadata',
}

interface IExportTemplateEntity {
	id: number; // FIXME: UUID string | number
	connector: ExportConnectorEntity;
	outputType: ExportFormat;
	keepOriginalLabels: boolean;
	compress: boolean;
	path: string;
	partition: ExportPartitionConfigurationEntity;
	config: ExportTemplateConfig;
	fileNameConfig: ExportFileConfigurationEntity;
	writeMode: ExportWriteModeConfigurationEntity;
}

const entityDefaults: IExportTemplateEntity = {
	id: -1,
	connector: ExportConnectorEntity.localConnector(),
	outputType: ExportFormat.None,
	keepOriginalLabels: true,
	compress: false,
	path: '/',
	config: new ExportNoConfigEntity(),
	partition: ExportPartitionConfigurationEntity.build(),
	fileNameConfig: ExportFileConfigurationEntity.build(),
	writeMode: ExportWriteModeConfigurationEntity.build(),
};

export class ExportTemplateDetailsEntity
	implements IExportTemplateEntity, IisEqual<ExportTemplateDetailsEntity>
{
	private [immerable] = true;
	public id = entityDefaults.id;
	public connector = entityDefaults.connector;
	public outputType = entityDefaults.outputType;
	public keepOriginalLabels = entityDefaults.keepOriginalLabels;
	public compress = entityDefaults.compress;
	public path = entityDefaults.path;
	public config = entityDefaults.config;
	public partition = entityDefaults.partition;
	public fileNameConfig = entityDefaults.fileNameConfig;
	public writeMode = entityDefaults.writeMode;

	public static build(
		params: Partial<IExportTemplateEntity> = {}
	): ExportTemplateDetailsEntity {
		const instance = new ExportTemplateDetailsEntity();
		instance.id = params.id ?? entityDefaults.id; // FIXME: generate new UUID no need to pass it through
		instance.connector = params.connector ?? entityDefaults.connector;
		instance.outputType = params.outputType ?? entityDefaults.outputType;
		instance.keepOriginalLabels =
			params.keepOriginalLabels ?? entityDefaults.keepOriginalLabels;
		instance.compress = params.compress ?? entityDefaults.compress;
		instance.path = params.path ?? entityDefaults.path;
		instance.config = params.config ?? entityDefaults.config;
		instance.partition = params.partition ?? entityDefaults.partition;
		instance.fileNameConfig =
			params.fileNameConfig ?? entityDefaults.fileNameConfig;
		instance.writeMode = params.writeMode ?? entityDefaults.writeMode;
		return instance;
	}

	public setConnector(
		connector: ExportConnectorEntity
	): ExportTemplateDetailsEntity {
		return produce(this, (draft) => {
			draft.connector = connector;
		});
	}

	public merge(config: ExportTemplateConfig): ExportTemplateDetailsEntity {
		return produce(this, (draft) => {
			draft.config = config;
		});
	}

	public isEqualTo(obj: ExportTemplateDetailsEntity): boolean {
		if (
			this.config instanceof ExportDbConfigurationEntity &&
			obj.config instanceof ExportDbConfigurationEntity
		) {
			return this.compareDbTypeExportTemplate(obj);
		}
		return this.compareFileTypeExportTemplate(obj);
	}

	public setPredictedFileName(predicted: string): ExportTemplateDetailsEntity {
		return produce(this, (draft) => {
			draft.fileNameConfig =
				draft.fileNameConfig.setPredictedUserDefinedLabel(predicted);
		});
	}

	private compareDbTypeExportTemplate(
		obj: ExportTemplateDetailsEntity
	): boolean {
		if (this.connector.compareTo(obj.connector) !== 0) {
			return false;
		} else if (this.keepOriginalLabels !== obj.keepOriginalLabels) {
			return false;
		} else if (this.writeMode.compareTo(obj.writeMode) !== 0) {
			return false;
		}
		if (this.config instanceof ExportDbConfigurationEntity) {
			return this.config.isEqualTo(obj.config as ExportDbConfigurationEntity);
		}
		return true;
	}

	private compareFileTypeExportTemplate(
		obj: ExportTemplateDetailsEntity
	): boolean {
		if (this.connector.compareTo(obj.connector) !== 0) {
			return false;
		} else if (this.outputType.localeCompare(obj.outputType) !== 0) {
			return false;
		} else if (this.keepOriginalLabels !== obj.keepOriginalLabels) {
			return false;
		} else if (this.compress !== obj.compress) {
			return false;
		} else if (this.path.localeCompare(obj.path) !== 0) {
			return false;
		} else if (this.partition.compareTo(obj.partition)) {
			return false;
		} else if (!this.fileNameConfig.isEqualTo(obj.fileNameConfig)) {
			return false;
		}

		if (this.config instanceof ExportCsvConfigurationEntity) {
			return this.config.isEqualTo(obj.config as ExportCsvConfigurationEntity);
		}
		if (this.config instanceof ExportXmlConfigurationEntity) {
			return this.config.isEqualTo(obj.config as ExportXmlConfigurationEntity);
		}
		if (this.config instanceof ExportExcelConfigurationEntity) {
			return this.config.isEqualTo(
				obj.config as ExportExcelConfigurationEntity
			);
		}
		if (this.config instanceof ExportTxtMetaConfigurationEntity) {
			return this.config.isEqualTo(
				obj.config as ExportTxtMetaConfigurationEntity
			);
		}
		return true;
	}
}
