import { AvatarEntity, ColumnType } from '@datachain/ui-sdk/components';
import { immerable, produce } from 'immer';

import { PublicationStatus } from '../../domain/publication-status';
import { ExpositionEndpointMetadataEntity } from '../exposition-endpoint-metadata-config/exposition-endpoint-metadata.entity';

export enum HistoryActionType {
	Publish = 'publish',
	ClearData = 'clear-data',
	UpdateData = 'update-data',
	UpdateConfig = 'update-config',
	Activate = 'activate',
	Deactivate = 'deactivate',
	Unknown = 'unknown',
}
interface IExpositionHistoryItemEntity {
	id: number;
	jobId: string | undefined;
	jobLog: string;
	action: HistoryActionType;
	actionDescription: string;
	status: PublicationStatus;
	date: string;
	duration: number;
	dataEntries: number;
	access: Array<string>;
	columns: Array<{ label: string; type: ColumnType; isList: boolean }>;
	avatar: AvatarEntity;
	endPointMetadata: ExpositionEndpointMetadataEntity;
}

const entityDefaults: IExpositionHistoryItemEntity = {
	id: -1,
	jobId: undefined,
	jobLog: '',
	action: HistoryActionType.Unknown,
	actionDescription: '',
	status: PublicationStatus.Unknown,
	date: '',
	duration: 0,
	dataEntries: 0,
	access: [],
	columns: [],
	avatar: AvatarEntity.build('user'),
	endPointMetadata: ExpositionEndpointMetadataEntity.build(),
};

export class ExpositionHistoryItemEntity
	implements IExpositionHistoryItemEntity
{
	private [immerable] = true;
	public id = entityDefaults.id;
	public jobId = entityDefaults.jobId;
	public jobLog = entityDefaults.jobLog;
	public status = entityDefaults.status;
	public date = entityDefaults.date;
	public duration = entityDefaults.duration;
	public dataEntries = entityDefaults.dataEntries;
	public access = entityDefaults.access;
	public columns = entityDefaults.columns;
	public action = entityDefaults.action;
	public actionDescription = entityDefaults.actionDescription;
	public avatar = entityDefaults.avatar;
	public endPointMetadata = entityDefaults.endPointMetadata;

	public static build(
		params: Partial<IExpositionHistoryItemEntity> = {}
	): ExpositionHistoryItemEntity {
		const instance = new ExpositionHistoryItemEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.jobId = params.jobId ?? entityDefaults.jobId;
		instance.jobLog = params.jobLog ?? entityDefaults.jobLog;
		instance.status = params.status ?? entityDefaults.status;
		instance.date = params.date ?? entityDefaults.date;
		instance.duration = params.duration ?? entityDefaults.duration;
		instance.dataEntries = params.dataEntries ?? entityDefaults.dataEntries;
		instance.access = params.access ?? entityDefaults.access;
		instance.columns = params.columns ?? entityDefaults.columns;
		instance.avatar = params.avatar ?? entityDefaults.avatar;
		instance.action = params.action ?? entityDefaults.action;
		instance.actionDescription =
			params.actionDescription ?? entityDefaults.actionDescription;
		instance.endPointMetadata =
			params.endPointMetadata ?? entityDefaults.endPointMetadata;
		return instance;
	}

	public updateJobLog(jobLog: string): ExpositionHistoryItemEntity {
		return produce(this, (draft: ExpositionHistoryItemEntity) => {
			draft.jobLog = jobLog;
		});
	}
}
