import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AccessConfigEntity } from '../../domain/exposition-access-config.entity';

export enum AccessMetadataFormControls {
	Id = 'id',
	Label = 'label',
	Description = 'description',
	IsActive = 'isActive',
}

@Injectable()
export class ExpositionAccessMetadataForm {
	public form: FormGroup;

	public constructor(protected readonly fb: FormBuilder) {
		this.form = this.fb.group({
			[AccessMetadataFormControls.Id]: [
				{
					value: -1,
					disabled: true,
				},
			],
			[AccessMetadataFormControls.Label]: ['', [Validators.required]],
			[AccessMetadataFormControls.Description]: [
				'',
				[Validators.maxLength(2500)],
			],
			[AccessMetadataFormControls.IsActive]: [true],
		});
	}

	public extract(): Partial<AccessConfigEntity> {
		const formValues = this.form.value;
		return AccessConfigEntity.build({
			id: formValues[AccessMetadataFormControls.Id] ?? -1,
			label: formValues[AccessMetadataFormControls.Label] ?? '',
			description: formValues[AccessMetadataFormControls.Description] ?? '',
			isActive: formValues[AccessMetadataFormControls.IsActive] ?? false,
		});
	}

	public populate(config: Partial<AccessConfigEntity> | null): void {
		if (config === null || config.id === undefined) {
			throw new Error('Invalid entity');
		}
		this.form.patchValue({
			[AccessMetadataFormControls.Id]: config.id,
			[AccessMetadataFormControls.Label]: config?.label ?? '',
			[AccessMetadataFormControls.Description]: config?.description ?? '',
			[AccessMetadataFormControls.IsActive]: config?.isActive ?? false,
		});
	}
}
