import { ColumnType } from '@datachain/ui-sdk/components';
import { immerable } from 'immer';

interface ISimulationColumnsItemEntity {
	id: number;
	uuid: string;
	exposedLabel: string;
	originalLabel: string;
	alias: string;
	description: string;
	type: ColumnType;
	isList: boolean;
	isPrimaryKey: boolean;
	isFilterable: boolean;
	sortOrder: 'asc' | 'desc' | '';
}

const entityDefaults: ISimulationColumnsItemEntity = {
	id: -1,
	uuid: '',
	exposedLabel: '',
	originalLabel: '',
	alias: '',
	description: '',
	type: ColumnType.Unknown,
	isList: false,
	isPrimaryKey: false,
	isFilterable: false,
	sortOrder: '',
};

export class SimulationColumnsItemEntity
	implements ISimulationColumnsItemEntity
{
	private [immerable] = true;
	public id = entityDefaults.id;
	public exposedLabel = entityDefaults.exposedLabel;
	public originalLabel = entityDefaults.originalLabel;
	public uuid = entityDefaults.uuid;
	public alias = entityDefaults.alias;
	public description = entityDefaults.description;
	public type = entityDefaults.type;
	public isList = entityDefaults.isList;
	public isPrimaryKey = entityDefaults.isPrimaryKey;
	public isFilterable = entityDefaults.isFilterable;
	public sortOrder = entityDefaults.sortOrder;

	public static build(
		params: Partial<ISimulationColumnsItemEntity> = {}
	): SimulationColumnsItemEntity {
		const inst = new SimulationColumnsItemEntity();
		inst.id = params.id ?? entityDefaults.id;
		inst.exposedLabel = params.exposedLabel ?? entityDefaults.exposedLabel;
		inst.originalLabel = params.originalLabel ?? entityDefaults.originalLabel;
		inst.uuid = params.uuid ?? entityDefaults.uuid;
		inst.alias = params.alias ?? entityDefaults.alias;
		inst.description = params.description ?? entityDefaults.description;
		inst.type = params.type ?? entityDefaults.type;
		inst.isList = params.isList ?? entityDefaults.isList;
		inst.isPrimaryKey = params.isPrimaryKey ?? entityDefaults.isPrimaryKey;
		inst.isFilterable = params.isFilterable ?? entityDefaults.isFilterable;
		inst.sortOrder = params.sortOrder ?? entityDefaults.sortOrder;
		return inst;
	}

	private constructor() {}
}
