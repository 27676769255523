import {
	BaseSimulationType,
	SimulationBaseEntity,
} from './simulation-base.entity';

interface ISimulationUserEntity {
	id: number;
	fullName: string;
	email: string;
}

const entityDefaults: ISimulationUserEntity = {
	id: -1,
	fullName: '',
	email: '',
};

export class SimulationUserEntity
	extends SimulationBaseEntity
	implements ISimulationUserEntity
{
	protected simType: BaseSimulationType = 'Users';
	public id = entityDefaults.id;
	public fullName = entityDefaults.fullName;
	public email = entityDefaults.email;

	public constructor(params: Partial<ISimulationUserEntity>) {
		super();
		this.id = params.id ?? entityDefaults.id;
		this.fullName = params.fullName ?? entityDefaults.fullName;
		this.email = params.email ?? entityDefaults.email;
	}
}
