import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DcValueAccessorComponent } from '@datachain/ui-sdk/common';
import { takeUntil, tap } from 'rxjs';

import { ValidationErrorKeys } from '../../../ui/form/validation-erros-keys';
import { ExportXmlConfigurationEntity } from './export-xml-configuration.entity';

enum ExportXmlFormControls {
	GroupTag = 'group',
	ChildTag = 'child',
}

@Component({
	selector: 'app-export-xml-configuration',
	templateUrl: './export-xml-configuration.component.html',
	styleUrls: ['./export-xml-configuration.component.scss'],
	providers: [
		DcValueAccessorComponent.generateAccessorToken(
			ExportXmlConfigurationComponent
		),
		DcValueAccessorComponent.generateValidatorToken(
			ExportXmlConfigurationComponent
		),
	],
})
export class ExportXmlConfigurationComponent extends DcValueAccessorComponent<
	ExportXmlConfigurationComponent,
	ExportXmlConfigurationEntity
> {
	public Appearance: MatFormFieldAppearance = 'outline';
	public ExportXmlFormControls = ExportXmlFormControls;
	public ValidationErrorKeys = ValidationErrorKeys;

	public static currentCmpEntity: ExportXmlConfigurationEntity | undefined =
		undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private static toEntity(formValues: any): ExportXmlConfigurationEntity {
		return ExportXmlConfigurationEntity.build({
			childTag: formValues[ExportXmlFormControls.ChildTag],
			groupTag: formValues[ExportXmlFormControls.GroupTag],
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static saveCurrent(value: any): void {
		this.currentCmpEntity = ExportXmlConfigurationComponent.toEntity(value);
	}

	public constructor(private readonly fb: FormBuilder) {
		super();
		this.cmpId = 'export-xml-config';

		this.form = this.fb.group({
			[ExportXmlFormControls.GroupTag]: ['', Validators.required],
			[ExportXmlFormControls.ChildTag]: ['', Validators.required],
		});

		this.form.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				tap((val) => {
					this.onChange(val);
					ExportXmlConfigurationComponent.saveCurrent(val);
					Object.keys(this.form.controls).forEach((k) => {
						const ctrl = this.form.get(k) as AbstractControl;
						ctrl.markAsTouched();
					});
				})
			)
			.subscribe();
	}

	public extract(): ExportXmlConfigurationEntity {
		const formValues = this.form.value;
		return ExportXmlConfigurationComponent.toEntity(formValues);
	}

	protected writeIntoForm(obj: ExportXmlConfigurationEntity): void {
		this.form.patchValue({
			[ExportXmlFormControls.GroupTag]: obj.groupTag,
			[ExportXmlFormControls.ChildTag]: obj.childTag,
		});
		ExportXmlConfigurationComponent.saveCurrent(this.form.value);
	}
}
