import { Injectable } from '@angular/core';
import { combineAll } from '@datachain/ui-sdk/common';
import { Observable } from 'rxjs';

import { PermissionRequester } from '../core/permission.requester';

@Injectable({
	providedIn: 'root',
})
export class ExpositionPermissionRequester extends PermissionRequester {
	public checkExpositionViewPermissions(
		elemId: number
	): Observable<{ delete: boolean; view: boolean; edit: boolean }> {
		return combineAll({
			delete: this.haveActionPermissionOnElement(
				elemId,
				'exposition',
				'delete'
			),
			view: this.haveActionPermissionOnElement(elemId, 'exposition', 'view'),
			edit: this.haveActionPermissionOnElement(elemId, 'exposition', 'edit'),
		});
	}
}
