import {
	IListAttrs,
	IListColumnConfig,
	IListOperations,
} from '@datachain/ui-sdk/common';
import { immerable, produce } from 'immer';
import { Map } from 'immutable';

import { AccessConfigEntity } from '../../../domain/exposition-access-config.entity';
import { AccessType, ExpositionAccessEntity } from './exposition-access.entity';

export interface IExpositionAccessListExtra
	extends IListColumnConfig<ExpositionAccessEntity> {
	isFixed?: boolean;
	isSelectable?: boolean;
	isSearchable?: boolean;
	isInGroup?: boolean;
	isVisible?: boolean;
	canBeHidden?: boolean;
	canBeReordered?: boolean;
	canBeChosen?: boolean;
	format?: string;
	visibleIndex?: number;
}

interface IExpositionAccessListEntity
	extends IListAttrs<ExpositionAccessEntity> {
	availableColumns: Map<string, IExpositionAccessListExtra>;
	currentConfig: AccessConfigEntity;
}

const entityDefaults: IExpositionAccessListEntity = {
	elements: Map(),
	availableColumns: Map(),
	currentConfig: AccessConfigEntity.build(),
};

export class ExpositionAccessListEntity
	implements
		IExpositionAccessListEntity,
		IListOperations<ExpositionAccessListEntity, ExpositionAccessEntity>
{
	private [immerable] = true;
	public elements = entityDefaults.elements;
	public availableColumns = entityDefaults.availableColumns;
	public currentConfig = entityDefaults.currentConfig;

	public static build(
		availableCols: Array<IExpositionAccessListExtra>
	): ExpositionAccessListEntity {
		const inst = new ExpositionAccessListEntity();
		const cols = availableCols.reduce((acc, curr) => {
			acc.push([curr.field, curr]);
			return acc;
		}, new Array<[string, IExpositionAccessListExtra]>());
		inst.availableColumns = Map(cols);
		return inst;
	}

	private constructor() {}

	public getAvailableColumns(): Map<
		string,
		IListColumnConfig<ExpositionAccessEntity>
	> {
		return this.availableColumns;
	}

	public setElements(
		elems: Map<string, ExpositionAccessEntity>
	): ExpositionAccessListEntity {
		return produce(this, (draft: ExpositionAccessListEntity) => {
			draft.elements = elems;
		});
	}

	public deleteAccess(id: string): ExpositionAccessListEntity {
		return produce(this, (draft: ExpositionAccessListEntity) => {
			draft.elements = draft.elements.delete(id);
		});
	}

	public setCurrentConfig(
		config: AccessConfigEntity
	): ExpositionAccessListEntity {
		return produce(this, (draft: ExpositionAccessListEntity) => {
			draft.currentConfig = config;
		});
	}

	public hasConfiguredOpenAccess(accessId: string): boolean {
		const currentAccess = this.elements.get(accessId);
		let checkOthers = false;
		if (currentAccess) {
			const isCurrentlyOpen = currentAccess.accessType === AccessType.Open;
			checkOthers = !isCurrentlyOpen;
		} else {
			checkOthers = true;
		}
		if (!checkOthers) {
			return false;
		} else {
			return this.elements.reduce<boolean>(
				(acc, curr) => curr.accessType === AccessType.Open || acc,
				false
			);
		}
	}
}
