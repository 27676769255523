import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DcForm } from '@datachain/ui-sdk/common';
import { ReplaySubject } from 'rxjs';

import { AppMetadataEntity } from './app-metadata.entity';

export enum MetadataFormControls {
	Title = 'title',
	Description = 'description',
	ActiveState = 'active-state',
}

@Injectable()
export class AppMetadataForm
	extends DcForm<AppMetadataEntity>
	implements OnDestroy
{
	private readonly onDestroy$ = new ReplaySubject<void>(1);

	private id: number;
	public title = new FormControl<string>('', [Validators.required]);
	public description = new FormControl<string>('', []);
	public activeState = new FormControl<boolean>(false, []);

	protected readonly _form = this.fb.group({});

	public constructor(protected readonly fb: FormBuilder) {
		super();
		this.id = -1;
		this.addControls();
		this.initListeners();
	}

	protected addControls(): void {
		this.form.addControl(MetadataFormControls.Title, this.title);
		this.form.addControl(MetadataFormControls.Description, this.description);
		this.form.addControl(MetadataFormControls.ActiveState, this.activeState);
	}

	protected initListeners(): void {}

	public extract(): AppMetadataEntity {
		const formValues = this.form.value;
		return AppMetadataEntity.build({
			id: this.id,
			title: formValues[MetadataFormControls.Title],
			description: formValues[MetadataFormControls.Description],
			isActive: formValues[MetadataFormControls.ActiveState],
		});
	}

	public populate(config: AppMetadataEntity | null): void {
		this.id = config?.id ?? -1;
		this.form.patchValue({
			[MetadataFormControls.Title]: config?.title ?? '',
			[MetadataFormControls.Description]: config?.description ?? '',
			[MetadataFormControls.ActiveState]: config?.isActive ?? false,
		});
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	protected updateForm(args: never): void {}
}
