import {
	IListAttrs,
	IListColumnConfig,
	IListOperations,
} from '@datachain/ui-sdk/common';
import { immerable, produce } from 'immer';
import { Map } from 'immutable';

import { PublicationAction } from '../../store';
import { ExpositionEntity } from '../exposition-view/exposition.entity';
import { ExpositionItemEntity } from './exposition-item.entity';

export interface IExpositionListExtra
	extends IListColumnConfig<ExpositionItemEntity> {
	isFixed?: boolean;
	isSelectable?: boolean;
	isSearchable?: boolean;
	isInGroup?: boolean;
	isVisible?: boolean;
	canBeHidden?: boolean;
	canBeReordered?: boolean;
	canBeChosen?: boolean;
	format?: string;
	visibleIndex?: number;
	visible?: boolean;
	hasHeaderFilter?: boolean;
}

interface IExpositionListEntity extends IListAttrs<ExpositionItemEntity> {
	availableColumns: Map<string, IExpositionListExtra>;
}

const entityDefaults: IExpositionListEntity = {
	elements: Map(),
	availableColumns: Map(),
};

export class ExpositionListEntity
	implements
		IExpositionListEntity,
		IListOperations<ExpositionListEntity, ExpositionItemEntity>
{
	private [immerable] = true;
	public elements = entityDefaults.elements;
	public availableColumns = entityDefaults.availableColumns;

	public static build(
		availableCols: Array<IExpositionListExtra>
	): ExpositionListEntity {
		const inst = new ExpositionListEntity();
		const cols = availableCols.reduce((acc, curr) => {
			acc.push([curr.field, curr]);
			return acc;
		}, new Array<[string, IExpositionListExtra]>());
		inst.availableColumns = Map(cols);
		return inst;
	}

	private constructor() {}

	public getAvailableColumns(): Map<
		string,
		IListColumnConfig<ExpositionItemEntity>
	> {
		return this.availableColumns;
	}

	public setElements(
		elems: Map<string, ExpositionItemEntity>
	): ExpositionListEntity {
		return produce(this, (draft: ExpositionListEntity) => {
			draft.elements = elems;
		});
	}

	public setRunningActionOn(
		ids: Array<number>,
		action: PublicationAction
	): ExpositionListEntity {
		return produce(this, (draft: ExpositionListEntity) => {
			draft.elements = ids.reduce<Map<string, ExpositionItemEntity>>(
				(acc, curr) => {
					const maybeFound = acc.get(`${curr}`);
					if (maybeFound) {
						const updateEl = maybeFound.setRunningAction(action);
						acc = acc.set(`${curr}`, updateEl);
						return acc;
					}
					return acc;
				},
				this.elements
			);
		});
	}

	public resetRunningActionOn(id: number): ExpositionListEntity {
		return produce(this, (draft: ExpositionListEntity) => {
			const maybeFound = draft.elements.get(`${id}`);
			if (!maybeFound) {
				return;
			}
			draft.elements = draft.elements.set(
				`${id}`,
				maybeFound.resetRunningAction()
			);
		});
	}

	public updateSelected(ids: Array<string>): ExpositionListEntity {
		return produce(this, (draft: ExpositionListEntity) => {
			draft.elements = ids.reduce((acc, curr) => {
				const item = acc.get(`${curr}`);
				if (item) {
					acc = acc.set(`${curr}`, item.toggleSelection());
					return acc;
				}
				return acc;
			}, this.elements);
		});
	}

	public setDetails(view: ExpositionEntity): ExpositionListEntity {
		const target = this.elements.get(`${view.id}`);
		if (!target) {
			return this;
		}
		return produce(this, (draft: ExpositionListEntity) => {
			const updated = target.updateView(view);
			this.elements.set(`${view.id}`, updated);
			draft.elements = this.elements.set(`${view.id}`, updated);
		});
	}
}
