import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule } from '@angular/forms';
import { DcLayoutModule } from '@datachain/ui-sdk/components';
import { DcPageModule } from '@datachain/ui-sdk/components/app-page';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { MaterialModule } from '../ui/material.module';
import { UiModule } from '../ui/ui.module';
import { OrphanProjectListComponent } from './components/orphan-project-list/orphan-project-list.component';
import { OrphanProjectParser } from './orphan-project-parser';
import { OrphanProjectEffects } from './store/orphan-project.effects';
import { orphanProjectReducer } from './store/orphan-project.reducer';
import { OrphanProjectSelector } from './store/orphan-project.selector';
import { OrphanProjectState } from './store/orphan-project.state';

@NgModule({
	declarations: [OrphanProjectListComponent],
	imports: [
		CommonModule,
		UiModule,
		DcLayoutModule,
		DcPageModule,
		MaterialModule,
		StoreModule.forFeature(OrphanProjectState.key, orphanProjectReducer),
		EffectsModule.forFeature([OrphanProjectEffects]),
		FormsModule,
	],
	providers: [OrphanProjectSelector, OrphanProjectParser],
})
export class OrphanProjectModule {
	public constructor(private readonly injector: Injector) {
		const orphanProjectsListCmpElement = createCustomElement(
			OrphanProjectListComponent,
			{
				injector,
			}
		);
		customElements.define(
			'app-orphan-project-list',
			orphanProjectsListCmpElement
		);
	}
}
