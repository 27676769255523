import { IComparable } from '@datachain/ui-sdk/common';
import { immerable } from 'immer';

interface IExportCharsetEntity {
	label: string;
	value: string;
}

const entityDefaults: IExportCharsetEntity = {
	label: '',
	value: '',
};

export class ExportCharsetEntity
	implements IExportCharsetEntity, IComparable<ExportCharsetEntity>
{
	private [immerable] = true;
	public label = entityDefaults.label;
	public value = entityDefaults.value;
	public static build(
		params: Partial<IExportCharsetEntity> = {}
	): ExportCharsetEntity {
		const instance = new ExportCharsetEntity();
		instance.value = params.value ?? entityDefaults.value;
		instance.label = params.label ?? entityDefaults.label;
		return instance;
	}

	public compareTo(obj: ExportCharsetEntity): number {
		return this.label.localeCompare(obj.label);
	}
}
