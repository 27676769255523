import { Injectable, OnDestroy } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	Validators,
} from '@angular/forms';
import { DcForm } from '@datachain/ui-sdk/common';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	ReplaySubject,
	takeUntil,
	tap,
} from 'rxjs';

import { ExportFileConfigurationEntity } from '../export-file-configuration/export-file-configuration.entity';
import { ExportPartitionConfigurationEntity } from '../export-partition-configuration/export-partition-configuration.entity';
import { ExportWriteModeConfigurationEntity } from '../export-write-mode-configuration/export-write-mode-configuration.entity';
import {
	ConnectorMode,
	ExportConnectorEntity,
} from './export-connector.entity';
import {
	ExportFormat,
	ExportTemplateDetailsEntity,
} from './export-template-details.entity';
import { ExportTemplateDetailsHelper } from './export-template-details.helper';
import { ExportTemplateFormHelper } from './export-template.form-helper';

export enum ExportTemplateFormControls {
	ConnectorMode = 'connectorMode',
	ExportType = 'exportType',
	KeepOriginalLabels = 'keepOriginalLabels',
	Compress = 'compress',
	CsvConfig = 'csvConfig',
	TxtMetaConfig = 'txtConfig',
	XmlConfig = 'xmlConfig',
	ExcelConfig = 'excelConfig',
	DbConfig = 'dbConfig',
	WriteModeConfig = 'writeModeConfig',
	Path = 'path',
	FileNamePatternPrediction = 'fileNamePatternPrediction',
	Partitioning = 'partitioning',
	FileConfig = 'fileConfig',
}

@Injectable()
export class ExportTemplateDetailsForm
	extends DcForm<ExportTemplateDetailsEntity>
	implements OnDestroy
{
	private readonly onDestroy$ = new ReplaySubject<void>(1);
	private id: number;
	public connector = new FormControl<ExportConnectorEntity | null>(
		{
			value: ExportConnectorEntity.localConnector(),
			disabled: false,
		},
		[Validators.required]
	);

	public exportType = new FormControl<ExportFormat | null>(
		{
			value: null,
			disabled: false,
		},
		[Validators.required]
	);

	public keepOriginalLabels = new FormControl<boolean>(
		{
			value: true,
			disabled: false,
		},
		[]
	);

	public compress = new FormControl<boolean>(
		{
			value: false,
			disabled: false,
		},
		[]
	);

	public path = new FormControl<string | null>(
		{
			value: null,
			disabled: true,
		},
		[Validators.required]
	);

	public partition = new FormControl<ExportPartitionConfigurationEntity | null>(
		{
			value: ExportPartitionConfigurationEntity.build(),
			disabled: false,
		}
	);

	public fileConfig = new FormControl<ExportFileConfigurationEntity | null>({
		value: null,
		disabled: false,
	});

	public writeMode = new FormControl<ExportWriteModeConfigurationEntity | null>(
		{
			value: null,
			disabled: false,
		}
	);

	public fileNamePatternPrediction = new FormControl<string>('');

	protected _form = this.fb.group({});

	public constructor(
		protected readonly fb: FormBuilder,
		public readonly exportTemplateDetailsHelper: ExportTemplateDetailsHelper
	) {
		super();
		this.id = -1;
		this.addControls();
		this.initListeners();
	}

	public populate(entity: ExportTemplateDetailsEntity): void {
		this.id = entity.id;
		return ExportTemplateFormHelper.buildWith(
			entity.connector.type,
			entity.outputType
		).populate(entity, this);
	}

	public extract(): ExportTemplateDetailsEntity {
		return ExportTemplateFormHelper.buildWith(
			this.connector.value?.type as ConnectorMode,
			this.exportType.value as ExportFormat
		).generate(this.id, this.form);
	}

	protected updateForm(
		connector: ExportConnectorEntity | null,
		exportType: ExportFormat | null
	): void {
		if (connector === null || exportType === null) {
			return;
		}
		const connectorType = connector.type;
		return ExportTemplateFormHelper.buildWith(connectorType, exportType).update(
			connector,
			exportType,
			this
		);
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	public compareConnectors(
		c1: ExportConnectorEntity,
		c2: ExportConnectorEntity
	): boolean {
		return c1.compareTo(c2) === 0;
	}

	public updatePath(path: string): void {
		this.form.patchValue({
			[ExportTemplateFormControls.Path]: path,
		});
	}

	public updatePredictedFileName(val: string): void {
		this.form.patchValue({
			[ExportTemplateFormControls.FileNamePatternPrediction]: val,
		});
		this.form
			.get(ExportTemplateFormControls.FileNamePatternPrediction)
			?.markAsTouched();
	}

	protected addControls(): void {
		this.form.addControl(
			ExportTemplateFormControls.ConnectorMode,
			this.connector
		);
		this.form.addControl(
			ExportTemplateFormControls.ExportType,
			this.exportType
		);
		this.form.addControl(
			ExportTemplateFormControls.KeepOriginalLabels,
			this.keepOriginalLabels
		);
	}

	protected initListeners(): void {
		const connectorCtrl = this.form.get(
			ExportTemplateFormControls.ConnectorMode
		) as AbstractControl;
		const exportTypeCtrl = this.form.get(
			ExportTemplateFormControls.ExportType
		) as AbstractControl;

		connectorCtrl.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				debounceTime(320),
				distinctUntilChanged(),
				filter((val): val is ExportConnectorEntity => val),
				tap((connector) => {
					const _exportType =
						this.exportType.value === ExportFormat.None
							? ExportFormat.CSV
							: this.exportType.value;
					this.form
						.get(ExportTemplateFormControls.ExportType)
						?.patchValue(_exportType);
					this.exportTemplateDetailsHelper.setSelectedConnectorType(
						connector.type
					);
					this.updateForm(connector, _exportType);
				})
			)
			.subscribe();

		exportTypeCtrl.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				debounceTime(320),
				distinctUntilChanged(),
				filter((val): val is ExportFormat => val !== null),
				tap((exportType) => {
					const _exportType =
						exportType === ExportFormat.None ? ExportFormat.CSV : exportType;
					this.updateForm(this.connector.value, _exportType);
				})
			)
			.subscribe();
	}

	public toggleControls(
		enable: boolean,
		...controlNames: Array<ExportTemplateFormControls>
	): void {
		if (enable) {
			return controlNames.forEach((c) => {
				if (!this.form.get(c)?.enabled) {
					this.form.get(c)?.enable();
				}
			});
		}
		return controlNames.forEach((c) => {
			if (!this.form.get(c)?.disabled) {
				this.form.get(c)?.disable();
			}
		});
	}

	public removeControls(
		...controlNames: Array<ExportTemplateFormControls>
	): void {
		controlNames.forEach((c) => {
			if (this.form.get(c)) {
				this.form.removeControl(c);
			}
		});
	}
}
