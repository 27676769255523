import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineAll, DcBaseComponent } from '@datachain/ui-sdk/common';
import { Observable } from 'rxjs';

import { FormulaUsageEntity } from '../formula-usage-list/formula-usage.entity';

@Component({
	selector: 'app-formula-usage-details',
	templateUrl: './formula-usage-details.component.html',
	styleUrls: ['./formula-usage-details.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	inputs: ['details'],
})
export class FormulaUsageDetailsComponent extends DcBaseComponent {
	public vo$: Observable<{ details: FormulaUsageEntity }>;

	public constructor() {
		super();
		this.cmpId = 'formula-usage-details';

		this.vo$ = combineAll({
			details: this.toObservable<FormulaUsageEntity>('details'),
		});
	}
}
