import {
	ChangeDetectionStrategy,
	Component,
	Inject,
	OnInit,
} from '@angular/core';
import { combineAll, DcBaseComponent } from '@datachain/ui-sdk/common';
import {
	IBreadCrumbEntry,
	PanelAction,
	AppIcons,
} from '@datachain/ui-sdk/components';
import { Store } from '@ngrx/store';
import { firstValueFrom, map, Observable, of, takeUntil, tap } from 'rxjs';

import { FunctionalDocumentationKeys } from '../../../core/documentation/functional-doc-keys';
import { ActionIcons } from '../../../ui/app-actions.icons';
import { ComponentIcons } from '../../../ui/app-components.icons';
import { DcIcons } from '../../../ui/app-dc.icons';
import { ActionIconsInjectable } from '../../../ui/ui.module';
import { ExpositionColumnConfigListEntity } from '../../domain/exposition-column-config-list.entity';
import { PublicationStatus } from '../../domain/publication-status';
import {
	ExpositionsSelector,
	goToExpositionCreateAccessRoute,
	goToExpositionEditAccessRoute,
	goToExpositionEditColumnsRoute,
	goToExpositionSimulationRoute,
	goToExpositionsListRoute,
	initExpositionView,
	openActivateConfirmationPopup,
	openClearDataConfirmationPopup,
	openDeactivateConfirmationPopup,
	openDeleteAccessPopup,
	openDeleteConfirmationPopup,
	openEditExpositionEndpointMetadataPopup,
	openEditExpositionMetadataPopup,
	openPublishConfirmationPopup,
	openUpdateDataConfirmationPopup,
	openUpdateParamsConfirmationPopup,
	PublicationAction,
	toggleAccessStatus,
} from '../../store';
import { ExpositionEndpointMetadataEntity } from '../exposition-endpoint-metadata-config/exposition-endpoint-metadata.entity';
import { ExpositionMetadataEntity } from '../exposition-internal-metadata-config/exposition-metadata.entity';
import { ExpositionAccessListEntity } from './exposition-access-list/exposition-access-list.entity';
import { ExpositionAccessEntity } from './exposition-access-list/exposition-access.entity';
import { PublishedExpositionEntity } from './published-exposition/published-exposition.entity';

@Component({
	selector: 'app-exposition-view',
	templateUrl: './exposition-view.component.html',
	styleUrls: ['./exposition-view.component.scss'],
	inputs: ['expositionId'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpositionViewComponent extends DcBaseComponent implements OnInit {
	// FIXME: import type
	public paths: Array<IBreadCrumbEntry> = [];
	public columnsActions: Array<PanelAction> = [];
	public accessListActions: Array<PanelAction> = [];
	public publicMetadataActions: Array<PanelAction> = [];

	public vo$: Observable<{
		expositionId: number;
		canDelete: boolean;
		canEdit: boolean;
		metadata: ExpositionMetadataEntity;
		endpointMetadata: ExpositionEndpointMetadataEntity;
		columns: ExpositionColumnConfigListEntity;
		accessPoints: ExpositionAccessListEntity;
		published: PublishedExpositionEntity;
		actionInProgress: PublicationAction;
	}>;

	protected readonly DcIcons = DcIcons;
	protected readonly ComponentIcons = ComponentIcons;
	protected readonly PublicationStatus = PublicationStatus;

	protected readonly FunctionalDocumentationKeys = FunctionalDocumentationKeys;
	public constructor(
		private readonly store: Store,
		private readonly expositionSelector: ExpositionsSelector,
		@Inject(ActionIconsInjectable) private readonly actionIcons: AppIcons
	) {
		super();
		this.cmpId = 'exposition-view';

		combineAll({
			expositionId: this.toObservable<number>('expositionId'),
		})
			.pipe(
				takeUntil(this.onDestroy$),
				tap(({ expositionId }) =>
					this.store.dispatch(
						initExpositionView({
							expositionId,
						})
					)
				)
			)
			.subscribe();

		const columns$ = this.expositionSelector
			.getColumns$()
			.pipe(
				map((columns) =>
					columns.setElements(columns.elements.filter((c) => c.isActive))
				)
			);

		this.vo$ = combineAll({
			expositionId: this.toObservable<number>('expositionId'),
			canDelete: this.expositionSelector.getCanDeleteExposition$(),
			canEdit: this.expositionSelector.getCanEditExposition$(),
			metadata: this.expositionSelector.getMetadata$(),
			endpointMetadata: this.expositionSelector.getEndpointMetadata$(),
			columns: columns$,
			accessPoints: this.expositionSelector.getAccessList$(),
			published: this.expositionSelector.getPublishedExposition$(),
			actionInProgress: this.expositionSelector.getActionInProgress$(),
		});
	}

	public ngOnInit(): void {
		super.ngOnInit();
		this.expositionSelector
			.getMetadata$()
			.pipe(
				takeUntil(this.onDestroy$),
				tap((metadata) => {
					this.paths = [
						{
							label: $localize`:i18n=@@expositions.breadcrumb.list:`,
							isActive: true,
							tooltip: $localize`:i18n=@@expositions.breadcrumb.list.tooltip:`,
							callback: (): void => {
								this.store.dispatch(goToExpositionsListRoute());
							},
						},
						{
							label: metadata.label,
							isActive: false,
							callback: (): void => {},
						},
					];
				})
			)
			.subscribe();
		of(null)
			.pipe(
				takeUntil(this.onDestroy$),
				tap(() => this.bootstrapIcons())
			)
			.subscribe();
	}

	public async editMetadata(): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		this.store.dispatch(
			openEditExpositionMetadataPopup({
				expositionId,
			})
		);
	}

	public publish(): void {}

	public async editEndpointMetadata(): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		this.store.dispatch(
			openEditExpositionEndpointMetadataPopup({
				expositionId,
			})
		);
	}

	public async editColumns(): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		this.store.dispatch(
			goToExpositionEditColumnsRoute({
				expositionId,
			})
		);
	}

	public async addNewAccessApi(): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		this.store.dispatch(
			goToExpositionCreateAccessRoute({
				expositionId,
			})
		);
	}
	public async goToAccessConfig(accessId: string): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		this.store.dispatch(
			goToExpositionEditAccessRoute({
				expositionId,
				accessId,
			})
		);
	}

	public async toggleAccessStatus(accessId: string): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		this.store.dispatch(
			toggleAccessStatus({
				expositionId,
				accessId,
			})
		);
	}

	public async deleteAccess(access: ExpositionAccessEntity): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		this.store.dispatch(
			openDeleteAccessPopup({
				expositionId,
				accessId: access.id,
				accessLabel: access.label,
			})
		);
	}
	public async openPublishConfirmationPopup(): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		const exposition = await firstValueFrom(
			this.expositionSelector.getMetadata$()
		);
		this.store.dispatch(
			openPublishConfirmationPopup({
				expositionId,
				expositionLabel: exposition.label,
			})
		);
	}
	public async openUpdateConfirmationPopup(): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		const metadata = await firstValueFrom(
			this.expositionSelector.getMetadata$()
		);
		const accessList = await firstValueFrom(
			this.expositionSelector.getAccessList$()
		);
		this.store.dispatch(
			openUpdateParamsConfirmationPopup({
				expositionId,
				expositionLabel: metadata.label,
				hasActiveAccess:
					accessList.elements.filter((access) => access.isActive).size !== 0,
			})
		);
	}
	public async openUpdateDataConfirmationPopup(): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		const exposition = await firstValueFrom(
			this.expositionSelector.getMetadata$()
		);
		this.store.dispatch(
			openUpdateDataConfirmationPopup({
				expositionId,
				expositionLabel: exposition.label,
			})
		);
	}
	public async openClearDataConfirmationPopup(): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		const exposition = await firstValueFrom(
			this.expositionSelector.getMetadata$()
		);
		this.store.dispatch(
			openClearDataConfirmationPopup({
				expositionId,
				expositionLabel: exposition.label,
			})
		);
	}
	public async openActivateConfirmationPopup(): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		const exposition = await firstValueFrom(
			this.expositionSelector.getMetadata$()
		);
		this.store.dispatch(
			openActivateConfirmationPopup({
				expositionId,
				expositionLabel: exposition.label,
			})
		);
	}
	public async openDeactivateConfirmationPopup(): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		const exposition = await firstValueFrom(
			this.expositionSelector.getMetadata$()
		);
		this.store.dispatch(
			openDeactivateConfirmationPopup({
				expositionId,
				expositionLabel: exposition.label,
			})
		);
	}

	public async openDeleteConfirmationPopup(): Promise<void> {
		const expositionId = await firstValueFrom(
			this.toObservable<number>('expositionId')
		);
		const exposition = await firstValueFrom(
			this.expositionSelector.getMetadata$()
		);
		this.store.dispatch(
			openDeleteConfirmationPopup({
				expositionId,
				expositionLabel: exposition.label,
			})
		);
	}

	public async goToSimulationRoute(): Promise<void> {
		const published = await firstValueFrom(
			this.expositionSelector.getPublishedExposition$()
		);
		this.store.dispatch(
			goToExpositionSimulationRoute({
				expositionId: published.id,
			})
		);
	}

	private async bootstrapIcons(): Promise<void> {
		const actions = [
			ActionIcons.AddExpositionAccess,
			ActionIcons.EditExpositionColumns,
		];
		const actionMap = await firstValueFrom(
			this.actionIcons.getFromRegistry(...actions)
		);

		this.columnsActions = [
			{
				order: 0,
				command: this.editColumns.bind(this),
				iconName: actionMap.get(ActionIcons.EditExpositionColumns)?.iconName,
				description: '',
				tooltip: '',
				typed: '',
			},
		];

		this.accessListActions = [
			{
				order: 0,
				command: this.addNewAccessApi.bind(this),
				iconName: actionMap.get(ActionIcons.AddExpositionAccess)?.iconName,
				description: '',
			},
		];

		this.publicMetadataActions = [
			{
				order: 0,
				command: this.editEndpointMetadata.bind(this),
				iconName: actionMap.get(ActionIcons.EditExpositionColumns)?.iconName,
				description: 'menu action',
			},
		];
	}
}
