/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import { UrlService } from '@datachain/ui-sdk/common';
import { AppTagItemEntity } from '@datachain/ui-sdk/components';
import { Observable } from 'rxjs';

import { TagAdapter } from '../../core/tag.adapter';
import { FileNameGenStrategy } from '../../export-template/components/export-file-configuration/export-file-configuration.entity';
import { ConnectorMode } from '../../export-template/components/export-template-details/export-connector.entity';
import { ExportTemplateDetailsEntity } from '../../export-template/components/export-template-details/export-template-details.entity';
import { AppMetadataEntity } from '../../ui/components/app-metadata/app-metadata.entity';
import { DcBaseRequester } from '../core/dc-base.requester';
import {
	IExportTemplateConfig,
	IExportTemplateResponse,
} from './models/export-template.models';

/* eslint-disable @typescript-eslint/no-explicit-any */
type IExportParquetTemplate = any;

@Injectable({
	providedIn: 'root',
})
export class ExportParquetTemplateRequester extends DcBaseRequester<
	IExportParquetTemplate,
	{ templateId: string },
	{ project: number }
> {
	protected resource;
	protected elementsListUrl;
	protected singleElementUrl;
	public constructor(
		private readonly urlService: UrlService,
		private readonly tagPayloadAdapter: TagAdapter
	) {
		super();
		this.resource = this.generateResourceUrl('DATABLOCK_EXPORT_TEMPLATE');
		this.elementsListUrl = `${this.resource}`;
		this.singleElementUrl = `${this.elementsListUrl}/{templateId}`;
	}

	public updateTemplate(
		isNew: boolean,
		payload: {
			id?: number;
			templateDetails: ExportTemplateDetailsEntity;
			templateMetadata: AppMetadataEntity;
			templateTags: Array<AppTagItemEntity>;
		}
	): Observable<IExportTemplateResponse> {
		const body: IExportTemplateResponse = {
			metadata: {
				id: payload.templateMetadata.id, // FIXME: not necessary if metadata is handled on server side
				label: payload.templateMetadata.title,
				description: payload.templateMetadata.description,
				actif: payload.templateMetadata.isActive,
				tags: this.tagPayloadAdapter.generateMetadataTagPayload(
					payload.templateTags
				),
			},
			config: this.generatePayload(payload.templateDetails),
		};
		let url: string;
		if (!isNew && payload.id !== undefined) {
			url = this.urlService.generateUrl(
				this.generateResourceUrl('DATABLOCK_EXPORT_TEMPLATE_UPDATE'),
				{
					templateId: payload.id,
				},
				{
					project: this.projectId,
				}
			);
			return this.http.put<IExportTemplateResponse>(url, body);
		}

		url = this.urlService.generateUrl(
			this.generateResourceUrl('DATABLOCK_EXPORT_TEMPLATE_POST'),
			{},
			{
				project: this.projectId,
			}
		);
		return this.http.post<IExportTemplateResponse>(url, body);
	}

	public getById(id: number): Observable<IExportTemplateResponse> {
		const url = this.urlService.generateUrl(
			this.singleElementUrl,
			{
				templateId: id,
			},
			{
				project: this.projectId,
			}
		);
		return this.http.get<IExportTemplateResponse>(url);
	}

	public generatePayload(
		templateDetails: ExportTemplateDetailsEntity
	): IExportTemplateConfig {
		return {
			type: 'PARQUET',
			connector_id:
				templateDetails.connector.type !== ConnectorMode.Local
					? templateDetails.connector.id
					: undefined,
			keep_original_labels: templateDetails.keepOriginalLabels,
			compress: templateDetails.compress,
			partition_count: templateDetails.partition.count,
			path: templateDetails.path,
			file_name: templateDetails.fileNameConfig.userDefinedLabel,
			naming_strategy:
				templateDetails.fileNameConfig.strategy === FileNameGenStrategy.UUID
					? 'UUID'
					: 'FILE_NAME',
		};
	}
}
