import { IBasicUser } from '../../core/models/basic-user.model';
import { IExpositionColumnConfig } from './exposition-column-config.model';

export enum HistoryStatus {
	UNPUBLISHED = 'UNPUBLISHED',
	WAITING = 'WAITING',
	RUNNING = 'RUNNING',
	AVAILABLE = 'AVAILABLE',
	UNAVAILABLE = 'UNAVAILABLE',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
	UNKNOWN = 'UNKNOWN',
}

export enum ActionType {
	UPDATE_PUBLIC_METADATA = 'UPDATE_PUBLIC_METADATA',
	UPDATE_ACCESS = 'UPDATE_ACCESS',
	UPDATE_COLUMNS = 'UPDATE_COLUMNS',
	UPDATE_CONF = 'UPDATE_CONF',
	UPDATE_DATA = 'UPDATE_DATA',
	PUBLISH_DATA = 'PUBLISH_DATA',
	CLEAR_DATA = 'CLEAR_DATA',
	ENABLE = 'ENABLE',
	DISABLE = 'DISABLE',
	CREATION = 'CREATION',
}

export interface IPublicationHistory {
	status?: HistoryStatus;
	date?: string;
	duration?: number;
	updated_by?: IBasicUser;
	nbr_of_lines?: number;
	access?: number;
	action?: ActionType;
}

export interface IPublicationHistoryDetails extends IPublicationHistory {
	id?: number;
	job_id?: string;
	access_list?: Array<string>;
	columns?: Array<IExpositionColumnConfig>;
	public_metadata?: {
		access_point?: string;
		title?: string;
		details?: string;
		keywords?: Array<string>;
	};
}
