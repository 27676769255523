import { Injectable } from '@angular/core';
import {
	CachePolicy,
	CachePolicyEntity,
	UrlService,
} from '@datachain/ui-sdk/common';
import { Observable } from 'rxjs';

import { DcBaseRequester } from '../core/dc-base.requester';
import { IShareableDatablockColumn } from './models/shareable-datablock-column.model';
import { IShareableDatablock } from './models/shareable-datablock.model';

@Injectable({
	providedIn: 'root',
})
export class ShareableDatablockRequester extends DcBaseRequester<IShareableDatablock> {
	protected resource;
	protected elementsListUrl;
	protected singleElementUrl;

	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('DATABLOCKS_SHAREABLE');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = this.resource;
	}

	public getShareableDatablocks(
		useCache: boolean
	): Observable<ReadonlyArray<IShareableDatablock>> {
		const url = this.urlService.generateUrl(
			this.elementsListUrl,
			{},
			{
				project: this.projectId,
			}
		);
		return this.get<ReadonlyArray<IShareableDatablock>>(
			url,
			CachePolicyEntity.build({
				policy: useCache ? CachePolicy.CacheOnly : CachePolicy.NetworkOnly,
				evictTimeout: 5 * 60,
				isCacheable: true,
			})
		);
	}

	public getDatablockColumns(
		datablockId: number,
		useCache: boolean
	): Observable<ReadonlyArray<IShareableDatablockColumn>> {
		const resourceUrl = this.generateResourceUrl(
			'DATABLOCKS_SHAREABLE_COLUMNS'
		);
		const url = this.urlService.generateUrl(
			resourceUrl,
			{
				datablockId,
			},
			{
				project: this.projectId,
				datablockId,
			}
		);
		return this.get<ReadonlyArray<IShareableDatablockColumn>>(
			url,
			CachePolicyEntity.build({
				policy: useCache ? CachePolicy.CacheOnly : CachePolicy.NetworkOnly,
				evictTimeout: 5 * 60,
				isCacheable: true,
			})
		);
	}
}
