<ng-container *ngIf="vo$ | async; let vo">
	<ng-container [ngSwitch]="vo.status">
		<div
			*ngSwitchCase="PublicationStatus.Unpublished"
			class="app-publication-status unpublished">
			<mat-icon [svgIcon]="DcIcons.ShareOff"></mat-icon>
			<span i18n="@@expositions.publication.status.unpublished">
				unpublished
			</span>
		</div>
		<div
			*ngSwitchCase="PublicationStatus.Available"
			class="app-publication-status available">
			<mat-icon [svgIcon]="DcIcons.Share"></mat-icon>
			<span i18n="@@expositions.publication.status.available">available</span>
		</div>
		<div
			*ngSwitchCase="PublicationStatus.Unavailable"
			class="app-publication-status unavailable">
			<mat-icon [svgIcon]="DcIcons.ShareOff"></mat-icon>
			<span i18n="@@expositions.publication.status.unavailable">
				unavailable
			</span>
		</div>
		<div *ngSwitchCase="PublicationStatus.Waiting" class="waiting">
			<mat-icon [svgIcon]="DcIcons.Pending"></mat-icon>
		</div>
		<div *ngSwitchCase="PublicationStatus.Running" class="running">
			<mat-icon><mat-spinner [diameter]="20"></mat-spinner></mat-icon>
		</div>
		<div *ngSwitchCase="PublicationStatus.Success" class="success">
			<mat-icon [svgIcon]="DcIcons.Check"></mat-icon>
		</div>

		<div *ngSwitchCase="PublicationStatus.Error" class="error">
			<mat-icon [svgIcon]="DcIcons.Cancel"></mat-icon>
		</div>
	</ng-container>
</ng-container>
