import { Injectable } from '@angular/core';
import {
	DcBaseInitializationService,
	AbsLanguageInterceptor,
} from '@datachain/ui-sdk/common';

@Injectable()
export class DcLanguageInterceptor extends AbsLanguageInterceptor {
	protected getLangLocale(): string {
		return DcBaseInitializationService.getConfigInstance().langLocale;
	}
}
