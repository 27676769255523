<ng-container *ngIf="vo$ | async; let vo">
	<form class="app-export-txt-meta-configuration" [formGroup]="form">
		<mat-form-field
			id="{{ cmpId + '-encoding-select' }}"
			[appearance]="Appearance">
			<mat-label i18n="@@export.csv.encoding">Encodage</mat-label>
			<mat-select
				disableOptionCentering
				[formControlName]="ExportTxtMetaFormControls.Encoding">
				<mat-option
					id="{{ cmpId + '-encoding-opt-' + i }}"
					*ngFor="let charset of vo.charsets; let i = index"
					[value]="charset.value">
					{{ charset.label }}
				</mat-option>
			</mat-select>

			<mat-error
				*ngIf="
					form
						.get(ExportTxtMetaFormControls.Encoding)
						?.hasError(ValidationErrorKeys.Required)
				"
				i18n="@@general.form.errors.required">
				You must make a selection
			</mat-error>
		</mat-form-field>

		<mat-form-field
			id="{{ cmpId + '-separator-input' }}"
			[appearance]="Appearance">
			<mat-label i18n="@@export.csv.separator">Separator</mat-label>
			<input
				type="text"
				matInput
				placeholder="separator"
				i18n-placeholder="@@export.csv.separator.placeholder"
				[formControlName]="ExportTxtMetaFormControls.Separator" />
			<mat-error
				*ngIf="
					form
						.get(ExportTxtMetaFormControls.Separator)
						?.hasError(ValidationErrorKeys.Required)
				"
				i18n="@@general.form.errors.required">
				You must make a selection
			</mat-error>
			<mat-error
				*ngIf="
					form
						.get(ExportTxtMetaFormControls.Separator)
						?.hasError(ValidationErrorKeys.Maxlength)
				"
				i18n="@@general.form.errors.maxlength">
				one-character-only
			</mat-error>
		</mat-form-field>

		<div class="app-export-txt-meta-configuration__characterDelimiterOptions">
			<app-tooltip
				tooltipText="includeAllCharacters"
				i18n-tooltipText="@@export.csv.includeAllCharacters.tooltip">
				<mat-checkbox
					id="{{ cmpId + '-includeAllCharacters-chkbx' }}"
					color="primary"
					[formControlName]="ExportTxtMetaFormControls.QuoteAll">
					<span i18n="@@export.csv.includeAllCharacters">
						Sur toutes les chaines de caractères
					</span>
				</mat-checkbox>
			</app-tooltip>

			<mat-form-field
				id="{{ cmpId + '-characterSequenceDelimiter-input' }}"
				[appearance]="Appearance">
				<mat-label i18n="@@export.csv.characterSequenceDelimiter">
					Identificateur de chaines de caractères
				</mat-label>
				<input
					type="text"
					matInput
					placeholder="characterSequenceDelimiter"
					i18n-placeholder="@@export.csv.characterSequenceDelimiter.placeholder"
					[formControlName]="ExportTxtMetaFormControls.Quote" />
				<mat-error
					*ngIf="
						form
							.get(ExportTxtMetaFormControls.Quote)
							?.hasError(ValidationErrorKeys.Required)
					"
					i18n="@@general.form.errors.required">
					You must make a selection
				</mat-error>
				<mat-error
					*ngIf="
						form
							.get(ExportTxtMetaFormControls.Quote)
							?.hasError(ValidationErrorKeys.Maxlength)
					"
					i18n="@@general.form.errors.maxlength">
					one-character-only
				</mat-error>
			</mat-form-field>
		</div>

		<div
			class="app-export-txt-meta-configuration__emptyNullFieldPlaceholderValueOptions">
			<app-tooltip
				tooltipText="shouldApplyInvalidFieldPlaceholderValue"
				i18n-tooltipText="
					@@export.csv.shouldApplyInvalidFieldPlaceholderValue.tooltip">
				<mat-checkbox
					id="{{ cmpId + '-shouldApplyInvalidFieldPlaceholderValue-chkbx' }}"
					color="primary"
					[formControlName]="
						ExportTxtMetaFormControls.ShouldApplyInvalidFieldPlaceholderValue
					">
					<span i18n="@@export.csv.shouldApplyInvalidFieldPlaceholderValue">
						Sur toutes les chaines de caractères
					</span>
				</mat-checkbox>
			</app-tooltip>

			<mat-form-field
				id="{{ cmpId + '-invalidFieldPlaceholderValue-input' }}"
				[appearance]="Appearance">
				<mat-label i18n="@@export.csv.invalidFieldPlaceholderValue">
					invalidFieldPlaceholderValue
				</mat-label>
				<input
					type="text"
					matInput
					placeholder="invalidFieldPlaceholderValue"
					i18n-placeholder="
						@@export.csv.invalidFieldPlaceholderValue.placeholder"
					[formControlName]="
						ExportTxtMetaFormControls.InvalidFieldValuePlaceholderValue
					" />
				<mat-error
					*ngIf="
						form
							.get(ExportTxtMetaFormControls.InvalidFieldValuePlaceholderValue)
							?.hasError(ValidationErrorKeys.Required)
					"
					i18n="@@general.form.errors.required">
					You must make a selection
				</mat-error>
			</mat-form-field>
		</div>

		<mat-form-field
			id="{{ cmpId + '-escapeCharacter-input' }}"
			[appearance]="Appearance">
			<mat-label i18n="@@export.csv.escapeCharacter">
				caractère d'échappement
			</mat-label>
			<input
				type="text"
				matInput
				placeholder="escapeCharacter"
				i18n-placeholder="@@export.csv.escapeCharacter.placeholder"
				[formControlName]="ExportTxtMetaFormControls.EscapeCharacter" />
			<mat-error
				*ngIf="
					form
						.get(ExportTxtMetaFormControls.EscapeCharacter)
						?.hasError(ValidationErrorKeys.Required)
				"
				i18n="@@general.form.errors.required">
				required
			</mat-error>
		</mat-form-field>

		<div>
			<mat-checkbox
				id="{{ cmpId + '-keepLeftWhite-chkbx' }}"
				color="primary"
				[formControlName]="ExportTxtMetaFormControls.KeepLeftWhiteSpace">
				<span i18n="@@export.csv.keepLeftWhite">Garder le blanc à gauche</span>
			</mat-checkbox>
		</div>

		<div>
			<mat-checkbox
				id="{{ cmpId + '-keepRightWhite-chkbx' }}"
				color="primary"
				[formControlName]="ExportTxtMetaFormControls.KeepRightWhiteSpace">
				<span i18n="@@export.csv.keepRightWhite">Garder le blanc à droite</span>
			</mat-checkbox>
		</div>

		<div>
			<mat-checkbox
				id="{{ cmpId + '-keepHeaders-chkbx' }}"
				color="primary"
				[formControlName]="ExportTxtMetaFormControls.KeepHeaders">
				<span i18n="@@export.csv.keepHeaders">Exporter les entêtes</span>
			</mat-checkbox>
		</div>

		<mat-form-field
			id="{{ cmpId + '-text-area' }}"
			[appearance]="Appearance"
			class="app-export-txt-meta-configuration__text-area">
			<mat-label i18n="@@export.txt.metadata-area">Metadata</mat-label>
			<textarea
				type="text"
				matInput
				rows="10"
				placeholder="placeholder"
				i18n-placeholder="@@export.txt.metadata-area.placeholder"
				[formControlName]="ExportTxtMetaFormControls.Metadata"></textarea>
		</mat-form-field>
	</form>
</ng-container>
