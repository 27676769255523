import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { DcLayoutModule } from '@datachain/ui-sdk/components';
import { DcPageModule } from '@datachain/ui-sdk/components/app-page';
import { DcArrayPipeModule, DcValuePipeModule } from '@datachain/ui-sdk/pipes';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
	DxButtonModule,
	DxCheckBoxModule,
	DxDataGridModule,
	DxSelectBoxModule,
} from 'devextreme-angular';

import { MaterialModule } from '../ui/material.module';
import { UiModule } from '../ui/ui.module';
import { FormulaUsageDetailsComponent } from './components/formula-usage-details/formula-usage-details.component';
import { FormulaUsageListComponent } from './components/formula-usage-list/formula-usage-list.component';
import { FormulaParser } from './formula.parser';
import { FormulaEffects } from './store/formula.effects';
import { formulaReducer } from './store/formula.reducer';
import { FormulaSelector } from './store/formula.selector';
import { FormulaState } from './store/formula.state';

@NgModule({
	declarations: [FormulaUsageListComponent, FormulaUsageDetailsComponent],
	imports: [
		CommonModule,
		UiModule,
		DcLayoutModule,
		DcPageModule,
		DcArrayPipeModule,
		DcValuePipeModule,
		MaterialModule,
		StoreModule.forFeature(FormulaState.key, formulaReducer),
		EffectsModule.forFeature([FormulaEffects]),
		DxDataGridModule,
		DxCheckBoxModule,
		DxSelectBoxModule,
		DxButtonModule,
	],
	providers: [FormulaSelector, FormulaParser],
})
export class FormulaModule {
	public constructor(private readonly injector: Injector) {
		const formulasUsageListCmpElement = createCustomElement(
			FormulaUsageListComponent,
			{
				injector,
			}
		);
		customElements.define(
			'app-formulas-usage-list',
			formulasUsageListCmpElement
		);
	}
}
