<app-modal *ngIf="vo$ | async; let vo" (triggerClose)="close()">
	<div modal-body class="export-datablock-modal-content">
		<div
			class="export-datablock-modal-content__search"
			[formGroup]="searchInputForm">
			<mat-form-field [appearance]="Appearance">
				<mat-label i18n="@@export.template.search">models</mat-label>
				<input
					type="text"
					id="{{ cmpId + '-search-template-input' }}"
					matInput
					placeholder="autocomplete"
					i18n-placeholder="@@datablock.export.modal.autocomplete"
					[matAutocomplete]="auto"
					[formControlName]="DebouncedSearchFormControls.searchInputCtrl" />
				<mat-autocomplete #auto="matAutocomplete">
					<mat-option
						id="{{ cmpId + '-template-' + i }}"
						*ngFor="
							let template of vo.availableTemplates | toArray;
							let i = index
						"
						(onSelectionChange)="selectOption(template)"
						[value]="template.metadata.title">
						{{ template.metadata.title }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>

			<div class="export-datablock-modal-content__get-all">
				<button
					id="{{ cmpId + 'get-all-templates-btn' }}"
					(click)="getAllAvailableTemplates('-search-template-input')"
					mat-raised-button
					color="accent">
					<span i18n="@@datablock.list.export.get.all">All</span>
				</button>
			</div>
		</div>

		<app-export-template-details
			class="export-datablock-modal-content__details"
			[exportConfig]="vo.templateDetails"
			(hasChangedValue)="resetSelection($event)"
			(notifyOnError)="
				onExportDetailsError($event)
			"></app-export-template-details>
	</div>
	<div modal-footer>
		<button
			id="{{ cmpId + '-export-btn' }}"
			[disabled]="vo.isExportDisabled || vo.isExportInProgress"
			(click)="executeExport()"
			mat-raised-button
			color="primary">
			<mat-icon *ngIf="vo.isExportInProgress">
				<mat-spinner color="accent" diameter="20"></mat-spinner>
			</mat-icon>
			<span i18n="@@datablock.list.export.execute">Execute Export</span>
		</button>
	</div>
</app-modal>
