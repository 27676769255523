<app-modal *ngIf="vo$ | async; let vo">
	<div
		modal-body
		class="app-exposition-confirmation-popup"
		[ngClass]="{
			'app-exposition-confirmation-popup__publish':
				vo.action === ExpositionAction.Publish
		}">
		<div class="app-exposition-confirmation-popup__element">
			<ng-container *ngIf="!vo.isMultipleElement">
				<span
					i18n="
						@@expositions.publication.api.confirmation.popup.concernedElement">
					concerned Element
				</span>
				<span class="app-exposition-confirmation-popup__element-label">
					{{ vo.element }}
				</span>
			</ng-container>
			<ng-container *ngIf="vo.isMultipleElement">
				<span
					i18n="
						@@expositions.publication.api.confirmation.popup.concernedElements">
					concerned Elements
				</span>

				<div class="app-exposition-confirmation-popup__element-label">
					{{ vo.expositionId.length }}
					<span
						i18n="@@expositions.publication.api.confirmation.popup.expositions">
						expositions
					</span>
				</div>
			</ng-container>
		</div>
		<ng-container [ngSwitch]="vo.action">
			<span
				*ngSwitchCase="ExpositionAction.Publish"
				i18n="
					@@expositions.publication.api.confirmation.popup.publish.description">
				publication request
			</span>

			<div *ngSwitchCase="ExpositionAction.UpdateData">
				<span
					i18n="
						@@expositions.publication.api.confirmation.popup.updateData.description">
					update data description
				</span>

				<div class="app-exposition-confirmation-popup__info">
					<mat-icon [svgIcon]="DcIcons.Info"></mat-icon>
					<span
						class="app-exposition-confirmation-popup__message"
						i18n="
							@@expositions.publication.api.confirmation.popup.updateData.info">
						update data info
					</span>
				</div>
			</div>
			<div *ngSwitchCase="ExpositionAction.ClearData">
				<span
					i18n="
						@@expositions.publication.api.confirmation.popup.clear.description">
					clear data description
				</span>

				<div class="app-exposition-confirmation-popup__info">
					<mat-icon [svgIcon]="DcIcons.Info"></mat-icon>
					<span
						class="app-exposition-confirmation-popup__message"
						i18n="@@expositions.publication.api.confirmation.popup.clear.info">
						clear data info
					</span>
				</div>
			</div>
			<div *ngSwitchCase="ExpositionAction.Activate">
				<span
					i18n="
						@@expositions.publication.api.confirmation.popup.activate.description">
					activate description
				</span>
				<div class="app-exposition-confirmation-popup__info">
					<mat-icon [svgIcon]="DcIcons.Info"></mat-icon>
					<span
						class="app-exposition-confirmation-popup__message"
						i18n="
							@@expositions.publication.api.confirmation.popup.activate.info">
						activate info
					</span>
				</div>
			</div>
			<div *ngSwitchCase="ExpositionAction.Deactivate">
				<span
					i18n="
						@@expositions.publication.api.confirmation.popup.deactivate.description">
					deactivate description
				</span>
				<div class="app-exposition-confirmation-popup__info">
					<mat-icon [svgIcon]="DcIcons.Info"></mat-icon>
					<span
						class="app-exposition-confirmation-popup__message"
						i18n="
							@@expositions.publication.api.confirmation.popup.deactivate.info">
						deactivate info
					</span>
				</div>
			</div>
			<div
				*ngSwitchCase="ExpositionAction.Update"
				class="app-exposition-confirmation-popup__update">
				<span
					i18n="
						@@expositions.publication.api.confirmation.popup.update.description">
					update description
				</span>
				<div class="app-exposition-confirmation-popup__checkbox">
					<mat-checkbox
						id="{{ cmpId }}-update-metadata-checkbox"
						color="primary"
						[(ngModel)]="updateMetadata"></mat-checkbox>
					<span
						i18n="
							@@expositions.publication.api.confirmation.popup.update.metadata">
						public metadata
					</span>
				</div>
				<div class="app-exposition-confirmation-popup__checkbox">
					<mat-checkbox
						id="{{ cmpId }}-update-columns-checkbox"
						color="primary"
						[ngModel]="updateColumns.isChecked"
						(ngModelChange)="changeUpdateColumns($event)"
						[disabled]="updateColumns.isDisabled"
						[matTooltip]="updateColumns.tooltip"
						[matTooltipDisabled]="!updateColumns.isDisabled"
						matTooltipPosition="after"></mat-checkbox>
					<span
						i18n="
							@@expositions.publication.api.confirmation.popup.update.columns">
						columns
					</span>
				</div>
				<div class="app-exposition-confirmation-popup__checkbox">
					<mat-checkbox
						id="{{ cmpId }}-update-access-checkbox"
						color="primary"
						[ngModel]="updateAccess.isChecked"
						(ngModelChange)="changeUpdateAccess($event)"
						[disabled]="updateAccess.isDisabled"
						[matTooltip]="updateAccess.tooltip"
						[matTooltipDisabled]="!updateAccess.isDisabled"
						matTooltipPosition="after"></mat-checkbox>
					<span
						i18n="
							@@expositions.publication.api.confirmation.popup.update.access">
						access
					</span>
				</div>
			</div>
			<div
				*ngSwitchCase="ExpositionAction.Delete"
				class="app-exposition-confirmation-popup__delete">
				<span
					class="app-exposition-confirmation-popup__description"
					i18n="
						@@expositions.publication.api.confirmation.popup.delete.multiple.description">
					deletion request
				</span>

				<div class="app-exposition-confirmation-popup__delete-info">
					<mat-icon [svgIcon]="DcIcons.Warning"></mat-icon>
					<span
						class="app-exposition-confirmation-popup__message"
						i18n="
							@@expositions.publication.api.confirmation.popup.delete.multiple.info">
						deletion info
					</span>
				</div>
				<div class="app-exposition-confirmation-popup__checkbox">
					<mat-checkbox
						id="{{ cmpId }}-confirm-delete-checkbox"
						color="primary"
						[(ngModel)]="confirmDeletion"></mat-checkbox>
					<span
						i18n="
							@@expositions.publication.api.confirmation.popup.delete.confirm">
						confirm delete
					</span>
				</div>
			</div>
			<div
				*ngSwitchCase="ExpositionAction.DeleteAccess"
				class="app-exposition-confirmation-popup__delete">
				<div i18n="@@expositions.delete.access.popup.confirmationQuestion">
					Do you confirm the deletion of this element ?
				</div>
				<div class="app-exposition-confirmation-popup__info">
					<mat-icon [svgIcon]="DcIcons.Info"></mat-icon>
					<span
						class="app-exposition-confirmation-popup__message"
						i18n="@@expositions.delete.access.popup.informationMessage">
						The removal of this access will only be taken into account in the
						Publication once the API configuration has been updated
					</span>
				</div>

				<div class="app-exposition-confirmation-popup__checkbox">
					<mat-checkbox
						[color]="'primary'"
						[(ngModel)]="confirmDeletion"></mat-checkbox>
					<span i18n="@@expositions.delete.access.popup.confirmation">
						I confirm the deletion of this element
					</span>
				</div>
			</div>
		</ng-container>
	</div>

	<div modal-footer class="app-exposition-confirmation-popup__footer">
		<ng-container [ngSwitch]="vo.action">
			<button
				*ngSwitchCase="ExpositionAction.Publish"
				id="{{ cmpId }}-publish-btn"
				class="app-exposition-confirmation-popup__footer__btn"
				[color]="'primary'"
				(click)="launchAction()"
				mat-raised-button>
				<mat-icon [svgIcon]="DcIcons.Publish"></mat-icon>
				<span i18n="@@expositions.publication.api.action.publish">publish</span>
			</button>
			<button
				*ngSwitchCase="ExpositionAction.ClearData"
				id="{{ cmpId }}-clear-data-btn"
				class="app-exposition-confirmation-popup__footer__btn"
				[color]="'warn'"
				(click)="launchAction()"
				mat-raised-button>
				<mat-icon [svgIcon]="DcIcons.Delete"></mat-icon>
				<span i18n="@@expositions.publication.api.action.clear">clear</span>
			</button>
			<button
				*ngSwitchCase="ExpositionAction.UpdateData"
				id="{{ cmpId }}-update-data-btn"
				class="app-exposition-confirmation-popup__footer__btn"
				[color]="'primary'"
				(click)="launchAction()"
				mat-raised-button>
				<mat-icon [svgIcon]="DcIcons.Input"></mat-icon>
				<span
					i18n="
						@@expositions.publication.api.confirmation.popup.action.updateData">
					update data
				</span>
			</button>
			<button
				*ngSwitchCase="ExpositionAction.Update"
				id="exposition-confirm-update"
				class="app-exposition-confirmation-popup__footer__btn"
				[color]="'primary'"
				(click)="launchAction()"
				[disabled]="
					!(updateMetadata || updateAccess.isChecked || updateColumns.isChecked)
				"
				mat-raised-button>
				<mat-icon [svgIcon]="DcIcons.Update"></mat-icon>
				<span i18n="@@expositions.publication.api.action.update">update</span>
			</button>

			<button
				*ngSwitchCase="ExpositionAction.Activate"
				id="{{ cmpId }}-activate-btn"
				class="app-exposition-confirmation-popup__footer__btn"
				[color]="'primary'"
				(click)="launchAction()"
				mat-raised-button>
				<mat-icon [svgIcon]="DcIcons.Share"></mat-icon>
				<span i18n="@@expositions.publication.api.action.activate">
					activate
				</span>
			</button>
			<button
				*ngSwitchCase="ExpositionAction.Deactivate"
				id="{{ cmpId }}-deactivate-btn"
				class="app-exposition-confirmation-popup__footer__btn"
				[color]="'primary'"
				(click)="launchAction()"
				mat-raised-button>
				<mat-icon [svgIcon]="DcIcons.ShareOff"></mat-icon>
				<span i18n="@@expositions.publication.api.action.deactivate">
					deactivate
				</span>
			</button>
			<button
				*ngSwitchCase="ExpositionAction.Delete"
				id="{{ cmpId }}-delete-btn"
				class="app-exposition-confirmation-popup__footer__btn"
				[color]="'warn'"
				[disabled]="!confirmDeletion"
				(click)="launchAction()"
				mat-raised-button>
				<mat-icon [svgIcon]="DcIcons.Delete"></mat-icon>
				<span
					i18n="@@expositions.publication.api.confirmation.popup.delete.btn">
					delete
				</span>
			</button>
			<button
				*ngSwitchCase="ExpositionAction.DeleteAccess"
				id="{{ cmpId }}-delete-access-btn"
				class="app-exposition-confirmation-popup__footer__btn"
				[color]="'warn'"
				[disabled]="!confirmDeletion"
				(click)="launchAction()"
				mat-raised-button>
				<mat-icon [svgIcon]="DcIcons.Delete"></mat-icon>
				<span
					i18n="@@expositions.publication.api.confirmation.popup.delete.btn">
					delete
				</span>
			</button>
		</ng-container>
	</div>
</app-modal>
