import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DcValueAccessorComponent } from '@datachain/ui-sdk/common';
import { takeUntil, tap } from 'rxjs';

import { ValidationErrorKeys } from '../../../ui/form/validation-erros-keys';
import { ExportExcelConfigurationEntity } from './export-excel-configuration.entity';

enum ExportExcelFormControl {
	CellCoordinates = 'coordinates',
	KeepHeaders = 'keepHeaders',
}

@Component({
	selector: 'app-export-excel-configuration',
	templateUrl: './export-excel-configuration.component.html',
	styleUrls: ['./export-excel-configuration.component.scss'],
	providers: [
		DcValueAccessorComponent.generateAccessorToken(
			ExportExcelConfigurationComponent
		),
		DcValueAccessorComponent.generateValidatorToken(
			ExportExcelConfigurationComponent
		),
	],
})
export class ExportExcelConfigurationComponent extends DcValueAccessorComponent<
	ExportExcelConfigurationComponent,
	ExportExcelConfigurationEntity
> {
	private readonly EXCEL_CELL_COORDINATE = 'Sheet1!A1';
	public Appearance: MatFormFieldAppearance = 'outline';
	public ExportExcelFormControl = ExportExcelFormControl;
	public ValidationErrorKeys = ValidationErrorKeys;

	public static currentCmpEntity: ExportExcelConfigurationEntity | undefined =
		undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private static toEntity(formValues: any): ExportExcelConfigurationEntity {
		return ExportExcelConfigurationEntity.build({
			withHeaders: formValues[ExportExcelFormControl.KeepHeaders],
			cell: formValues[ExportExcelFormControl.CellCoordinates],
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public static saveCurrent(value: any): void {
		this.currentCmpEntity = ExportExcelConfigurationComponent.toEntity(value);
	}

	public constructor(private readonly fb: FormBuilder) {
		super();
		this.cmpId = 'export-excel-config';

		// TODO: need custom validator for cell coordinates
		this.form = this.fb.group({
			[ExportExcelFormControl.CellCoordinates]: [
				this.EXCEL_CELL_COORDINATE,
				Validators.required,
			],
			[ExportExcelFormControl.KeepHeaders]: [false],
		});

		this.form.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				tap((val) => {
					this.onChange(val);
					ExportExcelConfigurationComponent.saveCurrent(val);
					Object.keys(this.form.controls).forEach((k) => {
						const ctrl = this.form.get(k) as AbstractControl;
						ctrl.markAsTouched();
					});
				})
			)
			.subscribe();
	}

	public extract(): ExportExcelConfigurationEntity {
		const formValues = this.form.value;
		return ExportExcelConfigurationComponent.toEntity(formValues);
	}

	protected writeIntoForm(obj: ExportExcelConfigurationEntity): void {
		this.form.patchValue({
			[ExportExcelFormControl.CellCoordinates]: obj.cell,
			[ExportExcelFormControl.KeepHeaders]: obj.withHeaders,
		});
		ExportExcelConfigurationComponent.saveCurrent(this.form.value);
	}
}
