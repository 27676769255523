import {
	BaseSimulationType,
	SimulationBaseEntity,
} from './simulation-base.entity';

export enum SimulationAccessType {
	Open = 'open',
	Restricted = 'restricted',
	None = 'unknown',
}

interface ISimulationAccessEntity {
	id: string;
	label: string;
	type: SimulationAccessType;
}

const entityDefaults: ISimulationAccessEntity = {
	id: '',
	label: '',
	type: SimulationAccessType.None,
};

export class SimulationAccessEntity
	extends SimulationBaseEntity
	implements ISimulationAccessEntity
{
	protected simType: BaseSimulationType = 'Access';
	public id = entityDefaults.id;
	public label = entityDefaults.label;

	public type = entityDefaults.type;
	public constructor(params: Partial<ISimulationAccessEntity>) {
		super();
		this.id = params.id ?? entityDefaults.id;
		this.label = params.label ?? entityDefaults.label;
		this.type = params.type ?? entityDefaults.type;
	}
}
