import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineAll, DcBaseComponent } from '@datachain/ui-sdk/common';
import {
	AppColumnItemEntity,
	ButtonToggle,
	MultiSelectMenuOptionEntity,
	AvatarEntity,
	IBreadCrumbEntry,
} from '@datachain/ui-sdk/components';
import { Store } from '@ngrx/store';
import {
	BehaviorSubject,
	debounceTime,
	distinctUntilChanged,
	firstValueFrom,
	map,
	Observable,
	ReplaySubject,
	skip,
	takeUntil,
	tap,
	withLatestFrom,
} from 'rxjs';

import { ComponentIcons } from '../../../ui/app-components.icons';
import { DcIcons } from '../../../ui/app-dc.icons';
import { DomUtilsService } from '../../../ui/dom-utils.service';
import {
	SimulationResultType,
	SimulationType,
} from '../../domain/simulation.types';
import {
	computeUrisForConfig,
	fetchAvailableAccessForSimulation,
	fetchAvailableColumnsForSim,
	fetchAvailableGroupsForSimulation,
	fetchAvailableUsersForSimulation,
	fetchExpositionGridData,
	fetchRawJsonData,
	fetchRawXmlData,
	getCurrentExpositionSimulationMetadata,
	goToExpositionsListRoute,
	navigateToExposition,
	SimulatorSelector,
} from '../../store';
import {
	SimulationAccessEntity,
	SimulationAccessType,
} from './simulation-access.entity';
import { SimulationColumnsItemEntity } from './simulation-columns-item.entity';
import { SimulationColumnsListEntity } from './simulation-columns-list.entity';
import { SimulationExpositionMetadataEntity } from './simulation-exposition-metadata.entity';
import { SimulationGroupEntity } from './simulation-group.entity';
import {
	SimResultErr,
	SimulationResultsEntity,
} from './simulation-results.entity';
import { SimulationUrisEntity } from './simulation-uris.entity';
import { SimulationUserEntity } from './simulation-user.entity';

type SimulationTarget = 'Users' | 'Groups' | 'Access' | 'none';

type ResultTab = {
	label: string;
	index: number;
};

type EndPoints = { rest: boolean; odata: boolean };

enum RestDataTabs {
	Grid = 0,
	RawJSON = 1,
}

enum ODataTabs {
	Grid = 0,
	RawJSON = 1,
	RawXML = 2,
}

@Component({
	selector: 'app-exposition-simulator',
	templateUrl: './exposition-simulator.component.html',
	styleUrls: ['./exposition-simulator.component.scss'],
	viewProviders: [DomUtilsService],
	inputs: ['expositionId'],
})
export class ExpositionSimulatorComponent
	extends DcBaseComponent
	implements OnInit
{
	public readonly DcIcons = DcIcons;
	public readonly ComponentIcons = ComponentIcons;
	public readonly RestDataTabs = RestDataTabs;
	public readonly ODataTabs = ODataTabs;

	public paths: Array<IBreadCrumbEntry> = [];
	public originalMultiSelectPortalOffset = '';
	public simulateForToggleOptions: Array<ButtonToggle> = [
		{
			id: 1,
			label: $localize`:i18n=@@expositions.simulator.user:`,
			isSelected: false,
			icon: DcIcons.User,
			cb: this.getAvailableUsers.bind(this),
		},
		{
			id: 2,
			label: $localize`:i18n=@@expositions.simulator.group:`,
			isSelected: false,
			icon: DcIcons.Group,
			cb: this.getAvailableGroup.bind(this),
		},
		{
			id: 3,
			label: $localize`:i18n=@@expositions.simulator.access:`,
			isSelected: false,
			icon: DcIcons.Access,
			cb: this.getAvailableAccess.bind(this),
		},
	];

	public queryType: Array<ButtonToggle> = [
		{
			label: 'REST',
			id: 'query-type-rest',
			isSelected: true,
			cb: (): void => {
				this.simulationType$.next('rest');
				this.isCopyDataEnabled$.next(false);
				this.simulationResultType$.next('DATA');
			},
		},
		{
			label: 'OData',
			id: 'query-type-odata',
			isSelected: false,
			cb: (): void => {
				this.simulationType$.next('odata');
				this.isCopyDataEnabled$.next(false);
				this.simulationResultType$.next('DATA');
			},
		},
	];

	public restResultTabs: Array<ResultTab> = [
		{
			label: 'DataGrid',
			index: RestDataTabs.Grid,
		},
		{
			label: 'JSON',
			index: RestDataTabs.RawJSON,
		},
	];

	public odataResultTabs: Array<ResultTab> = [
		{
			label: 'DataGrid',
			index: ODataTabs.Grid,
		},
		{
			label: 'JSON',
			index: ODataTabs.RawJSON,
		},
		{
			label: 'XML',
			index: ODataTabs.RawXML,
		},
	];

	private readonly simulateFor$ = new BehaviorSubject<SimulationTarget>('none');
	private readonly simulateForOptions$ = new BehaviorSubject<
		Array<MultiSelectMenuOptionEntity>
	>([]);
	private readonly simulationTargets$ = new BehaviorSubject<
		Array<MultiSelectMenuOptionEntity>
	>([]);
	private readonly selectedColOpts$ = new BehaviorSubject<
		Array<AppColumnItemEntity>
	>([]);
	private readonly selectedSortColOpts$ = new BehaviorSubject<
		Array<AppColumnItemEntity>
	>([]);
	private readonly columnOptions$ = new BehaviorSubject<
		Array<MultiSelectMenuOptionEntity>
	>([]);
	private readonly sortColumnOptions$ = new BehaviorSubject<
		Array<MultiSelectMenuOptionEntity>
	>([]);

	private readonly expositionColumnsList$ =
		new ReplaySubject<SimulationColumnsListEntity>(1);

	private readonly simulationType$ = new BehaviorSubject<SimulationType>(
		'rest'
	);
	private readonly simulationResultType$ =
		new BehaviorSubject<SimulationResultType>('DATA');

	private currentExposition$ = new Observable<number>();

	public readonly endPointsOverlay$ = new BehaviorSubject<EndPoints>({
		rest: false,
		odata: false,
	});

	public readonly filterParamSubject = new BehaviorSubject<string>('');
	public readonly hitsPerPageParamSubject = new BehaviorSubject<number>(100);
	public readonly pageCountParamSubject = new BehaviorSubject<number>(1);
	public readonly manualTriggerSubject = new BehaviorSubject<boolean>(true);
	public readonly isCopyDataEnabled$ = new BehaviorSubject<boolean>(true);

	public vo$!: Observable<{
		loadingColumns: boolean;
		isCopyDataEnabled: boolean;
		queryType: SimulationType;
		simulationTypeOptions: Array<MultiSelectMenuOptionEntity>;
		simulationFor: SimulationTarget;
		simulationTargets: Array<MultiSelectMenuOptionEntity>;
		availableSimCols: SimulationColumnsListEntity;
		columnOptions: Array<MultiSelectMenuOptionEntity>;
		sortColumnOptions: Array<MultiSelectMenuOptionEntity>;
		selectedColumns: Array<AppColumnItemEntity>;
		selectedSortColumns: Array<AppColumnItemEntity>;
		simulationData: SimulationResultsEntity | null;
		simulationMetadata: SimulationExpositionMetadataEntity;
		simulationUris: SimulationUrisEntity;
		simulationError: SimResultErr | null;
	}>;

	public constructor(
		private readonly store: Store,
		private readonly simulatorSelector: SimulatorSelector,
		private readonly snackBar: MatSnackBar,
		private readonly domUtils: DomUtilsService
	) {
		super();
		this.cmpId = 'app-exposition-simulator';
	}

	public ngOnInit(): void {
		this.currentExposition$ = this.toObservable<number>('expositionId');
		super.ngOnInit();
		this.triggerInit();
		this.listenToEvents();
		this.handleSubscriptions();

		this.vo$ = combineAll({
			loadingColumns: this.simulatorSelector.isLoadingColumns$(),
			isCopyDataEnabled: this.isCopyDataEnabled$,
			queryType: this.simulationType$,
			simulationFor: this.simulateFor$,
			simulationTypeOptions: this.simulateForOptions$,
			simulationTargets: this.simulationTargets$,
			availableSimCols: this.expositionColumnsList$,
			selectedColumns: this.selectedColOpts$,
			selectedSortColumns: this.selectedSortColOpts$,
			columnOptions: this.columnOptions$,
			sortColumnOptions: this.sortColumnOptions$.pipe(
				map((l) => l.filter((entry) => !entry.isList))
			),
			simulationData: this.simulatorSelector.getSimulationData$(),
			simulationMetadata: this.simulatorSelector.getSimulationMetaData$(),
			simulationUris: this.simulatorSelector.getSimulationUris$(),
			simulationError: this.simulatorSelector.getSimulationError$(),
		});
	}

	public async goToExposition(): Promise<void> {
		const expositionId = await firstValueFrom(this.currentExposition$);
		this.store.dispatch(
			navigateToExposition({
				expositionId,
			})
		);
	}

	public async updateSelection(
		selection: Array<string | number>
	): Promise<void> {
		const currentOptions = await firstValueFrom(this.simulateForOptions$);
		const filtered = currentOptions.filter((opt) => selection.includes(opt.id));
		this.simulationTargets$.next(filtered);
	}

	public async removeSelected(
		target: MultiSelectMenuOptionEntity
	): Promise<void> {
		const current = await firstValueFrom(this.simulationTargets$);
		const updated = current.filter((c) => c.id !== target.id);
		this.simulationTargets$.next(updated);
	}

	public async selectedColumns(ids: Array<string | number>): Promise<void> {
		const availableColumns = await firstValueFrom(this.expositionColumnsList$);
		const columns = availableColumns.elements.toList().toArray();

		// select column entities from available columns based on selected ids
		let selected = columns.filter((c) => ids.includes(c.id));

		// retrieve previously selected items
		const previouslySelectedOptions = (
			await firstValueFrom(this.selectedColOpts$)
		).map((it) => it.id);
		// retrieve previously selected columns from previously selected options
		const previouslySelectedColumns = columns.filter((c) =>
			previouslySelectedOptions.includes(c.id)
		);
		selected = selected.concat(previouslySelectedColumns);

		// reconstructs selected options then update
		const all = selected.map((s) =>
			AppColumnItemEntity.build({
				id: s.id,
				label: s.alias,
				type: s.type,
				isList: s.isList,
			})
		);
		this.selectedColOpts$.next(all);

		// remove selected from available options and update
		const currentOptions = await firstValueFrom(this.columnOptions$);
		const updated = currentOptions.filter((c) => !ids.includes(c.id));
		this.columnOptions$.next(updated);
	}

	public async columnRemoved(item: AppColumnItemEntity): Promise<void> {
		const columns = (await firstValueFrom(this.expositionColumnsList$)).elements
			.toList()
			.toArray();
		const configured = (await firstValueFrom(this.selectedColOpts$)).map(
			(it) => it.id
		);
		const notSelected = columns.filter((c) => !configured.includes(c.id));
		this.manualTriggerSubject.next(true);
		this.columnOptions$.next(
			notSelected.map<MultiSelectMenuOptionEntity>((item) =>
				MultiSelectMenuOptionEntity.build({
					id: item.id,
					label: item.alias,
					type: item.type,
					isList: item.isList,
				})
			)
		);
	}

	public async sortColumnRemoved(item: AppColumnItemEntity): Promise<void> {
		const columns = (await firstValueFrom(this.expositionColumnsList$)).elements
			.toList()
			.toArray();
		const configured = (await firstValueFrom(this.selectedSortColOpts$)).map(
			(it) => it.id
		);
		const notSelected = columns.filter((c) => !configured.includes(c.id));
		this.manualTriggerSubject.next(true);
		this.sortColumnOptions$.next(
			notSelected.map<MultiSelectMenuOptionEntity>((item) =>
				MultiSelectMenuOptionEntity.build({
					id: item.id,
					label: item.alias,
					type: item.type,
					isList: item.isList,
				})
			)
		);
	}

	public async removeAllSelectedColumns(
		val: 'columns' | 'sort'
	): Promise<void> {
		const columns = (await firstValueFrom(this.expositionColumnsList$)).elements
			.toList()
			.toArray();
		if (val === 'columns') {
			this.selectedColOpts$.next([]);
			this.columnOptions$.next(
				columns.map<MultiSelectMenuOptionEntity>((item) =>
					MultiSelectMenuOptionEntity.build({
						id: item.id,
						label: item.alias,
						type: item.type,
						isList: item.isList,
					})
				)
			);
		} else {
			this.selectedSortColOpts$.next([]);
			this.sortColumnOptions$.next(
				columns.map<MultiSelectMenuOptionEntity>((item) =>
					MultiSelectMenuOptionEntity.build({
						id: item.id,
						label: item.alias,
						type: item.type,
						isList: item.isList,
					})
				)
			);
		}
	}

	public async selectedSortColumns(ids: Array<string | number>): Promise<void> {
		const columns = await firstValueFrom(this.expositionColumnsList$);
		const columns_ = columns.elements.toList().toArray();
		let selected = columns_.filter((c) => ids.includes(c.id));
		const configured = (await firstValueFrom(this.selectedSortColOpts$)).map(
			(it) => it.id
		);
		const previouslySelected = columns_.filter((c) =>
			configured.includes(c.id)
		);
		selected = selected.concat(previouslySelected);
		const all = selected.map((s) =>
			AppColumnItemEntity.build({
				id: s.id,
				label: s.alias,
				type: s.type,
				isList: s.isList,
				sort: 'ASC',
			})
		);
		this.selectedSortColOpts$.next(all);
		const currentOptions = await firstValueFrom(this.sortColumnOptions$);
		const updated = currentOptions.filter((c) => !ids.includes(c.id));
		this.sortColumnOptions$.next(updated);
	}

	public async computeAllowedColumns(): Promise<void> {
		const expositionId = await firstValueFrom(this.currentExposition$);
		const simulationTargets = await firstValueFrom(this.simulationTargets$);
		const simType = await firstValueFrom(this.simulateFor$);
		const payload = this.prepareTargetsPayload(simType, simulationTargets);
		this.selectedColOpts$.next([]);
		this.selectedSortColOpts$.next([]);
		this.manualTriggerSubject.next(true);
		this.store.dispatch(
			fetchAvailableColumnsForSim({
				payload,
				expositionId,
			})
		);
	}

	public columnOrderModified($event: Array<AppColumnItemEntity>): void {
		this.selectedColOpts$.next($event);
	}

	public sortColumnOrderModified($event: Array<AppColumnItemEntity>): void {
		this.selectedSortColOpts$.next($event);
	}

	public sortOrderModified($event: Array<AppColumnItemEntity>): void {
		this.selectedSortColOpts$.next($event);
	}

	public async simulate(): Promise<void> {
		const simulationType = await firstValueFrom(this.simulationType$);
		const simulationResultType = await firstValueFrom(
			this.simulationResultType$
		);
		if (simulationType === 'rest') {
			return simulationResultType === 'DATA'
				? this.handleDataTabsIdxChn(RestDataTabs.Grid, simulationType)
				: this.handleDataTabsIdxChn(RestDataTabs.RawJSON, simulationType);
		}
		if (simulationResultType === 'DATA') {
			return this.handleDataTabsIdxChn(ODataTabs.Grid, simulationType);
		} else if (simulationResultType === 'JSON') {
			return this.handleDataTabsIdxChn(ODataTabs.RawJSON, simulationType);
		} else {
			return this.handleDataTabsIdxChn(ODataTabs.RawXML, simulationType);
		}
	}

	public triggerFilterParamChange($event: string): void {
		this.filterParamSubject.next($event);
	}

	public removeFilter(): void {
		this.filterParamSubject.next('');
	}

	public triggerPageCountParamParamChange($event: number): void {
		this.pageCountParamSubject.next($event);
	}

	public triggerHitsPerPageParamChange($event: number): void {
		this.hitsPerPageParamSubject.next($event);
	}

	public openSnackBar(): void {
		this.snackBar.open($localize`:i18n=@@general.copied:`, '', {
			duration: 2000,
		});
	}

	public async openEndpointMetadataPopover(
		event: Event,
		val: 'REST' | 'ODATA'
	): Promise<void> {
		event.stopPropagation();
		const current = await firstValueFrom(this.endPointsOverlay$);
		this.endPointsOverlay$.next({
			rest: val === 'REST' ? true : current.rest,
			odata: val === 'ODATA' ? true : current.odata,
		});
	}

	public closeEndpointMetadataPopover(): void {
		this.endPointsOverlay$.next({
			rest: false,
			odata: false,
		});
	}

	public async handleDataTabsIdxChn(
		tabIdx: number,
		simulationType: SimulationType
	): Promise<void> {
		const expositionId = await firstValueFrom(this.currentExposition$);
		const availableColumns = await firstValueFrom(this.expositionColumnsList$);
		const selectedColumnsOpts = await firstValueFrom(this.selectedColOpts$);
		const selectedSortColumnsOpts = await firstValueFrom(
			this.selectedSortColOpts$
		);
		const { selectedCols, sortCols } = this.prepareColumnsPayload(
			selectedColumnsOpts,
			selectedSortColumnsOpts,
			availableColumns
		);
		const simulationTargets = await firstValueFrom(this.simulationTargets$);
		const targets = await firstValueFrom(this.simulateFor$);
		const accessConfig = this.prepareTargetsPayload(targets, simulationTargets);

		const filter = await firstValueFrom(this.filterParamSubject);
		const hitsPerPage = await firstValueFrom(this.hitsPerPageParamSubject);
		const nbOfPages = await firstValueFrom(this.pageCountParamSubject);

		if (simulationType === 'rest' && tabIdx === RestDataTabs.Grid) {
			this.isCopyDataEnabled$.next(false);
			this.simulationResultType$.next('DATA');
			return this.store.dispatch(
				fetchExpositionGridData({
					expositionId,
					payload: {
						filter,
						hitsPerPage,
						nbOfPages,
						accessConfig,
						sortCols,
						selectedCols,
					},
				})
			);
		}

		if (simulationType === 'rest' && tabIdx === RestDataTabs.RawJSON) {
			this.isCopyDataEnabled$.next(true);
			this.simulationResultType$.next('JSON');
			return this.store.dispatch(
				fetchRawJsonData({
					expositionId,
					simulationType,
					payload: {
						filter,
						hitsPerPage,
						nbOfPages,
						accessConfig,
						sortCols,
						selectedCols,
					},
				})
			);
		}

		if (simulationType === 'odata' && tabIdx === ODataTabs.Grid) {
			this.isCopyDataEnabled$.next(false);
			this.simulationResultType$.next('DATA');
			return this.store.dispatch(
				fetchExpositionGridData({
					expositionId,
					payload: {
						filter,
						hitsPerPage,
						nbOfPages,
						accessConfig,
						sortCols,
						selectedCols,
					},
				})
			);
		}

		if (simulationType === 'odata' && tabIdx === ODataTabs.RawJSON) {
			this.isCopyDataEnabled$.next(true);
			this.simulationResultType$.next('JSON');
			return this.store.dispatch(
				fetchRawJsonData({
					expositionId,
					simulationType,
					payload: {
						filter,
						hitsPerPage,
						nbOfPages,
						accessConfig,
						sortCols,
						selectedCols,
					},
				})
			);
		}

		if (simulationType === 'odata' && tabIdx === ODataTabs.RawXML) {
			this.isCopyDataEnabled$.next(true);
			this.simulationResultType$.next('XML');
			return this.store.dispatch(
				fetchRawXmlData({
					expositionId,
					payload: {
						filter,
						hitsPerPage,
						nbOfPages,
						accessConfig,
						sortCols,
						selectedCols,
					},
				})
			);
		}
	}

	private async triggerInit(): Promise<void> {
		const expositionId = await firstValueFrom(this.currentExposition$);
		this.store.dispatch(
			getCurrentExpositionSimulationMetadata({
				expositionId,
			})
		);
	}

	private handleSubscriptions(): void {
		combineAll({
			cols: this.simulatorSelector.getSimulationColumns$(),
			metadata: this.simulatorSelector.getSimulationMetaData$(),
		})
			.pipe(
				takeUntil(this.onDestroy$),
				tap(({ cols, metadata }) => {
					this.expositionColumnsList$.next(cols);
					this.paths = [
						{
							label: $localize`:i18n=@@expositions.breadcrumb.list:`,
							isActive: true,
							tooltip: $localize`:i18n=@@expositions.breadcrumb.list.tooltip:`,
							callback: (): void => {
								this.store.dispatch(goToExpositionsListRoute());
							},
						},
						{
							label: metadata.linkedExpositionLabel,
							isActive: true,
							callback: this.goToExposition.bind(this),
						},
						{
							label: $localize`:i18n=@@expositions.simulator.page:`,
							isActive: false,
							callback: (): void => {},
						},
					];
				})
			)
			.subscribe();

		this.expositionColumnsList$
			.pipe(
				takeUntil(this.onDestroy$),
				map((l) =>
					l.elements
						.toList()
						.toArray()
						.map<MultiSelectMenuOptionEntity>((item) =>
							MultiSelectMenuOptionEntity.build({
								id: item.id,
								label: item.alias,
								type: item.type,
								isList: item.isList,
							})
						)
				),
				tap((l) => {
					this.columnOptions$.next(l);
					this.sortColumnOptions$.next(l);
				})
			)
			.subscribe();
	}

	private prepareTargetsPayload(
		type: SimulationTarget,
		targets: Array<MultiSelectMenuOptionEntity>
	):
		| SimulationUserEntity
		| Array<SimulationGroupEntity>
		| Array<SimulationAccessEntity> {
		if (type === 'Users') {
			return new SimulationUserEntity({
				id: targets[0].id as number,
			});
		} else if (type === 'Groups') {
			return targets.map(
				(it) =>
					new SimulationGroupEntity({
						id: it.id as number,
					})
			);
		}
		return targets.map(
			(it) =>
				new SimulationAccessEntity({
					id: it.id as string,
				})
		);
	}

	private prepareColumnsPayload(
		selectedColsOpts: Array<AppColumnItemEntity>,
		sortColsOpts: Array<AppColumnItemEntity>,
		columnsList: SimulationColumnsListEntity
	): {
		selectedCols: Array<SimulationColumnsItemEntity>;
		sortCols: Array<SimulationColumnsItemEntity>;
	} {
		return {
			selectedCols: selectedColsOpts.map((it) => {
				const col = columnsList
					.getElements()
					.get(`${it.id}`) as SimulationColumnsItemEntity;
				return SimulationColumnsItemEntity.build(col);
			}),
			sortCols: sortColsOpts.map((it) => {
				const col = columnsList
					.getElements()
					.get(`${it.id}`) as SimulationColumnsItemEntity;
				return SimulationColumnsItemEntity.build({
					...col,
					sortOrder:
						it.sort === 'ASC' ? 'asc' : it.sort === 'DESC' ? 'desc' : '',
				});
			}),
		};
	}

	private updateView(reset: boolean): void {
		if (!reset) {
			this.domUtils.setProperty(
				document.documentElement,
				'--multi-select-menu-y-translate',
				this.originalMultiSelectPortalOffset
			);
			return;
		}

		const fullHeight = this.domUtils.getFullHeight(
			document.querySelector('.selected-options')
		);
		this.domUtils.setProperty(
			document.documentElement,
			'--multi-select-menu-y-translate',
			this.domUtils.computeProp(this.originalMultiSelectPortalOffset)
		);
		const updatedStyle = this.domUtils.computeProp(
			this.originalMultiSelectPortalOffset,
			fullHeight
		);
		if (updatedStyle === '') {
			return;
		}
		this.domUtils.setProperty(
			document.documentElement,
			'--multi-select-menu-y-translate',
			updatedStyle
		);
	}

	private async getAvailableUsers(): Promise<void> {
		const currentSelectedType = await firstValueFrom(this.simulateFor$);
		if (currentSelectedType === 'Users') {
			return;
		}
		this.simulateFor$.next('Users');
		const expositionId = await firstValueFrom(this.currentExposition$);
		this.store.dispatch(
			fetchAvailableUsersForSimulation({
				payload: expositionId,
			})
		);
	}

	private async getAvailableGroup(): Promise<void> {
		const currentSelectedType = await firstValueFrom(this.simulateFor$);
		if (currentSelectedType === 'Groups') {
			return;
		}
		this.simulateFor$.next('Groups');
		const expositionId = await firstValueFrom(this.currentExposition$);
		this.store.dispatch(
			fetchAvailableGroupsForSimulation({
				payload: expositionId,
			})
		);
	}

	private async getAvailableAccess(): Promise<void> {
		const currentSelectedType = await firstValueFrom(this.simulateFor$);
		if (currentSelectedType === 'Access') {
			return;
		}
		this.simulateFor$.next('Access');
		const expositionId = await firstValueFrom(this.currentExposition$);
		this.store.dispatch(
			fetchAvailableAccessForSimulation({
				payload: expositionId,
			})
		);
	}

	private listenToEvents(): void {
		this.simulatorSelector
			.getSimulationUsers$()
			.pipe(
				takeUntil(this.onDestroy$),
				withLatestFrom(this.simulateFor$),
				tap(([users, type]) => {
					if (type === 'Users') {
						const users_ = this.adaptUiUsers(users);
						this.simulationTargets$.next([]);
						this.simulateForOptions$.next(users_);
					}
				})
			)
			.subscribe();

		this.simulatorSelector
			.getSimulationGroups$()
			.pipe(
				takeUntil(this.onDestroy$),
				withLatestFrom(this.simulateFor$),
				tap(([groups, type]) => {
					if (type === 'Groups') {
						const groups_ = this.adaptUiGroups(groups);
						this.simulationTargets$.next([]);
						this.simulateForOptions$.next(groups_);
					}
				})
			)
			.subscribe();

		this.simulatorSelector
			.getSimulationAccess$()
			.pipe(
				takeUntil(this.onDestroy$),
				withLatestFrom(this.simulateFor$),
				tap(([access, type]) => {
					if (type === 'Access') {
						const access_ = this.adaptUiAccess(access);
						this.simulationTargets$.next([]);
						this.simulateForOptions$.next(access_);
					}
				})
			)
			.subscribe();

		combineAll({
			manualTrigger: this.manualTriggerSubject,
			filter: this.filterParamSubject.pipe(
				distinctUntilChanged(),
				map((val) => (!val ? '' : val))
			),
			pageCount: this.pageCountParamSubject.pipe(
				distinctUntilChanged(),
				map((val) => (!val || val < 1 ? undefined : val))
			),
			hitsPerPage: this.hitsPerPageParamSubject.pipe(
				distinctUntilChanged(),
				map((val) => (!val || val < 1 ? undefined : val))
			),
			selectedCols: this.selectedColOpts$,
			selectedSortCols: this.selectedSortColOpts$,
		})
			.pipe(
				takeUntil(this.onDestroy$),
				debounceTime(500),
				skip(1),
				withLatestFrom(
					this.simulationType$,
					this.currentExposition$,
					this.expositionColumnsList$,
					(queryParams, simulationType, expositionId, availableColumns) => ({
						queryParams,
						simulationType,
						expositionId,
						columnsConfig: this.prepareColumnsPayload(
							queryParams.selectedCols,
							queryParams.selectedSortCols,
							availableColumns
						),
					})
				),
				tap(({ queryParams, simulationType, expositionId, columnsConfig }) =>
					this.store.dispatch(
						computeUrisForConfig({
							expositionId,
							payload: {
								filter: queryParams.filter,
								hitsPerPage: queryParams.hitsPerPage,
								nbOfPages: queryParams.pageCount,
								selectedCols: columnsConfig.selectedCols,
								sortCols: columnsConfig.sortCols,
								simulationType,
							},
						})
					)
				)
			)
			.subscribe();
	}

	private adaptUiUsers(
		users: Array<SimulationUserEntity>
	): Array<MultiSelectMenuOptionEntity> {
		return users.map<MultiSelectMenuOptionEntity>((item) =>
			MultiSelectMenuOptionEntity.build({
				id: item.id,
				label: `${item.fullName} | ${item.email}`,
				avatar: AvatarEntity.build(item.fullName),
			})
		);
	}

	private adaptUiGroups(
		groups: Array<SimulationGroupEntity>
	): Array<MultiSelectMenuOptionEntity> {
		return groups.map<MultiSelectMenuOptionEntity>((item) =>
			MultiSelectMenuOptionEntity.build({
				id: item.id,
				label: item.name,
				avatar: AvatarEntity.build(item.name),
			})
		);
	}

	private adaptUiAccess(
		access: Array<SimulationAccessEntity>
	): Array<MultiSelectMenuOptionEntity> {
		return access.map<MultiSelectMenuOptionEntity>((item) =>
			MultiSelectMenuOptionEntity.build({
				id: item.id,
				label: item.label,
				trailingIcons:
					item.type === SimulationAccessType.Open
						? [DcIcons.LockOpen]
						: [DcIcons.LockClose],
			})
		);
	}
}
