import { Injectable } from '@angular/core';
import { createSelector, select, Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { SimulationAccessEntity } from '../components/exposition-simulator/simulation-access.entity';
import { SimulationColumnsListEntity } from '../components/exposition-simulator/simulation-columns-list.entity';
import { SimulationExpositionMetadataEntity } from '../components/exposition-simulator/simulation-exposition-metadata.entity';
import { SimulationGroupEntity } from '../components/exposition-simulator/simulation-group.entity';
import {
	SimResultErr,
	SimulationResultsEntity,
} from '../components/exposition-simulator/simulation-results.entity';
import { SimulationUrisEntity } from '../components/exposition-simulator/simulation-uris.entity';
import { SimulationUserEntity } from '../components/exposition-simulator/simulation-user.entity';
import { ExpositionRootState, featureSelector } from './feature.store';
import { SimulatorSliceKey, SimulatorSliceState } from './simulator.state';

const baseSelector = createSelector(
	featureSelector,
	(state) => state[SimulatorSliceKey]
);

const simulationUsers = createSelector(baseSelector, (state) =>
	state.get('simulationUsers')
);
const simulationGroups = createSelector(baseSelector, (state) =>
	state.get('simulationGroups')
);
const simulationAccess = createSelector(baseSelector, (state) =>
	state.get('simulationAccess')
);
const simulationColumns = createSelector(baseSelector, (state) =>
	state.get('simulationColumns')
);
const simulationData = createSelector(baseSelector, (state) =>
	state.get('simulationData')
);
const simulationMetadata = createSelector(baseSelector, (state) =>
	state.get('simulationMetadata')
);

@Injectable()
export class SimulatorSelector {
	public constructor(private readonly store: Store<ExpositionRootState>) {}

	private get state(): Observable<SimulatorSliceState> {
		return this.store.pipe(select(baseSelector));
	}

	public isLoadingColumns$(): Observable<boolean> {
		return this.state.pipe(map((state) => state.get('loadingColumns')));
	}

	public getSimulationUsers$(): Observable<Array<SimulationUserEntity>> {
		return this.store.pipe(select(simulationUsers));
	}

	public getSimulationGroups$(): Observable<Array<SimulationGroupEntity>> {
		return this.store.pipe(select(simulationGroups));
	}

	public getSimulationAccess$(): Observable<Array<SimulationAccessEntity>> {
		return this.store.pipe(select(simulationAccess));
	}

	public getSimulationColumns$(): Observable<SimulationColumnsListEntity> {
		return this.store.pipe(select(simulationColumns));
	}

	public getSimulationData$(): Observable<SimulationResultsEntity | null> {
		return this.store.pipe(select(simulationData));
	}

	public getSimulationMetaData$(): Observable<SimulationExpositionMetadataEntity> {
		return this.store.pipe(select(simulationMetadata));
	}

	public getSimulationUris$(): Observable<SimulationUrisEntity> {
		return this.state.pipe(map((state) => state.get('simulationUris')));
	}

	public getSimulationError$(): Observable<SimResultErr | null> {
		return this.state.pipe(map((state) => state.get('simulationError')));
	}
}
