import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorsInterceptor } from '@datachain/ui-sdk/common';

import { NgCommService } from '../ajs-ng-communication.service';

@Injectable()
export class DcErrorsInterceptor extends ErrorsInterceptor {
	public constructor(private readonly ngCommService: NgCommService) {
		super();
	}

	protected checkResponseStatus(response: HttpErrorResponse): void {
		const regexClientError = /^4[0-9][0-9]$/;
		if (regexClientError.test(response.status.toString())) {
			console.error('check permissions: ', response);
			let msg = 'An Error has occured, no specific message';
			if (response.error.message) {
				msg = response.error.message;
			} else if (response.error.title) {
				msg = response.error.title;
			} else {
				msg = 'An Error has occured, no specific message';
			}
			this.ngCommService.notifyOnError(msg);
			return;
		}

		const regexServerError = /^5[0-9][0-9]$/;
		if (regexServerError.test(response.status.toString())) {
			console.error('server error: ', response);
			this.ngCommService.notifyOnError(response.error.message);
			return;
		}
	}

	private isRedirect(response: HttpResponse<unknown>): boolean {
		const regexRedirection = /^3[0-9][0-9]$/;
		return regexRedirection.test(response.status.toString());
	}
}
