import { Record } from 'immutable';

import { SimulationAccessEntity } from '../components/exposition-simulator/simulation-access.entity';
import { SimulationColumnsListEntity } from '../components/exposition-simulator/simulation-columns-list.entity';
import { SimulationExpositionMetadataEntity } from '../components/exposition-simulator/simulation-exposition-metadata.entity';
import { SimulationGroupEntity } from '../components/exposition-simulator/simulation-group.entity';
import {
	SimResultErr,
	SimulationResultsEntity,
} from '../components/exposition-simulator/simulation-results.entity';
import { SimulationUrisEntity } from '../components/exposition-simulator/simulation-uris.entity';
import { SimulationUserEntity } from '../components/exposition-simulator/simulation-user.entity';

export const SimulatorSliceKey = 'simulator';

interface IExpositionSimulatorSliceState {
	loadingColumns: boolean;
	simulationUsers: Array<SimulationUserEntity>;
	simulationGroups: Array<SimulationGroupEntity>;
	simulationAccess: Array<SimulationAccessEntity>;
	simulationColumns: SimulationColumnsListEntity;
	simulationData: SimulationResultsEntity | null;
	simulationMetadata: SimulationExpositionMetadataEntity;
	simulationUris: SimulationUrisEntity;
	simulationError: SimResultErr | null;
}

const simulatorDefaults: IExpositionSimulatorSliceState = {
	loadingColumns: false,
	simulationUsers: [],
	simulationGroups: [],
	simulationAccess: [],
	simulationData: null,
	simulationMetadata: SimulationExpositionMetadataEntity.build({}),
	simulationColumns: SimulationColumnsListEntity.build([]),
	simulationUris: SimulationUrisEntity.build({}),
	simulationError: null,
};

export class SimulatorSliceState extends Record<IExpositionSimulatorSliceState>(
	simulatorDefaults
) {
	public setLoadingColumns(val: boolean): SimulatorSliceState {
		return this.set('loadingColumns', val);
	}

	public setSimulationUsers(
		users: Array<SimulationUserEntity>
	): SimulatorSliceState {
		return this.set('simulationUsers', users);
	}

	public setSimulationGroups(
		groups: Array<SimulationGroupEntity>
	): SimulatorSliceState {
		return this.set('simulationGroups', groups);
	}

	public setSimulationAccess(
		access: Array<SimulationAccessEntity>
	): SimulatorSliceState {
		return this.set('simulationAccess', access);
	}

	public setSimulationColumns(
		cols: SimulationColumnsListEntity
	): SimulatorSliceState {
		return this.set('simulationColumns', cols);
	}

	public resetSimulation(): SimulatorSliceState {
		return this.set('simulationColumns', SimulationColumnsListEntity.build([]))
			.set('simulationData', null)
			.set('simulationUsers', [])
			.set('simulationAccess', [])
			.set('simulationGroups', [])
			.set('simulationError', null)
			.set('simulationUris', SimulationUrisEntity.build({}));
	}

	public setSimulationGridData(
		val: SimulationResultsEntity | null
	): SimulatorSliceState {
		return this.set('simulationData', val);
	}

	public setSimulationErrors(val: SimResultErr | null): SimulatorSliceState {
		return this.set('simulationError', val);
	}

	public setExpositionSimulationMetadata(
		val: SimulationExpositionMetadataEntity
	): SimulatorSliceState {
		return this.set('simulationMetadata', val);
	}

	public setExpositionSimulationUris(
		val: SimulationUrisEntity
	): SimulatorSliceState {
		return this.set('simulationUris', val);
	}
}
