import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { combineAll, DcBaseComponent } from '@datachain/ui-sdk/common';
import { Map } from 'immutable';
import { Observable, takeUntil, tap } from 'rxjs';

import { DcIcons } from '../../../ui/app-dc.icons';
import { ExpositionConsumerMappingEntity } from '../../domain/exposition-consumer-mapping.entity';
import { ExpositionConsumerEntity } from '../../domain/exposition-consumer.entity';

@Component({
	selector: 'app-exposition-consumers-mapping',
	templateUrl: 'exposition-consumers-mapping.component.html',
	inputs: ['availableConsumers', 'consumersToMap'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpositionConsumersMappingComponent extends DcBaseComponent {
	public readonly DcIcons = DcIcons;
	private mapOfConsumers = Map<number, ExpositionConsumerEntity>();

	@Output()
	public assignTargetConsumer = new EventEmitter<ExpositionConsumerMappingEntity>();

	@Output()
	public reloadUsers = new EventEmitter<void>();

	public vo$: Observable<{
		consumersToMap: Array<ExpositionConsumerMappingEntity>;
		availableConsumers: Array<ExpositionConsumerEntity>;
	}>;

	public constructor() {
		super();
		this.cmpId = 'app-consumers-mapping';

		const availableConsumersSubject =
			this.toObservable<Array<ExpositionConsumerEntity>>('availableConsumers');

		availableConsumersSubject
			.pipe(
				takeUntil(this.onDestroy$),
				tap((arr) => {
					const tupleOfConsumers = arr.reduce<
						Array<[number, ExpositionConsumerEntity]>
					>((acc, curr) => {
						acc.push([curr.id, curr]);
						return acc;
					}, []);
					this.mapOfConsumers = Map(tupleOfConsumers);
				})
			)
			.subscribe();

		this.vo$ = combineAll({
			availableConsumers: availableConsumersSubject,
			consumersToMap:
				this.toObservable<Array<ExpositionConsumerMappingEntity>>(
					'consumersToMap'
				),
		});
	}

	public triggerReloadUsers(): void {
		this.reloadUsers.emit();
	}

	public triggerAssignTargetConsumer(
		$event: MatSelectChange,
		unmappedConsumer: ExpositionConsumerMappingEntity
	): void {
		const found = this.mapOfConsumers.get($event.value as number);
		if (!found) {
			return;
		}
		const updated = unmappedConsumer.mapTo(found.id, found.isGroup);
		this.assignTargetConsumer.emit(updated);
	}
}
