import { DoBootstrap, Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { AngularJSToken } from '@datachain/ui-sdk/common';
import { AppModalService, AppIcons } from '@datachain/ui-sdk/components';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';
import { angularJSProvider } from './ajs-init.provider';
import { AjsInjectorService } from './ajs-injector.service';
import { AjsScopeService } from './ajs-scope.service';
import { AjsStateService } from './ajs-state.service';
import { CoreModule } from './core/core.module';
import {
	AppEffects,
	AppSelector,
	CORE,
	coreReducer,
	IAppRootState,
} from './core/store';
import { ExportTemplateModule } from './export-template/export-template.module';
import { ExpositionsModule } from './expositions/expositions.module';
import { FormulaModule } from './formula/formula.module';
import { OrphanProjectModule } from './orphan-project/orphan-project.module';
import { RequestersModule } from './requesters';
import {
	ActionIconsInjectable,
	DcIconsInjectable,
	GdIconsInjectable,
	HdIconsInjectable,
	UiComponentIconsInjectable,
} from './ui/ui.module';

const miscellaneousModules = [ExportTemplateModule, FormulaModule];
const administrationModules = [OrphanProjectModule];

const handleModules = [ExpositionsModule];

export const rootReducer: ActionReducerMap<IAppRootState> = {
	[CORE]: coreReducer,
};

@NgModule({
	imports: [
		...miscellaneousModules,
		...administrationModules,
		...handleModules,
		BrowserModule,
		UpgradeModule,
		CoreModule,
		RequestersModule,
		StoreModule.forRoot(rootReducer),
		EffectsModule.forRoot([AppEffects]),
		StoreDevtoolsModule.instrument({
			maxAge: 20,
			logOnly: environment.production,
		}),
	],
	providers: [
		angularJSProvider,
		AjsInjectorService,
		AjsScopeService,
		AjsStateService,
		AppSelector,
		AppModalService,
	],
	declarations: [],
})
export class AppModule implements DoBootstrap {
	public constructor(
		@Inject(AngularJSToken) angular: any,
		@Inject(DcIconsInjectable) private readonly dcIcons: AppIcons,
		@Inject(GdIconsInjectable) private readonly gdIcons: AppIcons,
		@Inject(HdIconsInjectable) private readonly hdIcons: AppIcons,
		@Inject(ActionIconsInjectable) private readonly actionIcons: AppIcons,
		@Inject(UiComponentIconsInjectable) private readonly uiCmpIcons: AppIcons
	) {}

	public ngDoBootstrap(): void {
		console.warn('bootstrapping root module...');
		this.dcIcons.addIconsToRegistry();
		this.gdIcons.addIconsToRegistry();
		this.hdIcons.addIconsToRegistry();
		this.actionIcons.addIconsToRegistry();
		this.uiCmpIcons.addIconsToRegistry();
	}
}
