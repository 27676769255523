import { Inject, Injectable } from '@angular/core';
import { DcLocalStorage, WINDOW } from '@datachain/ui-sdk/common';

interface IProjectStorage {
	projectId: number;
	projectLabel: string;
}

@Injectable()
export class ProjectStorage extends DcLocalStorage<IProjectStorage> {
	protected key = 'user';

	public constructor(@Inject(WINDOW) private readonly window: Window) {
		super();
		this.synchronize();
	}

	public setValue(val: IProjectStorage): void {
		this.setStorage(val);
	}

	public getValue(): IProjectStorage | undefined {
		return this.getStorage();
	}

	private synchronize(): void {
		const projectId = parseInt(
			this.window.localStorage.getItem('prt') ?? '-1',
			10
		);
		const projectLabel =
			this.window.localStorage.getItem('prt_label') ?? 'no project';
		this.setValue({
			projectId,
			projectLabel,
		});
	}
}
