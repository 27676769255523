import { immerable } from 'immer';

import dayjs from '../../../core/configs/dayjs';

interface ISimExpoUrls {
	endpoint: string;
	rest: string;
	odata: string;
}

interface ISimulationExpositionMetadataEntity {
	id: number;
	linkedExpositionLabel: string;
	endPointLabel: string;
	details: string;
	urls: ISimExpoUrls;
	keywords: Array<string>;
	publishedOn: dayjs.Dayjs;
	updatedOn: dayjs.Dayjs;
}

const entityDefaults: ISimulationExpositionMetadataEntity = {
	id: -1,
	linkedExpositionLabel: '',
	endPointLabel: '',
	details: '',
	urls: {
		endpoint: '',
		odata: '',
		rest: '',
	},
	keywords: [],
	publishedOn: dayjs(),
	updatedOn: dayjs(),
};

export class SimulationExpositionMetadataEntity
	implements ISimulationExpositionMetadataEntity
{
	private [immerable] = true;
	public details = entityDefaults.details;
	public endPointLabel = entityDefaults.endPointLabel;
	public id = entityDefaults.id;
	public linkedExpositionLabel = entityDefaults.linkedExpositionLabel;
	public publishedOn = entityDefaults.publishedOn;
	public updatedOn = entityDefaults.updatedOn;
	public urls = entityDefaults.urls;
	public keywords = entityDefaults.keywords;

	public static build(
		params: Partial<ISimulationExpositionMetadataEntity>
	): SimulationExpositionMetadataEntity {
		const inst = new SimulationExpositionMetadataEntity();
		inst.details = params.details ?? entityDefaults.details;
		inst.endPointLabel = params.endPointLabel ?? entityDefaults.endPointLabel;
		inst.id = params.id ?? entityDefaults.id;
		inst.linkedExpositionLabel =
			params.linkedExpositionLabel ?? entityDefaults.linkedExpositionLabel;
		inst.publishedOn = params.publishedOn ?? entityDefaults.publishedOn;
		inst.updatedOn = params.updatedOn ?? entityDefaults.updatedOn;
		inst.urls = params.urls ?? entityDefaults.urls;
		inst.keywords = params.keywords ?? entityDefaults.keywords;
		return inst;
	}

	private constructor() {}
}
