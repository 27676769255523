import { Injectable } from '@angular/core';
import {
	CachePolicy,
	CachePolicyEntity,
	UrlService,
} from '@datachain/ui-sdk/common';
import { Observable } from 'rxjs';

import { DcBaseRequester } from './dc-base.requester';
import { BeElement, BePermissions } from './models/permission.model';

@Injectable({
	providedIn: 'root',
})
export class PermissionRequester extends DcBaseRequester<unknown> {
	protected resource: string;
	protected elementsListUrl: string;
	protected singleElementUrl: string;

	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = 'unspecified';
		this.elementsListUrl = this.resource;
		this.singleElementUrl = this.resource;
	}

	public haveGlobalPermission(permission: BePermissions): Observable<boolean> {
		const url = this.urlService.generateUrl(
			this.generateResourceUrl('PERMISSIONS_CHECK_USER_GLOBAL_PERMISSION'),
			{
				action: permission,
			},
			{}
		);
		return this.get<boolean>(
			url,
			CachePolicyEntity.build({
				isCacheable: false,
				policy: CachePolicy.NetworkOnly,
				evictTimeout: 0,
			})
		);
	}

	protected haveActionPermissionOnElement(
		elementId: number,
		element: BeElement,
		permission: BePermissions
	): Observable<boolean> {
		const url = this.urlService.generateUrl(
			this.generateResourceUrl('PERMISSIONS_CHECK_PERMISSION'),
			{
				elementId,
				type: element,
				action: permission,
			},
			{
				project: this.projectId,
			}
		);
		return this.get<boolean>(
			url,
			CachePolicyEntity.build({
				isCacheable: false,
				policy: CachePolicy.NetworkOnly,
				evictTimeout: 0,
			})
		);
	}
}
