import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	OnInit,
	Output,
} from '@angular/core';
import { combineAll, DcBaseComponent } from '@datachain/ui-sdk/common';
import { Store } from '@ngrx/store';
import { Map } from 'immutable';
import {
	filter,
	firstValueFrom,
	map,
	Observable,
	ReplaySubject,
	takeUntil,
	tap,
} from 'rxjs';

import { ExpositionConsumerMappingEntity } from '../../../domain/exposition-consumer-mapping.entity';
import { ExpositionConsumerEntity } from '../../../domain/exposition-consumer.entity';
import {
	ExpositionsSelector,
	initProjectImportConsumersMapping,
	refreshProjectImportConsumersMapping,
} from '../../../store';

type AjsExpositionPreviousConsumerEntity = {
	id: number;
	name: string;
	isGroup: boolean;
};

@Component({
	selector: 'app-exposition-consumers-mapping-wrapper',
	templateUrl: 'exposition-project-import-consumers-mapping.component.html',
	inputs: ['knownConsumers'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpositionProjectImportConsumersMappingComponent
	extends DcBaseComponent
	implements OnInit
{
	private readonly consumersToMapSubject = new ReplaySubject<
		Map<string, ExpositionConsumerMappingEntity>
	>(1);

	@Output()
	public notifyOnMapping = new EventEmitter<
		Record<string, ExpositionConsumerMappingEntity> | undefined
	>();

	public vo$: Observable<{
		consumersToMap: Array<ExpositionConsumerMappingEntity>;
		availableConsumers: Array<ExpositionConsumerEntity>;
	}>;

	public constructor(
		private readonly store: Store,
		private readonly expositionSelector: ExpositionsSelector
	) {
		super();
		this.cmpId = 'app-consumers-mapping-wrapper';

		this.vo$ = combineAll({
			availableConsumers: this.expositionSelector.getAvailableConsumers$(),
			consumersToMap: this.consumersToMapSubject.pipe(
				map((els) => els.toList().toArray())
			),
		});
	}

	public ngOnInit(): void {
		super.ngOnInit();

		this.toObservable<Array<AjsExpositionPreviousConsumerEntity>>(
			'knownConsumers'
		)
			.pipe(
				takeUntil(this.onDestroy$),
				filter((arr) => arr && arr.length !== 0),
				tap(() => this.store.dispatch(initProjectImportConsumersMapping())),
				map((arr) => this.adapt(this.generatePreviousConsumerEntity(arr))),
				tap((res) => this.consumersToMapSubject.next(res))
			)
			.subscribe();
	}

	public async assignTargetConsumer(
		updated: ExpositionConsumerMappingEntity
	): Promise<void> {
		const currentMapping = await firstValueFrom(this.consumersToMapSubject);
		const newMapping = currentMapping.set(updated.id, updated);
		this.consumersToMapSubject.next(newMapping);

		const isAllMapped = newMapping.reduce<boolean>(
			(acc, curr) => acc && !!curr.targetConsumerId,
			true
		);

		this.notifyOnMapping.emit(
			isAllMapped
				? (newMapping.toJS() as Record<string, ExpositionConsumerMappingEntity>)
				: undefined
		);
	}

	public async reloadUsers(): Promise<void> {
		const known = await firstValueFrom(
			this.toObservable<Array<AjsExpositionPreviousConsumerEntity>>(
				'knownConsumers'
			)
		);
		const map = this.adapt(this.generatePreviousConsumerEntity(known));
		this.consumersToMapSubject.next(map);
		this.notifyOnMapping.emit(undefined);
		this.store.dispatch(refreshProjectImportConsumersMapping());
	}

	private adapt(
		consumers:
			| Map<string, ExpositionConsumerMappingEntity>
			| Array<ExpositionConsumerMappingEntity>
	): Map<string, ExpositionConsumerMappingEntity> {
		if (Map.isMap(consumers)) {
			return consumers;
		}
		return consumers.reduce<Map<string, ExpositionConsumerMappingEntity>>(
			(acc, curr) => {
				acc = acc.set(curr.id, curr);
				return acc;
			},
			Map<string, ExpositionConsumerMappingEntity>()
		);
	}

	private generatePreviousConsumerEntity(
		arr: Array<AjsExpositionPreviousConsumerEntity>
	): Array<ExpositionConsumerMappingEntity> {
		return arr.map<ExpositionConsumerMappingEntity>((arr) =>
			ExpositionConsumerMappingEntity.build({
				id: `${arr.id}`,
				isGroup: arr.isGroup,
				fullName: arr.name,
			})
		);
	}
}
