import { IisEqual } from '@datachain/ui-sdk/common';
import { immerable } from 'immer';

interface IExportExcelConfigurationEntity {
	cell: string;
	withHeaders: boolean;
}

const entityDefaults: IExportExcelConfigurationEntity = {
	cell: 'A1',
	withHeaders: true,
};

export class ExportExcelConfigurationEntity
	implements
		IExportExcelConfigurationEntity,
		IisEqual<ExportExcelConfigurationEntity>
{
	private [immerable] = true;
	public cell = entityDefaults.cell;
	public withHeaders = entityDefaults.withHeaders;

	public static build(
		params: Partial<IExportExcelConfigurationEntity> = {}
	): ExportExcelConfigurationEntity {
		const instance = new ExportExcelConfigurationEntity();
		instance.cell = params.cell ?? entityDefaults.cell;
		instance.withHeaders = params.withHeaders ?? entityDefaults.withHeaders;
		return instance;
	}
	private constructor() {}

	public isEqualTo(obj: ExportExcelConfigurationEntity): boolean {
		if (this.cell.localeCompare(obj.cell) !== 0) {
			return false;
		}
		if (this.withHeaders !== obj.withHeaders) {
			return false;
		}
		return true;
	}
}
