<div *ngIf="vo$ | async; let vo">
	<div class="app-exposition-api-publication">
		<div class="app-exposition-api-publication-detail">
			<span
				i18n="@@expositions.publication.api.source"
				class="app-exposition-api-publication__source">
				title
			</span>
			<span class="app-exposition-api-publication__navigation">
				{{ vo.published.datablockLabel }}
				<a appNavigate [navigateTo]="vo.published.datablockUrl">
					<mat-icon
						[svgIcon]="DcIcons.OpenInNewTab"
						matTooltip="open db in new tab"
						i18n-matTooltip="@@exposition.datablock.list.urlTooltip"
						matTooltipPosition="after"></mat-icon>
				</a>
			</span>
		</div>

		<ng-container *ngIf="vo.published.status !== PublicationStatus.Unpublished">
			<div class="app-exposition-api-publication-detail">
				<span
					i18n="@@expositions.publication.api.endpoints"
					class="app-exposition-api-publication__url">
					data url
				</span>
				<div class="app-exposition-api__endpoints">
					<app-overlay-badge
						[active]="true"
						[isOpen]="endPointsOverlay$.value.rest"
						value="REST"
						(overlayDismiss)="closeEndpointMetadataPopover()"
						(mouseenter)="openEndpointMetadataPopover($event, 'REST')"
						(mouseleave)="closeEndpointMetadataPopover()">
						<app-popover
							id="rest-popover-endpoint-metadata"
							overlayContent
							title="REST"
							(mouseenter)="openEndpointMetadataPopover($event, 'REST')"
							(mouseleave)="closeEndpointMetadataPopover()"
							(adbPopoverClose)="closeEndpointMetadataPopover()">
							<div class="endpoint-popover">
								<span>{{ vo.published.dataUrl }}</span>
								<app-button
									id="{{ cmpId }}-copy-rest-endpoint-url-btn"
									[leftIcon]="DcIcons.CopyFile"
									[cdkCopyToClipboard]="vo.published.dataUrl"
									(cdkCopyToClipboardCopied)="
													openSnackBar()
												"></app-button>
							</div>
						</app-popover>
					</app-overlay-badge>

					<app-overlay-badge
						[active]="true"
						[isOpen]="endPointsOverlay$.value.odata"
						value="OData"
						(mouseenter)="openEndpointMetadataPopover($event, 'ODATA')"
						(mouseleave)="closeEndpointMetadataPopover()"
						(overlayDismiss)="closeEndpointMetadataPopover()">
						<app-popover
							id="odata-popover-endpoint-metadata"
							overlayContent
							title="OData"
							(mouseenter)="openEndpointMetadataPopover($event, 'ODATA')"
							(mouseleave)="closeEndpointMetadataPopover()"
							(adbPopoverClose)="closeEndpointMetadataPopover()">
							<div class="endpoint-popover">
								<span>{{ vo.published.oDataUrl }}</span>
								<app-button
									id="{{ cmpId }}-copy-odata-endpoint-url-btn"
									[leftIcon]="DcIcons.CopyFile"
									[cdkCopyToClipboard]="vo.published.oDataUrl"
									(cdkCopyToClipboardCopied)="
													openSnackBar()
												"></app-button>
							</div>
						</app-popover>
					</app-overlay-badge>
				</div>
			</div>

			<div class="app-exposition-api-publication-detail">
				<span
					i18n="@@exposition.metadata.form.title"
					class="app-exposition-api-publication__title">
					title
				</span>
				<span>{{ vo.published.title }}</span>
			</div>
			<div class="app-exposition-api-publication-detail">
				<span
					i18n="@@exposition.metadata.form.details"
					class="app-exposition-api-publication__details">
					details
				</span>
				<span>{{ vo.published.details }}</span>
			</div>
			<div class="app-exposition-api-publication-detail">
				<span
					i18n="@@exposition.metadata.form.keywords"
					class="app-exposition-api-publication__keywords">
					title
				</span>
				<app-tag-configurator
					[configuredTags]="vo.published.keywords"
					displayMode="keywords"
					configMode="View"></app-tag-configurator>
			</div>
			<div class="app-exposition-api-publication-detail">
				<span
					i18n="@@expositions.publication.api.results"
					class="app-exposition-api-publication__details">
					results
				</span>
				<div class="app-exposition-api-publication__results">
					<app-overlay-badge
						[icon]="ComponentIcons.Column"
						[value]="vo.published.columnsCount"
						[active]="false"></app-overlay-badge>
					<app-overlay-badge
						[icon]="ComponentIcons.Access"
						[value]="vo.published.accessCount"
						[active]="false"></app-overlay-badge>
					<app-overlay-badge
						[icon]="ComponentIcons.Data"
						[value]="vo.published.dataCount"
						[active]="false"></app-overlay-badge>
				</div>
			</div>

			<div class="app-exposition-api-publication-detail">
				<span
					i18n="@@expositions.publication.api.publishedOn"
					class="app-exposition-api-publication__publish">
					published_on
				</span>

				<span class="app-exposition-api-publication__publish-details">
					{{ vo.published.publicationDate | date: 'yyyy-MM-dd HH:mm' }}
					<app-avatar
						[avatar]="vo.published.publisherAvatar"
						[withTooltip]="true"></app-avatar>
				</span>
			</div>
		</ng-container>

		<div
			*ngIf="vo.published.history.length"
			class="app-exposition-api-publication__updates">
			<span
				i18n="@@expositions.publication.api.updates"
				class="app-exposition-api-publication__updates-header">
				updates
			</span>
			<div class="app-exposition-api-publication__updates-content">
				<div
					*ngFor="let update of vo.published.history"
					class="app-exposition-api-publication__updates-content-entry">
					<app-exposition-publication-status
						[status]="update.status"></app-exposition-publication-status>
					<ng-container
						*ngIf="
							update.status !== PublicationStatus.Running;
							else inProgress
						">
						<span
							class="app-exposition-api-publication__updates-type"
							[matTooltip]="update.updateDescription"
							[matTooltipPosition]="'after'">
							{{ update.updateDescription | truncate: 28 }}
						</span>
						<app-overlay-badge
							[icon]="ComponentIcons.Data"
							[value]="update.linesCount"
							[active]="false"></app-overlay-badge>
						{{ update.date | date: 'yyyy-MM-dd HH:mm' }}
						<span>{{ update.duration }} s</span>
						<app-avatar
							[avatar]="update.avatar"
							[withTooltip]="true"></app-avatar>
					</ng-container>
					<ng-template #inProgress>
						<span i18n="@@expositions.publication.api.inProgress">
							in progress
						</span>
					</ng-template>
				</div>
			</div>
			<div class="app-exposition-api-publication__history">
				<button
					mat-stroked-button
					(click)="goToHistoryRoute()"
					id="exposition-view-history"
					class="app-exposition-api-publication__history-btn">
					<mat-icon [svgIcon]="DcIcons.Update"></mat-icon>
					<span i18n="@@expositions.history.action">see history</span>
				</button>
			</div>
		</div>
	</div>
</div>
