import { IComparable } from '@datachain/ui-sdk/common';
import { immerable } from 'immer';

interface IExportPartitionConfigurationEntity {
	count: number;
}

const entityDefaults: IExportPartitionConfigurationEntity = {
	count: 1,
};

export class ExportPartitionConfigurationEntity
	implements
		IExportPartitionConfigurationEntity,
		IComparable<ExportPartitionConfigurationEntity>
{
	private [immerable] = true;
	public count = entityDefaults.count;

	public static build(
		params: Partial<IExportPartitionConfigurationEntity> = {}
	): ExportPartitionConfigurationEntity {
		const instance = new ExportPartitionConfigurationEntity();
		instance.count = params.count ?? entityDefaults.count;
		return instance;
	}
	private constructor() {}

	public compareTo(obj: ExportPartitionConfigurationEntity): number {
		return this.count - obj.count;
	}
}
