<ng-container *ngIf="vo$ | async; let vo">
	<dx-data-grid
		keyExpr="id"
		[elementAttr]="{
			id: 'expositions-datablock-list',
			class: 'app-exposition-datablock-list'
		}"
		[dataSource]="vo.list.elements | toArray"
		[hoverStateEnabled]="true"
		[showColumnHeaders]="true"
		[allowColumnResizing]="true"
		[columnAutoWidth]="false"
		[showRowLines]="false"
		[showColumnLines]="false"
		[rowAlternationEnabled]="false"
		[showBorders]="false"
		[width]="'100%'"
		(onSelectionChanged)="onSelection($event)">
		<dxo-selection mode="single"></dxo-selection>
		<dxo-load-panel
			shadingColor="rgba(0,0,0,0.4)"
			indicatorSrc="/src/img/loader-duotone.svg"
			[height]="100"
			[width]="100"
			[enabled]="true"
			[showIndicator]="true"
			[showPane]="true"
			[shading]="true"></dxo-load-panel>

		<!-- Fixable in position columns	-->
		<dxo-column-fixing [enabled]="true"></dxo-column-fixing>

		<dxo-scrolling mode="virtual"></dxo-scrolling>

		<ng-container *ngFor="let col of vo.list.availableColumns | toArray">
			<dxi-column
				*ngIf="col.field === 'label'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowFiltering]="col.isFilterable"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[width]="'80%'"
				cellTemplate="datablockColumnTemplate"></dxi-column>
			<dxi-column
				*ngIf="col.field === 'columnsCount'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowFiltering]="col.isFilterable ?? false"
				[allowSorting]="true"
				[format]="col.format"
				[alignment]="'left'"
				[width]="'7%'"
				cellTemplate="columnsTemplate"></dxi-column>
			<dxi-column
				*ngIf="col.field === 'avatar'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowFiltering]="col.isFilterable"
				[allowSorting]="true"
				[alignment]="'left'"
				[width]="'5%'"
				cellTemplate="avatarTemplate"></dxi-column>
			<dxi-column
				*ngIf="col.field === 'updateDate'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowFiltering]="col.isFilterable ?? false"
				[allowSorting]="true"
				[format]="col.format"
				[width]="'10%'"
				[alignment]="'left'"
				cellTemplate="timestampTemplate"></dxi-column>
		</ng-container>

		<div class="columns-count" *dxTemplate="let cell of 'columnsTemplate'">
			<app-overlay-badge
				[icon]="ComponentIcons.Column"
				[value]="cell.data['columnsCount']"
				[isOpen]="vo.openedOverlayId === cell.data['id']"
				(overlayDismiss)="closeColumnsPopover()"
				(click)="openColumnsPopover($event, cell.data)">
				<app-popover
					overlayContent
					title="columns"
					[icon]="{ iconName: ComponentIcons.Column }"
					i18n-title="@@exposition.access.list.column.columns"
					[count]="cell.data['columns'].length"
					[ngStyle]="{
						'--popover-body-h': '7rem',
						'--popover-body-max-h': '7rem'
					}"
					(adbPopoverClose)="closeColumnsPopover()">
					<mat-spinner
						*ngIf="vo.isLoadingColumns"
						[diameter]="20"></mat-spinner>
					<ng-container *ngIf="!vo.isLoadingColumns">
						<div
							*ngFor="let column of cell.data['columns'] | toArray"
							class="datablock-columns__body-row">
							<app-column-type
								[type]="column['type']"
								[isList]="column['isList']"></app-column-type>
							<span>{{ column.label }}</span>
						</div>
					</ng-container>
				</app-popover>
			</app-overlay-badge>
		</div>

		<div
			*dxTemplate="let cell of 'datablockColumnTemplate'"
			class="datablock-column-template">
			<span class="datablock-column-template__label">
				{{ cell.data['label'] }}
				<a appNavigate [navigateTo]="cell.data['url']">
					<mat-icon
						[svgIcon]="DcIcons.OpenInNewTab"
						matTooltip="open db in new tab"
						i18n-matTooltip="@@exposition.datablock.list.urlTooltip"
						matTooltipPosition="after"></mat-icon>
				</a>
			</span>

			<app-tag-configurator
				configMode="View"
				displayMode="tags"
				[configuredTags]="cell.data['tags']"></app-tag-configurator>
		</div>

		<div *dxTemplate="let cell of 'avatarTemplate'">
			<app-avatar
				[avatar]="cell.data['avatar']"
				[withTooltip]="true"></app-avatar>
		</div>

		<div *dxTemplate="let cell of 'timestampTemplate'">
			<span>{{ cell.data['updateDate'] | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
		</div>

		<!-- Sorting	-->
		<dxo-sorting mode="multiple"></dxo-sorting>

		<!-- Pagination -->
		<dxo-paging [pageSize]="1000"></dxo-paging>
		<dxo-pager [visible]="false"></dxo-pager>

		<!-- State persistence	-->
		<dxo-state-storing
			type="localStorage"
			[enabled]="false"
			[storageKey]="dxLocalStorageKey"></dxo-state-storing>
	</dx-data-grid>
</ng-container>
