import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { combineAll, DcBaseComponent } from '@datachain/ui-sdk/common';
import {
	AppTagConfiguratorComponent,
	AppTagItemEntity,
} from '@datachain/ui-sdk/components';
import { OrderedSet } from 'immutable';
import { merge, Observable, of, takeUntil, tap } from 'rxjs';

import { ValidationErrorKeys } from '../../../ui/form/validation-erros-keys';
import {
	ExpositionInternalMetadataForm,
	InternalMetadataFormControls,
} from './exposition-internal-metadata.form';
import { ExpositionMetadataEntity } from './exposition-metadata.entity';

@Component({
	selector: 'app-exposition-internal-metadata-config',
	templateUrl: './exposition-internal-metadata.config.component.html',
	styleUrls: ['./exposition-internal-metadata.config.component.scss'],
	providers: [ExpositionInternalMetadataForm],
	inputs: ['expositionMetadata', 'availableTags', 'isInViewMode'],
})
export class ExpositionInternalMetadataConfigComponent extends DcBaseComponent {
	public Appearance: MatFormFieldAppearance = 'legacy';
	public OwnMetadataFormControls = InternalMetadataFormControls;

	@ViewChild(AppTagConfiguratorComponent)
	public tagConfiguratorComponentCmp: AppTagConfiguratorComponent | null = null;

	@Output()
	public hasInternalMetadataChanged = new EventEmitter<boolean>();

	public vo$: Observable<{
		expositionMetadata: ExpositionMetadataEntity;
		availableTags: OrderedSet<AppTagItemEntity>;
		isInViewMode: boolean;
	}>;
	protected readonly ValidationErrorKeys = ValidationErrorKeys;

	public constructor(
		public readonly internalMetadataForm: ExpositionInternalMetadataForm
	) {
		super();
		this.cmpId = 'exposition-metadata-config';
		this.toObservable<ExpositionMetadataEntity>('expositionMetadata')
			.pipe(
				takeUntil(this.onDestroy$),
				tap((config) => {
					this.internalMetadataForm.populate(config);
				})
			)
			.subscribe();

		this.internalMetadataForm.form.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				tap(() => {
					this.hasInternalMetadataChanged.emit();
				})
			)
			.subscribe();

		this.vo$ = combineAll({
			expositionMetadata: merge(
				of(ExpositionMetadataEntity.build({})),
				this.toObservable<ExpositionMetadataEntity>('expositionMetadata')
			),
			availableTags: merge(
				of(OrderedSet([])),
				this.toObservable<OrderedSet<AppTagItemEntity>>('availableTags')
			),
			isInViewMode: merge(
				of(false),
				this.toObservable<boolean>('isInViewMode')
			),
		});
	}

	public async extractFormData(): Promise<ExpositionMetadataEntity> {
		let tags = new Array<AppTagItemEntity>();
		if (this.tagConfiguratorComponentCmp) {
			tags = await this.tagConfiguratorComponentCmp.getUsedTags();
		}
		const ownMetadata = this.internalMetadataForm.extract();
		return ExpositionMetadataEntity.build({
			id: ownMetadata?.id,
			label: ownMetadata?.label,
			description: ownMetadata?.description,
			tags: OrderedSet(tags),
		});
	}
}
